import React from 'react'
import PropTypes from 'prop-types';
import TextFieldComponent from './TextFieldComponent';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import BtnsComponent from './BtnsComponent';
import { useMemo } from 'react';
import CountrySelectComponent from './CountrySelectComponent';
import { useCallback } from 'react';
import { GET } from '../../functions/superagentSending';
import { CHECK_DOMAIN_API } from '../../constants/api';
import { DOMAIN_REGEX } from '../../constants';
import FaqAdornmentComponent from './FaqAdornmentComponent';


const companySchema = (t) => Yup.object().shape({
	name: Yup.string()
		.required(t('validation.required'))
		.min(2, t('validation.min', { item: 2 })),
	domain: Yup.string()
		.required(t('validation.required'))
		.min(2, t('validation.min', { item: 2 }))
		.max(80, t('validation.max', { item: 80 }))
		.matches(DOMAIN_REGEX, t('errors.domain_regex')),
	nip: Yup.string()
		.required(t('validation.required'))
		.min(2, t('validation.min', { item: 2 })),
	street: Yup.string()
		.required(t('validation.required'))
		.min(2, t('validation.min', { item: 2 }))
		.max(80, t('validation.max', { item: 80 })),
	zipCode: Yup.string()
		.required(t('validation.required'))
		.min(2, t('validation.min', { item: 2 })),
	city: Yup.string()
		.required(t('validation.required'))
		.min(2, t('validation.min', { item: 2 })),
	country: Yup.string()
		.required(t('validation.required'))
		.min(2, t('validation.min', { item: 2 })),
});
const CompanyDataFormComponent = ({
	handleToggleDialog = () => { },
	fetchFunction = () => { },
	data,
	hiddenCallBack,
	hiddenSecondBtn,
	defaultValues = {},
	btnTitle,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const initialValues = useMemo(() => ({
		name: data?.name || '',
		domain: data?.domain || '',
		nip: data?.nip || '',
		street: data?.street || '',
		zipCode: data?.zipCode || '',
		city: data?.city || '',
		country: data?.country || '',
	}), [data])

	const formik = useFormik({
		validationSchema: companySchema(t),
		enableReinitialize: true,
		initialValues: initialValues,
		onSubmit: values => {
			// console.log(values)
			dispatch(fetchFunction({
				...defaultValues,
				company: {
					...values
				}
			}, () => {
				if (!hiddenCallBack)
					handleToggleDialog();
			}));
		},
	});

	const handleCheckDomain = useCallback((e) => {
		if (!formik.errors.domain || formik.errors.domain === t('errors.domain'))
			GET(`${CHECK_DOMAIN_API}?domain=${e.target.value}`,
				(success) => {
					formik.setFieldError('domain', success?.body?.data?.is_exist ? t('errors.domain') : false)
				}, (error) => {
					formik.setFieldError('domain', t('errors.can_not_check_domain'))
				})
	}, [formik, t])
	return (
		<form>
			<TextFieldComponent
				id='domain'
				label={t('form.domain')}
				formikProps={formik}
				onBlur={handleCheckDomain}
				InputProps={{
					endAdornment:
						<FaqAdornmentComponent
							title={t('faq.domain')}
						/>
				}}
				helpText={formik?.values?.domain
					? t('onboarding_page.your_domain', { name: formik.values.domain })
					: null
				}
			/>
			<TextFieldComponent
				id='name'
				label={t('form.companyName')}
				formikProps={formik}
			/>
			<TextFieldComponent
				id='nip'
				formikProps={formik}
			/>
			<TextFieldComponent
				id='street'
				formikProps={formik}
			/>

			<TextFieldComponent
				id='zipCode'
				formikProps={formik}
			/>

			<TextFieldComponent
				id='city'
				formikProps={formik}
			/>
			<CountrySelectComponent
				id='country'
				formikProps={formik}
			/>
			<BtnsComponent
				hiddenSecondBtn={hiddenSecondBtn}
				handleSave={formik.submitForm}
				onClose={handleToggleDialog}
				title={btnTitle}
			/>
		</form>
	)
}


CompanyDataFormComponent.propTypes = {
	handleToggleDialog: PropTypes.func,
	data: PropTypes.object,
	btnTitle: PropTypes.string,
	secondBtnTitle: PropTypes.string,
}
export default React.memo(CompanyDataFormComponent);