import React, { useMemo, useState } from 'react'
import { Box, Container, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ABOUT_ROUTE, BLOG_ROUTE, FAQS_ROUTE, LOGIN_ROUTE, MAIN_ROUTE, PLAN_ROUTE } from '../constants/routes';
import MenuItemComponent from './MenuItemComponent';
import LogoSvg from '../svgs/LogoSvg';
import LangComponent from './LangComponent';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ButtonComponent from './form/ButtonComponent';
import LinkComponent from './LinkComponent';
import { LANDING_BTN } from '../functions/csses';
import MobileMenuComponent from './MobileMenuComponent';
import HamburgerBtnComponent from './HamburgerBtnComponent';
import BraveComponent from './BraveComponent';

const useStyles = makeStyles((theme) => ({
	header: {
		background: theme.palette.background.default,
		position: 'sticky',
		top: 0,
		left: 0,
		zIndex: 2,
		width: '100%',
		// paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			position: 'fixed',
			background: theme.palette.background.white,
			zIndex: 100,
			paddingTop: 0,
			paddingBottom: 0,
		}
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	logoSvg: {
		cursor: 'pointer',
		height: 40,
		marginRight: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			height: 25,
		}
	},
	box: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		}
	},
	btn: {
		...LANDING_BTN,
		boxShadow: 'none',
		textTransform: 'inherit',
		padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
	},
	mobileHamb: {
		display: 'none',

		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
		}
	},
}))

const HeaderComponent = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'))
	const { data } = useSelector((state) => state.MainPageReducer)
	const { t } = useTranslation();
	const history = useHistory();
	const [show, setShow] = useState(false);
	const loginLink = useMemo(() => (data?.is_auth
		? {
			title: t('main_page.go_panel'),
			to: data?.redirect_url,
			external_link: true,
			target: '_self',
		}
		: {
			title: t('login_page.title'),
			to: LOGIN_ROUTE
		}
	), [t, data])
	const menu = useMemo(() => [
		{
			title: t('main_page.title'),
			to: MAIN_ROUTE
		},
		{
			title: t('about_us_page.menu_title'),
			to: ABOUT_ROUTE
		},
		{
			title: t('blog_page.menu_title'),
			to: BLOG_ROUTE
		},
		{
			title: t('main_page.plans_menu'),
			to: PLAN_ROUTE
		},
		{
			title: t('faqs_page.menu_title'),
			to: FAQS_ROUTE
		},
	], [t])

	const handlePush = useCallback(() => {
		history.push(MAIN_ROUTE)
	}, [history])

	const toggleShow = useCallback(() => {
		setShow(!show)
	}, [show])
	return (
		<>
			<Box component='header' className={classes.header}>
				<BraveComponent />
				{matches ?
					<MobileMenuComponent
						menu={menu}
						loginLink={loginLink}
						toggleShow={toggleShow}
						show={show}
					/>
					: null
				}
				<Container className={classes.container}>
					<LogoSvg className={classes.logoSvg} onClick={handlePush} />
					<Box className={classes.mobileHamb}>
						<LangComponent />
						<HamburgerBtnComponent
							toggleShow={toggleShow}
							show={show}
						/>
					</Box>
					<Box className={classes.box}>
						{menu?.length
							? menu.map(({ title, to, external_link, target }, key) =>
								<MenuItemComponent
									title={title}
									target={target}
									to={to}
									external_link={external_link}
									key={key}
								/>
							)
							: null
						}

						<LangComponent />
						<LinkComponent to={loginLink.to} external_link={loginLink.external_link} target={loginLink.target}>
							<ButtonComponent className={classes.btn} variant='contained' color='primary'>
								{loginLink.title}
							</ButtonComponent>
						</LinkComponent>
					</Box>
				</Container>
			</Box>
		</>
	)
}


HeaderComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(HeaderComponent);