import { Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import GradientContainerComponent from '../../components/GradientContainerComponent';
import { PASSWORD_FORGOT_API } from '../../constants/api';
import { LOGIN_ROUTE } from '../../constants/routes';
import { POST } from '../../functions/superagentSending';
import * as Yup from 'yup';
import BtnsComponent from '../../components/form/BtnsComponent';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import TextFieldComponent from '../../components/form/TextFieldComponent';
import DialogComponent from '../../components/DialogComponent';

const userSchema = (t) => Yup.object().shape({
    email: Yup.string()
        .email(t('validation.email'))
        .required(t('validation.required'))
        .min(2, t('validation.min', { item: 4 }))
        .max(80, ('validation.max', { item: 80 })),
});

const ForgotPage = (props) => {
    const [successVer, setSuccessVer] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { t } = useTranslation();
    const formik = useFormik({
        validationSchema: userSchema(t),
        initialValues: {
            email: '',
        },
        onSubmit: values => {
            setError(false)
            setLoading(true)
            POST(PASSWORD_FORGOT_API, values).then((success) => {
                setSuccessVer(true)
                setLoading(false)
            }).catch((error) => {
                formik.resetForm();
                setError(true)
                setLoading(false)
            })
        },
    });
    const handleBackClick = useCallback(() => {
        history.push(LOGIN_ROUTE);
    },[history])
    return (
        <GradientContainerComponent
            title={successVer ? t('forgot_page.success_title') : t('forgot_page.title')}
            loading={loading}
            subtitle={t('forgot_page.subtitle')}
            message={successVer ? t('forgot_page.success') : false}
            messageBtn={t('login_page.title')}
            messageBtnTo={LOGIN_ROUTE}
            error={error ? t('errors.email') : false}
        >
            <form onSubmit={formik.submitForm}>
                <TextFieldComponent formikProps={formik} autoComplete='username' id='email' type='email' placeholder={t('form.email')} />
                <BtnsComponent
                    gradient
                    title={t('btns.send')}
                    secondTitle={t('btns.remember')}
                    handleSave={formik.submitForm}
                    onClose={handleBackClick}
                />
            </form>
            <DialogComponent title={t('forgot_page.send_success_title')} showDialog={successVer}>
              <Typography>{t('forgot_page.send_success')}</Typography>
          </DialogComponent>
        </GradientContainerComponent>
    )
}


export default ForgotPage;