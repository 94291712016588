import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import { BLUR_BOX } from '../../../functions/csses';

const useStyles = makeStyles((theme) => ({
	box: {
		position: 'absolute',
		// display: 'flex',
		// alignItems: 'center',
	},
	section: {
		padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
		minWidth: 250,
		textAlign: 'center',
		...BLUR_BOX(theme),
		borderRadius: 40,
		overflow: 'hidden',
		height: 'fit-content',
		// width: '100%',
		animation: "$moving linear infinite",
		[theme.breakpoints.down('xs')]: {
			minWidth: 200,	
		}
	},
	message: {
		textTransform: 'inherit',
		[theme.breakpoints.down('xs')]: {
			fontSize: 12,
		}
	},
	'@keyframes moving': {
		'0%': {
			transform: "translate(0px, 0px)",
		},
		'25%': {
			transform: "translate(10px, 0px)",
		},
		'75%': {
			transform: "translate(-10px, 0px)",
		},
		'100%': {
			transform: "translate(0px, 0px)",
		},
	}
}))

const BlurMsgBoxComponent = ({ duration = "3s", message, speed, top = 0, marginLeft, marginRight, marginTop, marginBottom, bottom = 0, right, left, }) => {
	const classes = useStyles();

	return (
		<Box
			className={classes.box}
			style={{ top, marginLeft, marginRight, marginTop, marginBottom, bottom, right, left, }}
		>
			<section
				className={classes.section}
				style={{animationDuration: duration}}

			>
				<Typography variant='button' className={classes.message}>{message}</Typography>
			</section>
		</Box>
	)
}

export default React.memo(BlurMsgBoxComponent);