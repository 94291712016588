import { BLOG_API } from "../constants/api";
import { GET, } from "../functions/superagentSending";

const initialState = {
	loading: false,
	postLoading: false,
	data: {},
	meta: {},
	error: '',
};


const FETCH_BLOG_REQUEST = 'FETCH_BLOG_REQUEST'
const FETCH_BLOG_POST_REQUEST = 'FETCH_BLOG_POST_REQUEST'
const FETCH_BLOG_SUCCESS = 'FETCH_BLOG_SUCCESS'
const FETCH_BLOG_FAILURE = 'FETCH_BLOG_FAILURE'

const fetchBlogPageSuccess = (value) => ({
	type: FETCH_BLOG_SUCCESS,
	payload: value
})
const fetchBlogPageRequest = () => ({
	type: FETCH_BLOG_REQUEST
})
const fetchBlogPagePostRequest = () => ({
	type: FETCH_BLOG_POST_REQUEST
})


// eslint-disable-next-line
const fetchBlogPageFailure = error => ({
	type: FETCH_BLOG_FAILURE,
	payload: error
})


const BlogPageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_BLOG_REQUEST:
			return {
				...state,
				loading: true,
				error: ''
			}
		case FETCH_BLOG_POST_REQUEST:
			return {
				...state,
				postLoading: true,
				error: ''
			}
		case FETCH_BLOG_SUCCESS:
			return {
				data: {
					list: [
						...(payload?.meta?.current_page > 1 ? state?.data?.list : []),
						...payload?.data
					]
				},
				meta: payload.meta,
				loading: false,
				postLoading: false,
				error: ''
			}
		case FETCH_BLOG_FAILURE:
			return {
				...state,
				postLoading: false,
				loading: false,
				error: payload
			}
		default:
			return state
	}
}

export const FETCH_BLOG_REDUCER = (currentPage, name) => {
	return (dispatch) => {
		if (currentPage > 1)
			dispatch(fetchBlogPagePostRequest());
		else
			dispatch(fetchBlogPageRequest());
		GET(`${BLOG_API}?page=${currentPage}${name ? 'name=' + name : ''}`,
			(success) => {
				dispatch(fetchBlogPageSuccess(success?.body));
			},
			(error) => {

				dispatch(fetchBlogPageFailure(error));
				dispatch(fetchBlogPageFailure());
			})
	}
}

export default BlogPageReducer;