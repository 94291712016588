import { MAIN_PAGE_API, PHONE_API } from '../constants/api';
import { GET, POST } from '../functions/superagentSending';

const initialState = {
	loading: true,
	sendPhone: false,
	data: {},
	error: '',
};

const FETCH_MAIN_PAGE_REQUEST = 'FETCH_MAIN_PAGE_REQUEST';
const SEND_PHONE_REQUEST = 'SEND_PHONE_REQUEST';
const SEND_PHONE_SUCCESS = 'SEND_PHONE_SUCCESS';
const FETCH_MAIN_PAGE_SUCCESS = 'FETCH_MAIN_PAGE_SUCCESS';
const FETCH_MAIN_PAGE_FAILURE = 'FETCH_MAIN_PAGE_FAILURE';

const sendPhoneRequest = () => ({
	type: SEND_PHONE_REQUEST,
});
const fetchMainPageRequest = () => ({
	type: FETCH_MAIN_PAGE_REQUEST,
});
const sendPhoneSuccess = () => ({
	type: SEND_PHONE_SUCCESS,
});

const fetchMainPageSuccess = (data) => ({
	type: FETCH_MAIN_PAGE_SUCCESS,
	payload: data,
});

const fetchMainPageFailure = (error) => ({
	type: FETCH_MAIN_PAGE_FAILURE,
	payload: error,
});

const MainPageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_MAIN_PAGE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case SEND_PHONE_REQUEST:
			return {
				...state,
				sendPhone: true,
			};
		case FETCH_MAIN_PAGE_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
				error: '',
			};
		case SEND_PHONE_SUCCESS:
			return {
				...state,
				loading: false,
				sendPhone: false,
				error: '',
			};
		case FETCH_MAIN_PAGE_FAILURE:
			return {
				...state,
				sendPhone: false,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
};

export const FETCH_MAIN_PAGE_REDUCER = () => {
	return (dispatch) => {
		dispatch(fetchMainPageRequest());
		GET(MAIN_PAGE_API, (success) => {
			dispatch(fetchMainPageSuccess(success?.body));
		}, (error) => {
			dispatch(fetchMainPageFailure(error));
			dispatch(fetchMainPageFailure(''));
		})
	};
};
export const SEND_PHONE_REDUCER = (value, callback = () => { }) => {
	return (dispatch) => {
		dispatch(sendPhoneRequest());
		POST(PHONE_API, value)
			.then((success) => {
				callback()
				dispatch(sendPhoneSuccess());
			})
			.catch((error) => {
				dispatch(fetchMainPageFailure(error));
				dispatch(fetchMainPageFailure(''));
			})
	};
};

export default MainPageReducer;
