import React, { useCallback, useEffect } from 'react'
import { Container, Grid, makeStyles } from '@material-ui/core';
import MainContainerComponent from '../../components/MainContainerComponent';
import HrTitleComponent from '../../components/HrTitleComponent';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PostCardComponent from './components/PostCardComponent';
import { FETCH_BLOG_REDUCER } from '../../reducers/BlogPageReducer';
import ScrollComponent from '../../components/ScrollComponent';
import PostCardSkeletonComponent from './components/PostCardSkeletonComponent';

const useStyles = makeStyles((theme) => ({
}))

const BlogPage = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { data, loading, meta, postLoading } = useSelector(state => state.BlogPageReducer)
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(FETCH_BLOG_REDUCER(1));
	}, [dispatch])

	const handlePagination = useCallback((page) => {
		dispatch(FETCH_BLOG_REDUCER(page))
	}, [dispatch])
	return (
		<MainContainerComponent>
			<Container>
				<HrTitleComponent
					component='h1'
					title={t('blog_page.title')}
				/>
				<ScrollComponent
					length={data?.length}
					loading={postLoading}
					next={handlePagination}
					className={classes.scroll}
					meta={meta}
				>
					{loading
						? <Grid container>
							<PostCardSkeletonComponent />
							<PostCardSkeletonComponent />
							<PostCardSkeletonComponent />
							<PostCardSkeletonComponent />
							<PostCardSkeletonComponent />
							<PostCardSkeletonComponent />
							<PostCardSkeletonComponent />
							<PostCardSkeletonComponent />
							<PostCardSkeletonComponent />
							<PostCardSkeletonComponent />
						</Grid>
						: <Grid container>
							{data?.list?.length
								? data.list.map(({ title, intro, slug, src }, key) =>
									<PostCardComponent
										key={key}
										title={title}
										intro={intro}
										slug={slug}
										src={src}
									/>
								)
								: null
							}
						</Grid>
					}
				</ScrollComponent>

			</Container>
		</MainContainerComponent>
	)
}


export default React.memo(BlogPage);