import React from 'react'
import { useSelector } from 'react-redux';
import { BLOG_ROUTE } from '../../constants/routes';
import { FETCH_POST_REDUCER } from '../../reducers/PostPageReducer';
import PostComponent from '../../components/PostComponent';
import { useTranslation } from 'react-i18next';

const PostPage = (props) => {
	const { data, loading, } = useSelector((state) => state.PostPageReducer)
	const {t} = useTranslation();

	return (
		<PostComponent
			data={data}
			loading={loading}
			showPlanBtn
			breadcrumbs={[
				{
					title: t('blog_page.title'),
					to: BLOG_ROUTE
				}
			]}
			backRoute={BLOG_ROUTE}
			fetchFun={FETCH_POST_REDUCER}
		/>
	)
}

export default React.memo(PostPage);