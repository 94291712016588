import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    cont: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10,
        marginBottom: 10,
    },
    loaderBox: {
        width: 150,
        height: 150,
    }
}))

const SmallLoaderComponent = ({ show }) => {
    const classes = useStyles();

    return (

        <>{
            show
                ? <section className={classes.cont}>
                    <div className={classes.loaderBox}>
                        <CircularProgress />
                    </div>
                </section>
                : null
        } </>

    )
}


SmallLoaderComponent.propTypes = {
    show: PropTypes.bool,
}
export default React.memo(SmallLoaderComponent);