import React from 'react'
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import PaperComponent from '../../../components/PaperComponent';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import { STEPPER_ID } from '../../../constants';
import NotificationComponent from '../../../components/NotificationComponent';
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
	paperCont: {
	},
	paper: {
		maxHeight: '70vh',
		overflowY: 'auto',
	}
}))

const OnboardingSlideComponent = ({ subtitleClassName, error, loading, title, subtitle, children, step }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [stepperHeight, setStepperHeight] = useState('70vh');
	const [showNotif, setShowNotif] = useState(false);
	const handleCloseNotification = useCallback(() => {
		setShowNotif(false)
	}, [])

	useEffect(() => {
		const stepper = document.querySelector(`#${STEPPER_ID}`);
		if (stepper)
			setStepperHeight(`calc(100vh - ${stepper.clientHeight}px - 120px)`)
	}, [])

	useEffect(() => {
		if (error)
			setShowNotif(true)
		return (() => {
			setShowNotif(false)
		})
	}, [error])
	return (
		<PaperComponent
			maxWidth='md'
			title={title || t(`onboarding_page.steps.${step}.title`)}
			subtitle={subtitle || t(`onboarding_page.steps.${step}.desc`)}
			customContClass={classes.paperCont}
			subtitleClassName={subtitleClassName}
			maxHeight={stepperHeight}
			customPaperClass={classes.paper}
		>
			{children}

			<NotificationComponent
				message={error || t('errors.wrong')}
				onClick={handleCloseNotification}
				showNotif={showNotif}
			/>
		</PaperComponent>
	)
}


OnboardingSlideComponent.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
}
export default React.memo(OnboardingSlideComponent);