import React from 'react'
import { Box, Container, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import RankingComponent from '../../../components/RankingComponent';
import { useTranslation } from 'react-i18next';
import HeaderImg from '../../../imgs/headerImg.webp'
import HeaderImgBack from '../../../imgs/headerImgBack.webp'
import HeaderImgBackMobile from '../../../imgs/headerImgBack_mobile.webp'
import SmileImg from '../../../imgs/smile.webp'
import BlurMsgBoxComponent from './BlurMsgBoxComponent';
import HeaderCircleSvg from '../../../svgs/HeaderCircleSvg';
import SloganComponent from './SloganComponent';


const useStyles = makeStyles((theme) => ({
	headerImg: {
		height: 600,
		maxWidth: '100%',
		[theme.breakpoints.down('sm')]: {
			margin: "auto",
			height: 500,
		}
	},
	headerImgBox: {
		position: 'relative',
		display: 'flex',
		paddingLeft: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			// display: 'none',
		}
	},
	headerCircle: {
		opacity: 0,
		transition: "all 1s ease-in",
		position: 'absolute',
		top: 0,
		maxWidth: '50%',
		left: 0,
		right: 0,
		zIndex: -1,
		margin: 'auto',
		animation: "$fadeIn 1s linear 0.5s 1 normal forwards",
		[theme.breakpoints.down('xs')]: {
			top: -50,
		}
	},

	topCont: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: theme.spacing(8),
		[theme.breakpoints.down('sm')]: {
			marginTop: 0,
		}
	},
	firstRankingIcons: {
		top: 0,
		bottom: 0,
		marginLeft: -50,
		position: 'absolute',
	},
	imgBack: {
		position: 'absolute',
		zIndex: -2,
		width: '90%',
		objectFit: 'contain',
		right: 0,
		top: '10%',
		transition: "all 1s ease-in",
		opacity: 0,
		animation: "$fadeIn 1s linear 1s 1 normal forwards",
		[theme.breakpoints.down('xs')]: {
			width: '130%',
			right: '-20%',
		}
	},
	smile: {
		opacity: 0,
		width: 300,
		maxWidth: '100%',
		position: 'absolute',
		transition: "all 1s ease-in",
		bottom: -50,
		left: 0,
		right: 0,
		margin: 'auto',
		zIndex: -1,
		animation: "$fadeIn 1s linear 0.5s 1 normal forwards",
		[theme.breakpoints.down('xs')]: {
			bottom: -20,
		}
	},
	mobileSlogan: {
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(5),
			display: 'block',
		}
	},
	desktopSlogan: {
		display: 'block',
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(5),
		}
	},
	phoneBox: {
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			margin: 'auto',
		}
	},
	'@keyframes fadeIn': {
		'0%': {
			opacity: 0,
		},
		'100%': {
			opacity: 1,
		}
	}
}))

const BannerComponent = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'))
	return (
		<Container className={classes.topCont}>
			<Grid container alignItems='center'>
				<SloganComponent className={classes.desktopSlogan} />
				<Grid item md={6} xs={12} className={classes.headerImgBox}>
					<Box className={classes.phoneBox}>
						<RankingComponent
							ranking={5}
							size={40}
							speed={1}
							className={classes.firstRankingIcons}
						/>
						<BlurMsgBoxComponent
							top={40}
							speed={3}
							marginLeft={50}
							message={t('main_page.first_banner_msg')}
						/>
						<BlurMsgBoxComponent
							message={t('main_page.second_banner_msg')}
							top={'none'}
							speed={2}
							bottom={40}
							marginLeft={100}
							duration={"5s"}
						/>
						<img
							className={classes.headerImg}
							src={HeaderImg}
							alt={t('main_page.title_h1')}
						/>
					</Box>
					{matches
						? <img
							className={classes.imgBack}
							src={HeaderImgBackMobile}
							alt={t('main_page.alt_back')}
						/>
						: <img
							className={classes.imgBack}
							src={HeaderImgBack}
							alt={t('main_page.alt_back')}
						/>
					}
					<img
						className={classes.smile}
						src={SmileImg}
						alt={"Smile"}
					/>

					<HeaderCircleSvg className={classes.headerCircle} />
				</Grid>
			</Grid>
		</Container>
	)
}


BannerComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(BannerComponent);