import * as React from "react"

const CircleSvg = (props) => (
	<svg
		viewBox="0 0 346 346"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<path
			d="M416.807 44.365h-.007c-94.689 0-172.607 77.918-172.607 172.607S322.111 389.579 416.8 389.579s172.606-77.917 172.607-172.606c.001-94.687-77.913-172.605-172.6-172.608m0 287.771h-.002c-63.177 0-115.164-51.987-115.164-115.164s51.987-115.164 115.164-115.164 115.164 51.987 115.164 115.164v.001c0 63.176-51.986 115.162-115.162 115.163"
			style={{
				fill: "#f85f5f",
				fillOpacity: 0.7,
				fillRule: "nonzero",
			}}
			transform="translate(-244.193 -44.365)"
		/>
	</svg>
)

export default CircleSvg
