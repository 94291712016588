import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types';
import { REGISTER_ROUTE } from '../../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import AnimBtnComponent from '../../../components/form/AnimBtnComponent';

const BuyBtnComponent = ({ id, primary }) => {
	const { t } = useTranslation();
	const [showAnim, setShowAnim] = useState(false);
	const history = useHistory();

	const handleClick = useCallback(() => {
		setShowAnim(true)
		setTimeout(() => {
			history.push(`${REGISTER_ROUTE}/${id}`)
		}, 2000)
	}, [history, id])


	return (
			<AnimBtnComponent
				title={t('btns.choice')}
				handleClick={handleClick}
				showAnim={showAnim}
			primary={primary}
			/>
	)
}


BuyBtnComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(BuyBtnComponent);