import * as React from "react"

const RoadSvg = (props) => (
	<svg
		viewBox="0 0 1275 428"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
		}}
		{...props}
	>
		<path
			d="M1175.1 6661.02h214.88"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
			}}
			transform="matrix(0 -1.28 1.07835 0 -5948.13 1797.37)"
		/>
		<path
			d="M0 105.564C0 47.653 47.653 0 105.564 0"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
				strokeLinecap: "round",
				strokeMiterlimit: 10,
			}}
			transform="rotate(-180 617.38 202.151)"
		/>
		<path
			d="M.5 106.064C.5 48.153 48.153.5 106.064.5"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
				strokeLinecap: "round",
				strokeMiterlimit: 10,
			}}
			transform="translate(39.7 21.698)"
		/>
		<path
			d="M401.989 10747h26.384"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
				strokeLinecap: "round",
			}}
			transform="rotate(45 13256.958 5101.208)"
		/>
		<path
			d="M401.989 10747h26.384"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
				strokeLinecap: "round",
			}}
			transform="rotate(-45 -12634.986 5667.998)"
		/>
		<path
			d="M401.989 10747h26.384"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
				strokeLinecap: "round",
			}}
			transform="rotate(135 2995.47 5689.877)"
		/>
		<path
			d="M401.989 10747h26.384"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
				strokeLinecap: "round",
			}}
			transform="rotate(45 13443.737 6496.264)"
		/>
		<path
			d="M401.989 10747h26.384"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
				strokeLinecap: "round",
			}}
			transform="rotate(-135 -1609.866 5532.651)"
		/>
		<path
			d="M401.989 10747h26.384"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
				strokeLinecap: "round",
			}}
			transform="rotate(135 2674.221 5618.652)"
		/>
		<path
			d="M401.989 10747h26.384"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
				strokeLinecap: "round",
			}}
			transform="rotate(-135 -1905.721 5655.198)"
		/>
		<path
			d="M401.989 10747h26.384"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
				strokeLinecap: "round",
			}}
			transform="rotate(135 2378.361 5496.103)"
		/>
		<path
			d="M1175.1 6661.02h378.69"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
				strokeLinecap: "round",
			}}
			transform="rotate(-180 1152.43 3532.66)"
		/>
		<path
			d="M1175.1 6661.02h587.88"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
			}}
			transform="translate(-524.212 -6638.82)"
		/>
		<path
			d="M1175.1 6661.02h702.44"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
				strokeLinecap: "round",
			}}
			transform="rotate(-180 960.905 3532.66)"
		/>
		<path
			d="M1175.1 6661.02h500.76"
			style={{
				fill: "none",
				fillRule: "nonzero",
				stroke: "#fff",
				strokeWidth: 8,
			}}
			transform="matrix(1.00673 0 0 1 -1036.24 -6638.82)"
		/>
	</svg>
)

export default RoadSvg
