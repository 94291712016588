import React from 'react'
import { Container, makeStyles, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import HrTitleComponent from '../../../components/HrTitleComponent';
import { useTranslation } from 'react-i18next';
import PlanTitleComponent from './PlanTitleComponent';
import PlanTdComponent from './PlanTdComponent';
import BuyBtnComponent from './BuyBtnComponent';
import MobilePlansComponent from './MobilePlansComponent';
import { useSelector } from 'react-redux';
import { classNames } from '../../../functions';
import TrialTitleComponent from './TrialTitleComponent';



const useStyles = makeStyles((theme) => ({
	plansGrid: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(8),
	},
	planTitles: {
		padding: 0,
		overflow: 'hidden',
		borderRadius: 40,
		display: 'flex',
	},
	table: {
		width: '100%',
		borderCollapse: 'collapse',
	},
	tbody: {
		border: `1px solid ${theme.palette.gray.line}`,
		padding: 0,
		'& tr': {
			border: `1px solid ${theme.palette.gray.line}`,
		},
		'& td': {
			border: `1px solid ${theme.palette.gray.line}`,
		}
	},
	td: {
		padding: theme.spacing(2),
	},
	th: {
		zIndex: 1,
		position: 'sticky',
		top: 105,
	},
	thNames: {
		width: "70%",
	}
}))

const PlansComponent = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { data } = useSelector((state) => state.MainPageReducer)
	return (
		<Container className={classes.plans} id='plans'>
			<HrTitleComponent
				hiddenHelmet
				reverse
				component='h1'
				title={t('main_page.plans_title')}
			/>
			{matches
				? <MobilePlansComponent data={data} />
				: <table className={classes.table}>
					<thead>
						<tr >
							<th className={classes.th}></th>
							<th colSpan='3' className={classNames([classes.th, classes.thNames])}>
								<Paper className={classes.planTitles}>
									{data?.plans?.header?.length
										? data.plans.header.map(({ name, price, valute, id }, key) =>
											<PlanTitleComponent
												key={key}
												popular={key === 1}
												length={data?.plans?.header?.length}
												name={name}
												id={id}
												price={price}
												valute={valute}
											/>
										)
										: null
									}
								</Paper>
							</th>
						</tr>
					</thead>
					<tbody className={classes.tbody}>
						{data?.plans?.body?.length
							? data.plans.body.map(({ name, availabilityInPlan }, key) =>
								<tr key={key} data-aos="zoom-in">
									<td className={classes.td}>{name}</td>
									{availabilityInPlan?.length
										? availabilityInPlan.map((availability, key) =>
											<PlanTdComponent
												key={key}
												has={availability}
											/>
										)
										: null}
								</tr>
							)
							: null
						}
						<tr>
							<td></td>
							{data?.plans?.header?.length
								? data.plans.header.map(({ id }, key) =>
									<td key={key} className={classes.td}>
										<BuyBtnComponent

											id={id}
										/>
									<TrialTitleComponent />
									</td>
								)
								: null
							}
						</tr>
					</tbody>
				</table>}
		</Container>
	)
}

export default React.memo(PlansComponent);