import * as React from "react"

const PointSvg = (props) => (
	<svg
		viewBox="0 0 141 168"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<path
			d="M140.152 63.762C140.743 115.9 71.64 168.33 70.078 167.83 68.6 167.359-.588 122.408 0 63.762.358 28.549 31.377 0 70.078 0c38.701 0 69.674 28.549 70.074 63.762"
			style={{
				fill: "url(#pointGrad)",
				fillRule: "nonzero",
			}}
			transform="translate(.424)"
		/>
		<path
			d="m22.346 40.223-3.24-2.893C7.6 27.093 0 20.342 0 12.056v-.001C0 5.44 5.443-.002 12.057-.002c.078 0 .155 0 .233.002 3.85.021 7.513 1.69 10.056 4.581A13.496 13.496 0 0 1 32.4 0c.078-.002.155-.002.233-.002 6.614 0 12.057 5.442 12.057 12.057v.001c0 8.286-7.6 15.037-19.106 25.3l-3.238 2.867Z"
			style={{
				fill: "#fff",
				fillRule: "nonzero",
			}}
			transform="translate(48.156 55.306)"
		/>
		<defs>
			<linearGradient
				id="pointGrad"
				x1={0}
				y1={0}
				x2={1}
				y2={0}
				gradientUnits="userSpaceOnUse"
				gradientTransform="matrix(126 46.8256 -46.8256 126 0 52.7)"
			>
				<stop
					offset={0}
					style={{
						stopColor: "#f88383",
						stopOpacity: 1,
					}}
				/>
				<stop
					offset={1}
					style={{
						stopColor: "#f95d5d",
						stopOpacity: 1,
					}}
				/>
			</linearGradient>
		</defs>
	</svg>
)

export default PointSvg
