import React from 'react'
import { InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import FaqLinkComponent from '../FaqLinkComponent';

const FaqAdornmentComponent = ({ title, url }) => {

	return (
		<InputAdornment position="end">
			<FaqLinkComponent
				title={title}
				url={url}
			/>
		</InputAdornment>
	)
}


FaqAdornmentComponent.propTypes = {
	title: PropTypes.any,
	url: PropTypes.any,
}
export default React.memo(FaqAdornmentComponent);