import { FAQS_API } from "../constants/api";
import { GET, } from "../functions/superagentSending";

const initialState = {
	loading: false,
	data: {},
	error: '',
};


const FETCH_FAQS_REQUEST = 'FETCH_FAQS_REQUEST'
const FETCH_FAQS_SUCCESS = 'FETCH_FAQS_SUCCESS'
const FETCH_FAQS_FAILURE = 'FETCH_FAQS_FAILURE'

const fetchFaqsPageSuccess = (value) => ({
	type: FETCH_FAQS_SUCCESS,
	payload: value
})
const fetchFaqsPageRequest = () => ({
	type: FETCH_FAQS_REQUEST
})


// eslint-disable-next-line
const fetchFaqsPageFailure = error => ({
	type: FETCH_FAQS_FAILURE,
	payload: error
})


const FaqsPageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_FAQS_REQUEST:
			return {
				...state,
				loading: true,
				error: ''
			}
		case FETCH_FAQS_SUCCESS:
			return {
				data: {
					list: payload?.data
				},
				loading: false,
				error: ''
			}
		case FETCH_FAQS_FAILURE:
			return {
				...state,
				loading: false,
				error: payload
			}
		default:
			return state
	}
}

export const FETCH_FAQS_REDUCER = (currentPage, name) => {
	return (dispatch) => {
		dispatch(fetchFaqsPageRequest());
		GET(`${FAQS_API}?page=${currentPage}${name ? 'name=' + name : ''}`,
			(success) => {
				dispatch(fetchFaqsPageSuccess(success?.body));
			},
			(error) => {

				dispatch(fetchFaqsPageFailure(error));
				dispatch(fetchFaqsPageFailure());
			})
	}
}

export default FaqsPageReducer;