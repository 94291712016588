import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import SmallLoaderComponent from './SmallLoaderComponent';


const ScrollComponent = ({ loading, length = 0, children, meta, next = () => { }, className = '' }) => {
    const hasMore = useMemo(() => meta?.current_page < meta?.last_page, [meta])

    const handlePagination = useCallback(() => {
        next(meta?.current_page + 1)
    }, [meta, next])

    return (
        <InfiniteScroll
            dataLength={length}
            pageStart={0}
            next={handlePagination}
            className={className}
            hasMore={hasMore}
            scrollThreshold={'20%'}
            loader={<SmallLoaderComponent show={loading} />}
        >
            {children}
        </InfiniteScroll>
    )
}


ScrollComponent.propTypes = {
    length: PropTypes.number,
    loading: PropTypes.bool,
    className: PropTypes.string,
    next: PropTypes.func,
}
export default React.memo(ScrollComponent);