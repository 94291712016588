import React, { useEffect } from 'react'
import { Box, Breadcrumbs, Container, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import MainContainerComponent from './MainContainerComponent';
import HrTitleComponent from './HrTitleComponent';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LinkComponent from './LinkComponent';
import ButtonComponent from './form/ButtonComponent';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ARTICLE_CSS } from '../functions/csses';
import { MAIN_ROUTE, PLAN_ROUTE } from '../constants/routes';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	boxImg: {
		'& span': {
			width: '100%',
		},
	},
	skeletonImg: {
		width: '100%',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		height: 400,
	},
	img: {
		width: '100%',
		objectFit: 'cover',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	desc: {
		...ARTICLE_CSS(theme),
		marginTop: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(4),
		},
	},
	breadItem: {
		...theme.typography.body2,
		color: theme.palette.text.gray
	},
	separator: {
		color: theme.palette.text.gray
	},
	breadcrumbs: {
		...theme.typography.body2,
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	textSkeletonBox: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(8),
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(4),
		},
	},
	textSkeleton: {
		height: 40,
		marginBottom: theme.spacing(1),
		width: '100%',
	},
	breadSkeleton: {
		width: 100,
	},
	btn: {
		marginTop: theme.spacing(1),
	},
	planBtn: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(2),
	}
}))

const PostComponent = ({ showPlanBtn, hiddenBack, backBtnTitle, hiddenFetch, backRoute, children, data = {}, loading, breadcrumbs = [], fetchFun = () => { } }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { slug } = useParams();

	useEffect(() => {
		if (!hiddenFetch)
			dispatch(fetchFun(slug))
	}, [slug, hiddenFetch, dispatch, fetchFun])
	return (
		<MainContainerComponent hiddenCircle title={data?.title}>
			<Container>
				<HrTitleComponent
					component='h1'
					loading={loading}
					title={data?.title}
				/>
				<Box>
					<Breadcrumbs
						separator="|"
						aria-label="breadcrumb"
						classes={{
							root: classes.breadcrumbs,
							separator: classes.separator
						}}
					>
						<LinkComponent className={classes.breadItem} to={MAIN_ROUTE}>
							{t('main_page.title')}
						</LinkComponent>
						{breadcrumbs?.length
							? breadcrumbs.map(({ title, to }, key) =>
								<LinkComponent
									className={classes.breadItem}
									to={to}
									key={key}
								>
									{title}
								</LinkComponent>)
							: null
						}
						{loading
							? <Skeleton variant='text' className={classes.breadSkeleton} />
							: <Typography color="textPrimary" variant='body2'>{data?.title}</Typography>
						}
					</Breadcrumbs>
				</Box>
				{loading
					? <Skeleton
						variant='rect'
						className={classes.skeletonImg}
					/>
					: data?.src ? <Box className={classes.boxImg}>
						<LazyLoadImage
							src={data?.src}
							className={classes.img}
							alt={data?.title}
							effect='blur'
						/>
					</Box> : null}

				{loading
					? <Box className={classes.textSkeletonBox}>
						<Skeleton variant='rect' className={classes.textSkeleton} />
						<Skeleton variant='rect' className={classes.textSkeleton} />
						<Skeleton variant='rect' className={classes.textSkeleton} />
					</Box>
					: <Typography
						className={classes.desc}
						variant='body2'
						dangerouslySetInnerHTML={{ __html: data?.description }}
					/>}
				{children}
				{showPlanBtn
					? <LinkComponent to={PLAN_ROUTE} className={classes.planBtn}>
						<ButtonComponent variant='contained' color='secondary'>
							{t('btns.choice')}
						</ButtonComponent>
					</LinkComponent>
					: null
				}
				{!hiddenBack
					? <LinkComponent to={backRoute}>
						<ButtonComponent variant='contained' color='primary' className={classes.btn}>
							{backBtnTitle || t('btns.back')}
						</ButtonComponent>
					</LinkComponent>
					: null
				}

			</Container>
		</MainContainerComponent>
	)
}


PostComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(PostComponent);