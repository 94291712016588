import React, { useMemo } from 'react'
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import TextFieldComponent from './TextFieldComponent';
import BtnsComponent from './BtnsComponent';
import { useDispatch } from 'react-redux';
import CountrySelectComponent from './CountrySelectComponent';


const userSchema = (t) => Yup.object().shape({
    name: Yup.string()
        .required(t('validation.required')),
    street: Yup.string()
        .required(t('validation.required'))
        .min(2, t('validation.min', { item: 2 })),
    zipCode: Yup.string()
        .required(t('validation.required')),
    city: Yup.string()
        .required(t('validation.required')),
    country: Yup.string()
        .required(t('validation.required'))
        .min(2, t('validation.min', { item: 2 })),
});


const BusinessFormComponent = ({
    data,
    btnTitle = '',
    secondBtnTitle = '',
    hiddenCallBack,
    defaultValues = {},
    fetchFunction = () => { },
    handleToggleDialog = () => { },
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const initialValues = useMemo(() => ({
        id: data?.id || '',
        name: data?.name || '',
        street: data?.street || '',
        zipCode: data?.zipCode || '',
        city: data?.city || '',
        country: data?.country || '',
    }), [data])

    const formik = useFormik({
        validationSchema: userSchema(t),
        enableReinitialize: true,
        initialValues: initialValues,
        onSubmit: values => {
            dispatch(fetchFunction({
                ...defaultValues,
                business:{
                    ...values
                }
            }, () => {
                if (!hiddenCallBack)
                    handleToggleDialog();
            }));
            // dispatch((values));
        },
    });

    return (
        <form onSubmit={formik.submitForm}>
            <TextFieldComponent
                id='name'
                formikProps={formik}
            />
            <TextFieldComponent
                id='street'
                formikProps={formik}
            />

            <TextFieldComponent
                id='zipCode'
                formikProps={formik}
            />

            <TextFieldComponent
                id='city'
                formikProps={formik}
            />
            <CountrySelectComponent
                id='country'
                formikProps={formik}
            />
            <BtnsComponent
                handleSave={formik.submitForm}
                onClose={handleToggleDialog}
                title={btnTitle}
                secondTitle={secondBtnTitle}
            />
        </form>
    )
}


BusinessFormComponent.propTypes = {
    onClose: PropTypes.func,
}
export default React.memo(BusinessFormComponent);