import React from 'react'
import { Box, Container, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { classNames } from '../functions';

const useStyles = makeStyles((theme) => ({
	cont: {
		// width: '100vw',
		minHeight: `calc(100vh - ${theme.spacing(8)}px)`,
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	paper: {
		position: 'relative',
		padding: `${theme.spacing(5)}px ${theme.spacing(10)}px`,
		minWidth: 700,
		[theme.breakpoints.down('sm')]: {
			padding: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
			minWidth: 'auto',
			width: `calc(100% - ${theme.spacing(10)}px)`
		},
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2),
			width: `calc(100% - ${theme.spacing(4)}px)`
		},
	},
	title: {
		fontWeight: 'bold',
		textAlign: 'center',
		marginBottom: theme.spacing(2),
	},
	subtitle: {
		marginBottom: theme.spacing(2),
		textAlign: 'center',
	}
}))

const PaperComponent = ({
	customContClass = '',
	subtitleClassName = '',
	customPaperClass = '',
	title,
	subtitle,
	children,
	maxWidth,
	maxHeight
}) => {
	const classes = useStyles();

	return (
		<Container className={customContClass || classes.cont} maxWidth={maxWidth}>
			<Paper
				className={classNames([classes.paper, customPaperClass])}
				style={{ maxHeight }}
			>
				<Typography variant='h1' className={classes.title}>{title}</Typography>
				<Typography className={classNames([classes.subtitle, subtitleClassName])} dangerouslySetInnerHTML={{ __html: subtitle }} />
				<Box>
					{children}
				</Box>
			</Paper>
		</Container>
	)
}


PaperComponent.propTypes = {
	title: PropTypes.string,
	maxWidth: PropTypes.string,
	subtitle: PropTypes.string,
}
export default React.memo(PaperComponent);