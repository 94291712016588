import React from "react"
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import ButtonComponent from "./ButtonComponent";
import { useTranslation } from "react-i18next";
import LinkComponent from "../LinkComponent";
import { DEMO_LINK } from "../../constants";
import { classNames } from "../../functions";
import { LANDING_BTN } from "../../functions/csses";

const useStyles = makeStyles((theme) => ({
	btn: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		position: 'relative',
		zIndex: 1,
		...LANDING_BTN,
		boxShadow: 'none',
	},
	secondary: {
		background: theme.palette.forGradient.secondaryBtn,
	}
}));

const DemoBtnComponent = ({ color = 'primary' }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<LinkComponent to={DEMO_LINK} external_link>
			<ButtonComponent color={color} className={classNames([
				classes.btn,
				color === 'secondary'
					? classes.secondary
					: ''
			])} variant='contained'>
				{t('btns.demo')}
			</ButtonComponent>
		</LinkComponent>
	);
};

DemoBtnComponent.propTypes = {
	name: PropTypes.string,
};
export default React.memo(DemoBtnComponent);