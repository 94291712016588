import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PostComponent from '../../components/PostComponent';
import { ALREADY_VERIFIC_ERROR } from '../../constants';
import { LOGIN_ROUTE } from '../../constants/routes';
import { getParam } from '../../functions';
import { GET } from '../../functions/superagentSending';

const SucVerificationPage = (props) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [answer, setAnswer] = useState({});
	useEffect(() => {
		setLoading(true)
		const emailUrl = getParam('email_url');
		const errorAnswer = {
			title: t('suc_verification_page.title_error'),
			description: t('suc_verification_page.message_error'),
		};
		if (emailUrl) {
			GET(emailUrl,
				(success) => {
					setLoading(false)
					setAnswer({
						title: t('suc_verification_page.title'),
						description: t('suc_verification_page.desc'),
					})
				}, 
				(error) => {
					setLoading(false)
					setAnswer(error.status === ALREADY_VERIFIC_ERROR 
						? {
							title: t('suc_verification_page.already_title'),
							description: t('suc_verification_page.already_desc'),
						} 
						: errorAnswer)
				})
		} else {
			setLoading(false)
			setAnswer(errorAnswer)
		}
	}, [t])
	return (
		<PostComponent
			data={answer}
			hiddenFetch
			loading={loading}
			backBtnTitle={t('btns.login')}
			backRoute={LOGIN_ROUTE}
		/>
	)
}

export default React.memo(SucVerificationPage);