import * as React from "react"

const HotSvg = (props) => (
	<svg
		viewBox="0 0 30 49"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<path
			d="M10.308 0c.215.15.425.311.646.449a23.498 23.498 0 0 1 7.635 7.671 21.51 21.51 0 0 1 3.22 10.52c.092 2.009.145 4.024.12 6.035a22.029 22.029 0 0 1-1.336 8.1 25.81 25.81 0 0 0 5.167-16.507c.261.462.536.915.78 1.387a29.481 29.481 0 0 1 3.175 9.57A19.376 19.376 0 0 1 29 35.871a28.878 28.878 0 0 1-7.6 12.123c-.084.081-.177.151-.338.288a64.504 64.504 0 0 0 1.815-10.663 27.362 27.362 0 0 1-5.333 3.362 29.166 29.166 0 0 1-4.218-16.863c-.564.481-1.061.855-1.5 1.292a17.79 17.79 0 0 0-5 9.865 28.9 28.9 0 0 0 .824 12.443c.036.136.071.272.1.409a1.086 1.086 0 0 1-.042.219c-.836-.852-1.664-1.618-2.409-2.469a20.82 20.82 0 0 1-4.75-8.886 18.087 18.087 0 0 1 .933-11.607c1.849-4.316 3.925-8.518 5.9-12.771a38.61 38.61 0 0 0 2.453-5.977c.496-1.903.643-3.88.435-5.836-.024-.266-.033-.533-.049-.8h.087Z"
			style={{
				fill: "#fff",
				fillRule: "nonzero",
			}}
			transform="translate(.002)"
		/>
	</svg>
)

export default HotSvg
