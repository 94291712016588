import { createTheme } from '@material-ui/core';

const primaryColor = '#000000';
const primaryGradientColor = '#3493e6';
const secondaryColor = '#F87B7B';
const secondaryGradientColor = '#ec6ead';
export const mainTheme = () => createTheme({
    '*': {
        fontFamily: '"sofia-pro", "sans-serif"',
    },
    typography: {
        fontSize: 12,
        'button': {
            fontWeight: 'bold',
            fontSize: 14,
        },
        'body1': {
            fontSize: 18,
            '@media (max-width: 450px)': {
                fontSize: 14,
            }
        },
        'body2': {
            fontSize: 14,
        },
        'h3': {
            fontSize: 22,
            marginBottom: 16,
            fontWeight: 'bold',
            '@media (max-width: 450px)': {
                fontSize: 18,
            },
        },
        'h5': {
            fontSize: 25,
            fontWeight: 500,
            marginBottom: 16,
            '@media (max-width: 450px)': {
                fontSize: 22,
            },
        },
        'h4': {
            fontSize: 35,
            fontWeight: 500,
            marginBottom: 16,
            '@media (max-width: 450px)': {
                fontSize: 22,
            },
        },
        'h2': {
            fontSize: 22,
            fontWeight: '400',
            '@media (max-width: 450px)': {
                fontSize: 18,
            },
        },
        'h1': {
            fontSize: 26,
            fontWeight: 'bold',
            marginBottom: 40,
        },
        'h6': {
            fontSize: 14,
            marginBottom: 10,
            fontWeight: 'bold',
        }
        // fontFamily: '"Boing", "Roboto", "Helvetica", "Arial", "sans-serif"',
    },
    palette: {
        primary: {
            dark: '#343B4E',
            main: primaryColor,
            light: '#464E65',
        },
        secondary: {
            dark: '#E56E6E',
            main: secondaryColor,
            light: '#FF7E7E',
            // orange: '#FF963C',
        },
        forGradient: {
            primary: primaryGradientColor,
            secondary: secondaryGradientColor,
            secondaryBtn: `linear-gradient(90deg, #F88383 0%, #F95D5D 100%)`
        },
        gray: {
            line: '#00000012'
        },
        background: {
            white: '#ffffff',
            default: '#FAFAFA',
            primaryGradient: `linear-gradient(90deg, ${primaryGradientColor} 0%, ${secondaryGradientColor} 100%)`,
            aboutGradient: `linear-gradient(90deg, #FFE940 0%, #FF0093 100%)`,
            lastAboutGradient: `linear-gradient(90deg, #5CB9D0 0%, #7C64FF 100%)`,
            howItWorkGradient: `linear-gradient(90deg, #AB3F91 0%, #FF188A 50%, #FFE940 100%)`,
            footerGradient: `linear-gradient(90deg, #DB77FF 0%, #FFE5AA 100%)`,
            planGradient: `linear-gradient(25deg, #F09CDD 0%, #F95E5E 100%)`
        },
        text: {
            primary: '#000000',
            white: '#ffffff',
            gray: '#9A9A9A',

        },
        paper: {

        }

    },
    overrides: {
        MuiChip: {
            label: {
                fontSize: 14,
                paddingTop: 10,
                paddingBottom: 10,
            },
            root: {
                marginTop: 5,
                color: primaryColor,
                marginRight: 5,
            },
            colorSecondary: {
                color: secondaryColor,
                backgroundColor: `${secondaryColor}50`,
                '&:hover': {
                    backgroundColor: `${secondaryColor}70 !important`,
                },
                '&:focus': {
                    backgroundColor: `${secondaryColor}70 !important`,
                },

            }
        },
        MuiPaper: {
            elevation24: {
                borderRadius: 15,
            },
            elevation1: {
                borderRadius: 15,
                padding: 16,
                // background: '#F8F8F8',
                // overflow: 'hidden',
            }
        },
    },
    props: {
        MuiContainer: {
            // maxWidth: "md",
        },
    },
})

const MainTheme = mainTheme();
export default MainTheme