import React, { useCallback, useMemo } from 'react'
import { Box, FormHelperText, makeStyles, } from '@material-ui/core';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { INITIAL_COUNTRY } from '../../constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	containerInput: {
		width: '100%',
		'& .special-label': {
			fontSize: theme.typography.body1.fontSize,
			transform: 'scale(0.75)',
			left: 0,
			color: 'rgba(0, 0, 0, 0.54)',
		},
		'& .form-control': {
			fontSize: theme.typography.body1.fontSize,
			width: '100%',
			'&:focus': {
				borderColor: theme.palette.primary.main,
				boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
				'& .special-label': {
					color: theme.palette.primary.main
				}
			}
		},

		'&:hover': {
			'& .special-label': {
				color: theme.palette.primary.main
			}
		}
	},
}))

const PhoneFieldComponent = ({
	formikProps = {},
	onBlur = () => { },
	onChange = () => { },
	id,
	currentValue,
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const value = useMemo(() => currentValue || formikProps.values[id], [formikProps, id, currentValue]);
	const error = useMemo(() => formikProps.errors[id], [formikProps, id]);
	const touched = useMemo(() => formikProps.touched[id], [formikProps, id]);
	const isError = useCallback(() => error && Boolean(touched) ? true : false, [touched, error])
	const handleBlur = useCallback((event) => {
		onBlur(event);
		formikProps.handleBlur(event)
	}, [formikProps, onBlur])
	const handleChange = useCallback((phone) => {
		const newPhone = phone.search('/+/') !== -1 ? phone : `+${phone}`
		onChange(newPhone);
		formikProps.setFieldValue(
			id,
			newPhone
		)
	}, [formikProps, onChange, id])
	return (
		<Box>
			<PhoneInput
				inputProps={{
					id,
					name: id,
					autoComplete: 'tel',
					// className: classes.input,
					// required: true,
					error: isError() ? error : ' ',
				}}
				containerClass={classes.containerInput}
				inputClass={classes.input}
				specialLabel={t(`form.${id}`)}
				value={value}
				country={INITIAL_COUNTRY}
				onChange={handleChange}
				onBlur={handleBlur}
			// onChange={phone => setPhone(phone)}
			/>
			<FormHelperText error className={isError() ? '' : classes.hiddenHelperText}>{isError() ? error : ' '}</FormHelperText>
		</Box>
	)
}


PhoneFieldComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(PhoneFieldComponent);