import React, { useEffect } from 'react'
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import CookiesComponent from './CookiesComponent';

// import CursorBallComponent from './CursorBallComponent';


const useStyles = makeStyles((theme) => ({
	mainBox: {
		overflow: 'hidden',
		position: 'relative',
		minHeight: '100vh',
		'& *': {
			fontFamily: '"lato", "sans-serif"',
		},
		[theme.breakpoints.down('sm')]: {
			// paddingTop: theme.spacing(7),
			paddingTop: theme.spacing(13),
		}
	}
}))

const MainContainerComponent = ({ title, children, hiddenCircle }) => {
	const classes = useStyles();

	useEffect(() => {
			window.scrollTo(0, 0)
		// eslint-disable-next-line
	}, [window.location.href])
	return (
		<Box className={classes.mainBox} id='landing-cont'>
		
			<HeaderComponent />
			{children}
			<CookiesComponent />
			<FooterComponent
				hiddenCircle={hiddenCircle}
			/>
			{/* <CursorBallComponent /> */}
		</Box>
	)
}


MainContainerComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(MainContainerComponent);