import React from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import LinkComponent from '../../../components/LinkComponent';
import { BLOG_ROUTE } from '../../../constants/routes';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const useStyles = makeStyles((theme) => ({
	link: {
		display: 'block',
		height: `calc(100% - ${theme.spacing(6)}px)`,
		padding: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
			height: `calc(100% - ${theme.spacing(4)}px)`,
		},
		[theme.breakpoints.down('xs')]: {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
	intro: {
		flex: '1',
	},
	img: {
		width: '100%',
		objectFit: 'cover',
		height: 250,
		marginTop: theme.spacing(2),
	},
	postBox: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		fontWeight: 400,
		marginBottom: theme.spacing(2),
	},
	imgBox: {
		'& span': {
			width: '100%',
		}
	}
}))

const PostCardComponent = ({ title, intro, slug, src }) => {
	const classes = useStyles();
	return (
		<Grid item md={4} sm={6} xs={12}>
			<LinkComponent to={`${BLOG_ROUTE}/${slug}`} className={classes.link}>
				<Box className={classes.postBox}>
					<Typography
						variant='h1'
						className={classes.title}
						component='h2'
					>{title}
					</Typography>
					<Typography variant='body2' className={classes.intro}>{intro}</Typography>
					<Box className={classes.imgBox}>
					<LazyLoadImage
						src={src}
						className={classes.img}
						alt={title}
						effect='blur'
					/>
					</Box>
				</Box>
			</LinkComponent>
		</Grid>
	)
}


PostCardComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(PostCardComponent);