import * as React from "react"

const LogoSvg = (props) => (
	<svg
		viewBox="0 0 237 75"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<g transform="translate(-202.336 -169.25)">
			<clipPath id="a">
				<path d="M298.299 229.497a12.32 12.32 0 0 1-9.345 4.577c-.127 0-.254.01-.382.01a12.34 12.34 0 0 1-9.87-4.591 1.29 1.29 0 0 0-1.912-.306l-3.367 2.219a1.311 1.311 0 0 0-.306 1.913c3.06 4.131 8.187 7.115 15.607 7.115.155 0 .306-.007.459-.011v-.018c6.928-.236 11.768-3.128 14.7-7.087a1.31 1.31 0 0 0-.306-1.913l-3.367-2.219a1.446 1.446 0 0 0-.825-.3 1.484 1.484 0 0 0-1.087.606" />
			</clipPath>
			<g clipPath="url(#a)">
				<path
					style={{
						fill: "url(#b)",
					}}
					d="M0 0h43v21H0z"
					transform="translate(267 223)"
				/>
			</g>
		</g>
		<path
			d="M394.6 48.294a1.648 1.648 0 0 1 .077 2.448l-1.836 2.066a2.017 2.017 0 0 1-2.754.077 15.77 15.77 0 0 0-9.41-3.29c-4.284 0-6.886 2.142-6.886 4.667 0 2.219 1.53 3.672 5.356 4.361l6.35 1.224c6.886 1.377 10.941 4.361 10.941 10.558 0 5.968-5.509 11.476-15.378 11.476a21.677 21.677 0 0 1-14.077-4.667c-1.913-1.607-1.76-2.984-.689-3.9l1.989-1.683c1.071-.918 1.989-.382 2.754.23a14.952 14.952 0 0 0 10.328 4.208c4.973 0 7.5-2.525 7.5-5.05 0-2.831-2.219-4.132-6.58-4.973L376.16 64.9c-6.121-1.224-9.869-4.591-9.869-10.176 0-6.427 6.12-11.017 14.689-11.017 6.274 0 11.017 2.219 13.618 4.591"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-231.639 -27.697)"
		/>
		<path
			d="M610.347 46c.027-.002.054-.002.081-.002a1.387 1.387 0 0 1 1.373 1.532v4.147a1.806 1.806 0 0 1-1.454 1.9h-2.629c-5.2 0-8.8 2.907-8.8 10.635v16.829a1.361 1.361 0 0 1-1.378 1.3h-4.596a1.378 1.378 0 0 1-1.371-1.371V47.684l-.001-.064c0-.758.62-1.384 1.378-1.39h4.284a1.43 1.43 0 0 1 1.377 1.377v4.437A10.45 10.45 0 0 1 608.1 46h2.247Z"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-374.909 -29.153)"
		/>
		<path
			d="M19.587 16.072A18.577 18.577 0 0 0 7.344 20.66V1.372C7.344.619 6.725 0 5.972 0H1.453L1.39-.001C.632-.001.007.618 0 1.376v50.069a1.396 1.396 0 0 0 1.39 1.378l.063-.001h4.438a1.445 1.445 0 0 0 1.453-1.377v-2.666a18.651 18.651 0 0 0 12.243 4.583c10.229 0 18.645-8.417 18.645-18.645s-8.416-18.645-18.645-18.645m0 31.089h-.001c-6.825 0-12.442-5.617-12.442-12.442s5.617-12.442 12.442-12.442 12.442 5.617 12.442 12.442v.001c-.001 6.825-5.616 12.44-12.441 12.441"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(0 .001)"
		/>
		<path
			d="M143.134 44.365h-.001c-10.23 0-18.648 8.418-18.648 18.648s8.418 18.648 18.648 18.648 18.648-8.418 18.648-18.648v-.001c0-10.229-8.418-18.647-18.647-18.647m0 31.089c-6.825 0-12.442-5.617-12.442-12.442s5.617-12.442 12.442-12.442 12.442 5.617 12.442 12.442-5.617 12.442-12.442 12.442"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-78.893 -28.116)"
		/>
		<path
			d="M262.849 44.365c-10.229.002-18.645 8.419-18.645 18.648 0 10.23 8.418 18.648 18.648 18.648S281.5 73.243 281.5 63.013v-.001c0-10.229-8.418-18.647-18.647-18.647m0 31.089c-6.825 0-12.442-5.617-12.442-12.442s5.617-12.442 12.442-12.442 12.442 5.617 12.442 12.442-5.617 12.442-12.442 12.442"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-154.762 -28.116)"
		/>
		<path
			d="M486.447 43.7c-10.864 0-18.744 8.569-18.744 19.2 0 10.864 7.881 18.974 18.974 18.974 7.421 0 12.547-2.984 15.607-7.116a1.31 1.31 0 0 0-.306-1.913l-3.366-2.218a1.286 1.286 0 0 0-1.913.306 12.338 12.338 0 0 1-9.869 4.591c-6.079.07-11.207-4.718-11.553-10.788h28a1.33 1.33 0 0 0 1.454-1.454v-1.371c0-10.251-7.574-18.208-18.285-18.208m.077 6.12c5.438-.14 10.154 3.905 10.845 9.3H475.55c1.13-5.47 5.378-9.3 10.974-9.3"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-296.406 -27.697)"
		/>
		<defs>
			<linearGradient
				id="b"
				x1={0}
				y1={0}
				x2={1}
				y2={0}
				gradientUnits="userSpaceOnUse"
				gradientTransform="matrix(43 10.5 -10.5 43 0 0)"
			>
				<stop
					offset={0}
					style={{
						stopColor: "#f89191",
						stopOpacity: 1,
					}}
				/>
				<stop
					offset={1}
					style={{
						stopColor: "#f95050",
						stopOpacity: 1,
					}}
				/>
			</linearGradient>
		</defs>
	</svg>
)

export default LogoSvg
