import React from 'react'
import { Box, Container, Drawer, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import HamburgerBtnComponent from './HamburgerBtnComponent';
import LinkComponent from './LinkComponent';
import ButtonComponent from './form/ButtonComponent';
import { LANDING_BTN } from '../functions/csses';

const useStyles = makeStyles((theme) => ({
    logo: {
        [theme.breakpoints.down('sm')]: {
            width: 60,
            height: 40,
        },
    },
    mobileMenu: {
        height: '100vh',
        background: theme.palette.secondary.main,
    },
    mobileBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 40,
    },
    item: {
        ...theme.typography.h3,
        color: theme.palette.text.white,
        textAlign: 'center',
        marginBottom: 40,
    },
    menuCont: {
        flex: 1,
        marginTop: 40,
        marginBottom: 40,
    },
    btnBox: {
        display: 'flex',
        justifyContent: 'center',
    },
    btn: {
        ...LANDING_BTN,
    }
}))

const MobileMenuComponent = ({ loginLink = {}, menu = [], show, toggleShow = () => { } }) => {
    const classes = useStyles();
    return (
        <Drawer 
        open={Boolean(show)} 
        // open={true} 
        color='primary'
        classes={{
            paper: classes.mobileMenu
        }}
        // className={classes.mobileMenu}
        anchor='top'
        >
            <Container className={classes.cont}>
                <Box className={classes.mobileBtn}>
                    <HamburgerBtnComponent
                        toggleShow={toggleShow}
                        show={show}
                    />
                </Box>
                <Box className={classes.menuCont}>
                    {menu?.length
                        ? menu.map(({ title, to, external_link }, key) =>
                            <LinkComponent to={to} title={title} className={classes.link} external_link={external_link} key={key}>
                                <Typography
                                    className={classes.item}
                                >
                                    {title}
                                </Typography>
                            </LinkComponent>
                        )
                        : null
                    }
                    <Box className={classes.btnBox}>
                    <LinkComponent to={loginLink.to} external_link={loginLink.external_link} target={loginLink.target}>
                        <ButtonComponent variant='contained' color='primary' className={classes.btn}>
                            {loginLink.title}
                        </ButtonComponent>
                    </LinkComponent>
                    </Box>
                </Box>
            </Container>


        </Drawer>
    )
}


MobileMenuComponent.propTypes = {
    loginLink: PropTypes.object,
    menu: PropTypes.array,
}
export default React.memo(MobileMenuComponent);