import { FAQS_API } from '../constants/api';
import { GET } from '../functions/superagentSending';

const initialState = {
	loading: true,
	data: {},
	error: '',
};

const FETCH_HELP_POST_REQUEST = 'FETCH_HELP_POST_REQUEST';
const FETCH_HELP_POST_SUCCESS = 'FETCH_HELP_POST_SUCCESS';
const FETCH_HELP_POST_FAILURE = 'FETCH_HELP_POST_FAILURE';

const fetchHelpPostPageRequest = () => ({
	type: FETCH_HELP_POST_REQUEST,
});

const fetchHelpPostPageSuccess = (data) => ({
	type: FETCH_HELP_POST_SUCCESS,
	payload: data,
});

const fetchHelpPostPageFailure = (error) => ({
	type: FETCH_HELP_POST_FAILURE,
	payload: error,
});

const HelpPostPageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_HELP_POST_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_HELP_POST_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
				error: '',
			};
		case FETCH_HELP_POST_FAILURE:
			return {
				loading: false,
				data: {},
				error: payload,
			};
		default:
			return state;
	}
};

export const FETCH_HELP_POST_REDUCER = (slug) => {
	return (dispatch) => {
		dispatch(fetchHelpPostPageRequest());
		GET(`${FAQS_API}/${slug}`, (success) => {
			dispatch(fetchHelpPostPageSuccess(success?.body?.data));
		}, (error) => {
			dispatch(fetchHelpPostPageFailure(error));
			dispatch(fetchHelpPostPageFailure(''));
		})
	};
};

export default HelpPostPageReducer;
