import React, { useCallback } from 'react'
import { Box, CircularProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import OnboardingSlideComponent from './OnboardingSlideComponent';
import { ONBOARDING_FINISH_STEP, SUCCESS_ONBOARDING_USER_COM, TOKEN_NAME, USER_CREATION_USER_COM } from '../../../constants';
import ButtonComponent from '../../../components/form/ButtonComponent';
import { useTranslation } from 'react-i18next';
import LinkComponent from '../../../components/LinkComponent';
import { MAIN_ROUTE } from '../../../constants/routes';
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GET } from '../../../functions/superagentSending';
import { CHECK_READY_API } from '../../../constants/api';
import { sendEvent } from '../../../functions';

const useStyles = makeStyles((theme) => ({
	videoBox: {
		border: '1px solid black',
		width: '100%',
		height: 300,
	},
	btn: {
		background: theme.palette.background.primaryGradient,
	},
	actionBox: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(8),
	},
	loadingBox: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: '80%',
	},
	loadingTitle: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
	subtitle: {
		textAlign: "start !important",
		"& p": {
			fontSize: 14,
		},
		"& li": {
			fontSize: 14,
			paddinLeft: theme.spacing(2),
		},
		'& h3': {
			...theme.typography.h3,
		},
	}
}))

const FinishStepComponent = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [error, setError] = useState(false);
	const [showLoading, setShowLoading] = useState(true);
	const { data } = useSelector(state => state.OnboardingReducer);
	const [checkedData, setCheckedData] = useState({});
	const checkReady = useCallback(() => {
		setError(false)
		GET(CHECK_READY_API, (success) => {
			const data = success?.body?.data;
			if (!data?.is_ready)
				setTimeout(() => {
					checkReady();
				}, 3000)
			else{
				setShowLoading(false);
				setCheckedData(data);
			}
		}, (error) => {
			setError(t('error.create'))	
		})
	}, [t])
	useEffect(() => {
		if (data?.currentStep === ONBOARDING_FINISH_STEP){
			checkReady();
		}
	}, [data, checkReady])

	const handleStart = useCallback(() => {
		sendEvent({
			name: USER_CREATION_USER_COM, body: {
				name: SUCCESS_ONBOARDING_USER_COM
			}
		});
		window.location.href = `${checkedData?.redirect_url}?${TOKEN_NAME}=${checkedData?.access_token}`
	}, [checkedData]);
	return (
		<OnboardingSlideComponent
			error={error ? t('errors.login') : false}
			step={ONBOARDING_FINISH_STEP}
			subtitleClassName={classes.subtitle}
		>
			{/* <Box className={classes.videoBox}></Box> */}
			<Box className={classes.actionBox}>
				{showLoading
					? <Box className={classes.loadingBox}>
						<Box>
							<CircularProgress />
						</Box>
						<Typography className={classes.loadingTitle}>{t('onboarding_page.finish_loading')}</Typography>
					</Box>
					: <LinkComponent to={MAIN_ROUTE}>
						<ButtonComponent
							variant='contained'
							onClick={handleStart}
							className={classes.btn}
						>{t('btns.start')}</ButtonComponent>
					</LinkComponent>
				}
			</Box>


		</OnboardingSlideComponent>
	)
}


FinishStepComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(FinishStepComponent);