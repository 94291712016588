import React from "react"
import PropTypes from "prop-types";
import { Typography, makeStyles } from "@material-ui/core";
import StarComponent from "../../../components/StarComponent";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	trialTitle: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginLeft: theme.spacing(2),
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
			marginTop: theme.spacing(2),
			marginBottom: 0,
		}
	},
}));

const TrialTitleComponent = () => {
 const classes = useStyles();
	const {t} = useTranslation();
	return (
		<Typography className={classes.trialTitle} variant='body2'>
			<StarComponent /> {t("main_page.trial_title")}
		</Typography>
	);
};

TrialTitleComponent.propTypes = {
	 name: PropTypes.string,
};
export default React.memo(TrialTitleComponent);