import React from 'react'
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import OnboardingSlideComponent from './OnboardingSlideComponent';
import { useTranslation } from 'react-i18next';
import { ONBOARDING_PAY_STEP } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { UPDATE_ONBOARDING_REDUCER } from '../../../reducers/OnboardingReducer';
import BtnsComponent from '../../../components/form/BtnsComponent';

const useStyles = makeStyles((theme) => ({
	carDataForm: {
		padding: `${theme.spacing(2.3125)}px ${theme.spacing(1.75)}px`,
		border: '1px solid rgba(0, 0, 0, 0.23)',
		borderRadius: 4,
		'&:hover': {
			borderColor: theme.palette.primary.main,
		},
		'&:focus': {
			borderColor: theme.palette.primary.main,
		},
		'&:active': {
			borderColor: theme.palette.primary.main,
		},
	},
	btn: {
		marginTop: theme.spacing(4),
	}
}))

const PayStepComponent = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { data } = useSelector(state => state.OnboardingReducer);
	const stripe = useStripe();
	const elements = useElements();
	const dispatch = useDispatch();
	const handleSubmit = async (event) => {
		// Block native form submission.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardElement);

		// Use your card Element with other Stripe.js APIs
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		});

		if (error) {
			console.log('[error]', error);
		} else {
			dispatch(UPDATE_ONBOARDING_REDUCER({
				paymentMethod: paymentMethod.id,
				currentStep: ONBOARDING_PAY_STEP
			}))
		}
	};
	return (
		<>
			<OnboardingSlideComponent
				title={t(`onboarding_page.steps.${ONBOARDING_PAY_STEP}.title`)}
				subtitle={
					t(`onboarding_page.steps.${ONBOARDING_PAY_STEP}.desc`,
						{
							plan: data?.plan,
							price: data?.price,
							valute: data?.valute,
						}
					)}
			>
				<form onSubmit={handleSubmit}>
					<CardElement
						className={classes.carDataForm}

					/>
					<BtnsComponent
						type='submit'
						title={t('btns.pay')}
						hiddenSecondBtn
						disabled={!stripe}
					/>
				</form>
			</OnboardingSlideComponent>
		</>
	)
}


PayStepComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(PayStepComponent);