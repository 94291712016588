import React from "react"
import { Box, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import HotSvg from "../../../svgs/HotSvg";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../functions";
import { VALUTE } from "../../../constants";
import BuyBtnComponent from "./BuyBtnComponent";

const useStyles = makeStyles((theme) => ({
	perLocal: {
		marginBottom: theme.spacing(2),
	},
	hotIcon: {
		height: 30,
		marginRight: theme.spacing(2),
	},
	planBox: {
		padding: theme.spacing(2),
		paddingTop: theme.spacing(4),
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('xs')]: {
			padding: 0,
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
			borderRadius: 15,
		}
	},
	hotPlan: {
		background: theme.palette.background.planGradient,
		'& *': {
			color: theme.palette.text.white,
		}
	},
	hotBox: {
		display: 'flex',
		alignItems: 'center',
	},
	hotText: {
		color: theme.palette.text.white,
		margin: 0,
	},
	name: {
		textAlign: 'center',
		fontWeight: 'bold',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1),
	},
	price: {
		textAlign: 'center',
		fontWeight: 'bold',
		marginTop: theme.spacing(1),
	},
	type: {
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
	}
}));

const PlanTitleComponent = ({ id, name, length = 1, popular, price, valute }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box
			className={classNames([classes.planBox, popular ? classes.hotPlan : null])}
			width={`${100 / length}%`}
		>
			<Box className={classes.hotBox}>
				<HotSvg className={classes.hotIcon} />
				<Typography variant="h3" component='p' className={classes.hotText}>{t('main_page.hot_plan')}</Typography>
			</Box>
			<Typography variant="h4" className={classes.name}>{name}</Typography>
			<Typography variant="h5" component='p' className={classes.price}>{price} {valute || VALUTE} / {t("main_page.month_plan")}</Typography>
			<Typography variant="body2" className={classes.perLocal}>{t("main_page.per_local")}</Typography>
			<BuyBtnComponent
				id={id}
				primary={popular ? true : false}
			/>
			{/* <DemoBtnComponent color={popular ? 'primary' : 'secondary'} /> */}
		</Box>
	);
};

PlanTitleComponent.propTypes = {
	name: PropTypes.string,
};
export default React.memo(PlanTitleComponent);