import React from 'react'
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import RankingIconComponent from './RankingIconComponent';

const RankingComponent = ({  speed, size, ranking, justifyContent = 'center', className = '' }) => {
    return (
            <Box display='flex' justifyContent={justifyContent} alignItems='center' className={className}>
                <RankingIconComponent size={size} ranking={ranking} index={1} />
                <RankingIconComponent size={size} ranking={ranking} index={2} />
                <RankingIconComponent size={size} ranking={ranking} index={3} />
                <RankingIconComponent size={size} ranking={ranking} index={4} />
                <RankingIconComponent size={size} ranking={ranking} index={5} />
            </Box>
    )
}


RankingComponent.propTypes = {
    ranking: PropTypes.number,
    className: PropTypes.string,
    justifyContent: PropTypes.string,
}
export default React.memo(RankingComponent);