import React from "react"
import PostComponent from "../../components/PostComponent";
import { useTranslation } from "react-i18next";
import { MAIN_ROUTE } from "../../constants/routes";
import AboutImg from '../../imgs/about.jpg'
import AboutImgMobile from '../../imgs/about_mobile.jpg'
import { useMediaQuery, useTheme } from "@material-ui/core";
const AboutPage = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'))
	const {t} = useTranslation();
	return (
		<PostComponent
			data={{
				src: matches ? AboutImgMobile : AboutImg,
				title: t('about_us_page.title'),
				description: t('about_us_page.desc'),
			}}
			hiddenFetch
			backRoute={MAIN_ROUTE}
		/>
	);
};

export default React.memo(AboutPage);