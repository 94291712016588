import { useFormik } from 'formik';
import React, { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import GradientContainerComponent from '../../components/GradientContainerComponent';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../constants/routes';
import { POST } from '../../functions/superagentSending';
import { PASSWORD_RESET_API } from '../../constants/api';
import TextFieldComponent from '../../components/form/TextFieldComponent';
import BtnsComponent from '../../components/form/BtnsComponent';

const passwordSchema = (t) => Yup.object().shape({
    email: Yup.string()
        .email(t('validation.email'))
        .required(t('validation.required'))
        .min(2, t('validation.min', { item: 4 }))
        .max(80, ('validation.max', { item: 80 })),
    password: Yup.string()
        .min(8, t('validation.min', { item: 8 }))
        .required(t('validation.required')),
});
const ResetPage = (props) => {
    const history = useHistory();
    const [succ, setSucc] = useState(false);
    const [error, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const locations = useLocation();
    const [token, setToken] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState(false);
    const { t } = useTranslation();
    const formik = useFormik({
        validationSchema: passwordSchema(t),
        initialValues: {
            password: '',
            password_confirmation: '',
        },
        onSubmit: values => {
            setErr(false)
            setLoading(false)
            POST(PASSWORD_RESET_API, { ...values, token })
                .then((success) => {
                    setSucc(true)
                    setLoading(false)
                    const login = setTimeout(() => {
                        history.push(LOGIN_ROUTE)
                    }, 2000)
                    return () => {
                        clearInterval(login);
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    formik.resetForm();
                    setErr(true)
                })
        }
    });

    useEffect(() => {
        setToken(locations)
        const tokenIndex = locations.search.lastIndexOf('token=');
        if (tokenIndex && tokenIndex >= 0) {
            setToken(locations.search.substring(tokenIndex + 6))
        }
        else
            history.push(LOGIN_ROUTE)
        // eslint-disable-next-line
    }, [])
    const handleCheckNewPassword = useCallback(() => {
        setPasswordConfirmation(formik.values.password !== formik.values.password_confirmation)
    }, [formik])
    const handleBackClick = useCallback(() => {
        history.push(LOGIN_ROUTE);
    }, [history])
    return (
        <GradientContainerComponent
            title={succ ? t('reset_page.success_title') :t('reset_page.title')}
            loading={loading}
            formik={formik}
            message={succ ? t('reset_page.success') : false}
            messageBtn={t('btns.login')}
            messageBtnTo={LOGIN_ROUTE}
            error={error ? t('errors.reset') : false}
        >
            <form onSubmit={formik.submitForm}>
            <input
                type="text" name="email" defaultValue="..." autoComplete="username email" style={{ display: 'none' }}
            />
                <TextFieldComponent formikProps={formik} autoComplete='email' id='email' type='email' />
            <TextFieldComponent formikProps={formik} autoComplete='new-password' id='password' type='password' placeholder={t('form.new_password')} />
            <TextFieldComponent
                formikProps={formik}
                autoComplete='new-password'
                id='password_confirmation'
                type='password'
                placeholder={t('form.password_confirmation')}
                onBlur={handleCheckNewPassword}
                customHelperText={passwordConfirmation ? t('validation.repeat_password') : ''}
            />
                <BtnsComponent
                    gradient
                    title={t('btns.reset')}
                    secondTitle={t('btns.remember')}
                    handleSave={formik.submitForm}
                    onClose={handleBackClick}
                />
            </form>
        </GradientContainerComponent>
    )
}


export default ResetPage;