import { BLOG_API } from '../constants/api';
import { GET } from '../functions/superagentSending';

const initialState = {
	loading: true,
	data: {},
	error: '',
};

const FETCH_POST_REQUEST = 'FETCH_POST_REQUEST';
const FETCH_POST_SUCCESS = 'FETCH_POST_SUCCESS';
const FETCH_POST_FAILURE = 'FETCH_POST_FAILURE';

const fetchPostPageRequest = () => ({
	type: FETCH_POST_REQUEST,
});

const fetchPostPageSuccess = (data) => ({
	type: FETCH_POST_SUCCESS,
	payload: data,
});

const fetchPostPageFailure = (error) => ({
	type: FETCH_POST_FAILURE,
	payload: error,
});

const PostPageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_POST_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_POST_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
				error: '',
			};
		case FETCH_POST_FAILURE:
			return {
				loading: false,
				data: {},
				error: payload,
			};
		default:
			return state;
	}
};

export const FETCH_POST_REDUCER = (slug) => {
	return (dispatch) => {
		dispatch(fetchPostPageRequest());
		GET(`${BLOG_API}/${slug}`, (success) => {
			dispatch(fetchPostPageSuccess(success?.body?.data));
		}, (error) => {
			dispatch(fetchPostPageFailure(error));
			dispatch(fetchPostPageFailure(''));
		})
	};
};

export default PostPageReducer;
