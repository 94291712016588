import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DemoBtnComponent from "../../../components/form/DemoBtnComponent";
import { classNames } from "../../../functions";

const useStyles = makeStyles((theme) => ({
	sloganBox: {
		paddingRight: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			paddingRight: 0,
		}
	},
	title: {
		fontSize: 70,
		fontWeight: 500,
		[theme.breakpoints.down('sm')]: {
			fontSize: 40,
		}
	},
	slogan: {
		marginBottom: theme.spacing(2),
	},
}));

const SloganComponent = ({ className = ''}) => {
	const classes = useStyles();
	const {t} = useTranslation();
	return (
		<Grid item md={6} xs={12} className={classNames([className, classes.sloganBox])}>
			<Typography
				variant='h1'
				className={classes.title}
			>
				{t('main_page.title_h1')}
			</Typography>
			<Typography className={classes.slogan}>{t('main_page.slogan')}</Typography>
			<DemoBtnComponent color='secondary' />
		</Grid>
	);
};

SloganComponent.propTypes = {
	className: PropTypes.string,
};
export default React.memo(SloganComponent);