import React from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	img: {
		height: 250,
		width: '100%',
		marginTop: theme.spacing(2),
	},
	title: {
		width: '50%',
		height: 30,
		marginBottom: theme.spacing(2),
	},
	text: {
		marginBottom: theme.spacing(1),
		width: '100%',
	},
	postBox: {
		margin: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(2),
		},
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
			marginRight: 0,
		},
	}
}))

const PostCardSkeletonComponent = (props) => {
	const classes = useStyles();

	return (
		<Grid item md={4} sm={6} xs={12}>
			<Box className={classes.postBox}>
				<Skeleton variant='rect' className={classes.title} />

				<Skeleton className={classes.text} />
				<Skeleton className={classes.text} />
				<Skeleton className={classes.text} />
				<Skeleton
					className={classes.img}
					variant='rect'
				/>
			</Box>
		</Grid>
	)
}


PostCardSkeletonComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(PostCardSkeletonComponent);