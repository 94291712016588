export const isAccountAdded = (t, accountAdded) => accountAdded ? t('employees_page.account_added') : t('employees_page.account_not_added')

export const classNames = (classes) => classes.map((cl) => `${cl}`).join(' ')

export const getId = (item) => item?.id || item?._id || ""

export const scrollToTextField = (e) => {
	e.currentTarget.scrollIntoView({
		block: "center",
		behavior: "smooth"
	});
}

export const getParam = (paramName) => {
	const params = new URLSearchParams(window.location.search);
	return params.get(paramName)
}



export const sendEvent = ({ name, body = {} }) => {
	const updatedBody = {
		...body,
		...(body?.name
			? {
				name: body.name.toLowerCase(),
			}
			: {}),
	};
	window.userengage(`event.${name}`, updatedBody);
};
