import React from "react"
import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import MainContainerComponent from "../../components/MainContainerComponent";
import PlansComponent from "../mainPage/components/PlansComponent";
import { useDispatch } from "react-redux";
import { FETCH_MAIN_PAGE_REDUCER } from "../../reducers/MainPageReducer";
import { useEffect } from "react";
import EnterpriseComponent from "../mainPage/components/EnterpriseComponent";

const useStyles = makeStyles((theme) => ({
	box: {
		paddingTop: theme.spacing(8),
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(4),
		}
	}
}));

const PlanPage = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(FETCH_MAIN_PAGE_REDUCER())
	}, [dispatch])
	return (
		<MainContainerComponent>
			<Box className={classes.box}>
				<PlansComponent />
				<EnterpriseComponent />
			</Box>
		</MainContainerComponent>
	);
};

PlanPage.propTypes = {
	name: PropTypes.string,
};
export default React.memo(PlanPage);