import React from "react"
import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import PlanTitleComponent from "./PlanTitleComponent";
import CheckIcon from '@material-ui/icons/Check';
import BuyBtnComponent from "./BuyBtnComponent";
import TrialTitleComponent from "./TrialTitleComponent";
import { classNames } from "../../../functions";

const useStyles = makeStyles((theme) => ({
	planTitle: {
		padding: 0,
		boxShadow: 'none',
	},
	plan: {
		border: `1px solid ${theme.palette.gray.line}`,
		borderRadius: 15,
		marginBottom: theme.spacing(4),
		marginTop: theme.spacing(4),
	},
	check: {
		width: 30,
		height: 30,
	},
	item: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		margin: `0 ${theme.spacing(4)}px`,
		padding: theme.spacing(3),
		borderTop: `1px solid ${theme.palette.gray.line}`,
	},
	itemBtn: {
		[theme.breakpoints.down('xs')]: {
			flexDirection: "column",
		}
	},
	text: {
		fontWeight: 'bold',
		marginRight: theme.spacing(1),
	}
}));

const MobilePlansComponent = ({ data }) => {
	const classes = useStyles();

	return (
		<section className={classes.section}>
			{data?.plans?.header?.length
				? data.plans.header.map(({ name, id, popular, valute, price }, key) => {
					const body = data.plans.body.filter(({ availabilityInPlan }) => availabilityInPlan[key]);
					return <Box className={classes.plan} key={key}>
						<Paper className={classes.planTitle}>
							<PlanTitleComponent
								length={1}
								popular={popular}
								name={name}
								price={price}
								valute={valute}
							/>
						</Paper>
						{body.map(({ name }, keyIndex) =>
							<Box className={classes.item} style={{ border: keyIndex === 0 ? 'none !important' : false }} key={keyIndex}>
								<Typography className={classes.text}>{name}</Typography>
								<CheckIcon color="secondary" className={classes.check} />
							</Box>
						)}
						<Box className={classNames([classes.item, classes.itemBtn])}>
							<BuyBtnComponent
								id={id}
							/>
							<TrialTitleComponent />
						</Box>
					</Box>
				}
				)
				: null
			}

		</section>
	);
};

MobilePlansComponent.propTypes = {
	name: PropTypes.string,
};
export default React.memo(MobilePlansComponent);