import { MAIN_API } from "../constants/api";
import { GET } from "../functions/superagentSending";

const initialState = {
    loading: false,
    data: {
        currentBusiness: '',
        businesses: [
            {
                "id": 1,
                "name": "Korona Coffee",
                "slug": "korona-coffee"
            },
            {
                "id": 2,
                "name": "U Pawla",
                "slug": "korona-coffee"
            }
        ]
    },
    error: '',
};


const UPDATE_MAIN_SUCCESS = 'UPDATE_MAIN_SUCCESS'
const FETCH_MAIN_REQUEST = 'FETCH_MAIN_REQUEST'
const FETCH_MAIN_SUCCESS = 'FETCH_MAIN_SUCCESS'
const FETCH_MAIN_FAILURE = 'FETCH_MAIN_FAILURE'

const updateMainSuccess = (id, value) => ({
    type: UPDATE_MAIN_SUCCESS,
    payload: { id, value }
})

const fetchMainSuccess = (value) => ({
    type: FETCH_MAIN_SUCCESS,
    payload: value
})
const fetchMainRequest = () => ({
    type: FETCH_MAIN_REQUEST
})

const fetchMainFailure = error => ({
    type: FETCH_MAIN_FAILURE,
    payload: error
})


const MainReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_MAIN_SUCCESS:
            return {
                data: {
                    ...state.data,
                    [payload.id]: payload.value,
                },
                loading: false,
                error: ''
            }
        case FETCH_MAIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case FETCH_MAIN_SUCCESS:
            return {
                data: {
                    ...state.data,
                    ...payload,
                },
                loading: false,
                error: ''
            }
        case FETCH_MAIN_FAILURE:
            return {
                data: {},
                loading: false,
                error: payload
            }
        default:
            return state
    }
}

export const UPDATE_MAIN_REDUCER = (id, value) => {
    return (dispatch) => {
        dispatch(updateMainSuccess(id, value));
    }
}

export const FETCH_MAIN_REDUCER = () => {
    return (dispatch) => {
        dispatch(fetchMainRequest());
        GET(MAIN_API,
            (success) => {
                dispatch(fetchMainSuccess(success));
            },
            (error) => {
                dispatch(fetchMainFailure());
            })

    }
}


export default MainReducer;