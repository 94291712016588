import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import HrComponent from '../../../components/HrComponent';
import LinkComponent from '../../../components/LinkComponent';
import { FAQS_ROUTE } from '../../../constants/routes';

const useStyles = makeStyles((theme) => ({
	gridCont: {
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			marginBottom: 0,
		},
	},
	question: {
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(2),
		},
	},
	title: {
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(2),
			marginTop: theme.spacing(2),
		},
	}
}))

const TopicComponent = ({ title = '', questions = [] }) => {
	const classes = useStyles();

	return (
		<Grid container className={classes.gridCont}>
			<Grid item md={6} xs={12} >
				<Typography
					variant='body1'
					color='secondary'
					className={classes.title}
					component='h3'
				>
					{title}
				</Typography>
			</Grid>
			<Grid item md={6} xs={12}>
				<Grid container justifyContent='space-between'>
					{questions?.length
						? questions.map(({ slug, title }, key) =>
							<Grid
								key={key}
								className={classes.question}
								item
								sm={6}
								xs={12}
							>
								<LinkComponent to={`${FAQS_ROUTE}/${slug}`}>
									<Typography>
										{title}
									</Typography>
								</LinkComponent>
							</Grid>
						)
						: null}

				</Grid>
				<HrComponent />
			</Grid>
		</Grid>
	)
}


TopicComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(TopicComponent);