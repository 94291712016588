import React from 'react'
import { makeStyles } from '@material-ui/core';
import ButtonComponent from './ButtonComponent';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../functions';

const useStyles = makeStyles((theme) => ({
    btnsBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {

            flexDirection: 'column',
        },
    },
    btn: {
        flexBasis: 'calc(50% - 10px)',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    gradient: {
        background: theme.palette.background.primaryGradient,
    }
}))

const BtnsComponent = ({ disabled, type, hiddenSecondBtn, secondTitle, title, gradient, handleSave = () => { }, onClose = () => { } }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div className={classes.btnsBlock}>
            <ButtonComponent
                color='secondary'
                variant='contained'
                fullWidth
                disabled={disabled}
                type={type}
                className={classNames([classes.btn, gradient ? classes.gradient : ''])}
                onClick={handleSave}
            >
                {title || t('btns.save')}
            </ButtonComponent>
            {!hiddenSecondBtn
                ? <ButtonComponent color='primary' variant='contained' fullWidth className={classes.btn} onClick={onClose}>
                    {secondTitle || t('btns.close')}
                </ButtonComponent>
                : null}
        </div>
    )
}

export default React.memo(BtnsComponent);