import React from 'react'
import OnboardingSlideComponent from './OnboardingSlideComponent';
import { ONBOARDING_COMPANY_STEP } from '../../../constants';
import CompanyDataFormComponent from '../../../components/form/CompanyDataFormComponent';
import { useTranslation } from 'react-i18next';
import { UPDATE_ONBOARDING_REDUCER } from '../../../reducers/OnboardingReducer';
import { useSelector } from 'react-redux';


const CompanyStepComponent = (props) => {

	const { t } = useTranslation();
	const { data } = useSelector(state => state.OnboardingReducer);
	return (
		<OnboardingSlideComponent
			step={ONBOARDING_COMPANY_STEP}
		>
			<CompanyDataFormComponent
				btnTitle={t('btns.next')}
				hiddenSecondBtn
				data={data?.company}
				defaultValues={{
					currentStep: ONBOARDING_COMPANY_STEP
				}}
				fetchFunction={UPDATE_ONBOARDING_REDUCER}
			/>
		</OnboardingSlideComponent>
	)
}


export default React.memo(CompanyStepComponent);