import * as React from "react"

const TakeInfoSvg = (props) => (
	<svg
		viewBox="0 0 106 103"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<path
			d="M91.177 239.12a2.743 2.743 0 0 1-1.445-2.761c.063-3.62.018-7.242.023-10.863-.176-10.339 7.332-19.338 17.536-21.016a32.078 32.078 0 0 1 3.941-.294c.628-.035 1.259-.006 1.9-.006-.025-.162-.012-.245-.047-.281a15.264 15.264 0 0 1-4.245-11.763c.13-3.378-.124-6.79.255-10.134.793-7 7.2-12.36 14.386-12.36 7.186 0 13.7 5.364 14.364 12.359.323 4.552.34 9.12.05 13.674a12.462 12.462 0 0 1-3.469 7.623l-.692.792c1.788.1 3.47.11 5.135.286 9.292.981 17.289 8.842 18.1 18.19.418 4.839.185 9.735.23 14.6.009.969-.69 1.479-1.408 1.949l-64.614.005Zm29.148-4.1a1.41 1.41 0 0 0-.043-.323c-3.547-8.62-7.106-17.235-10.675-25.846a1.102 1.102 0 0 0-.91-.443c-7.39.713-13.918 6.946-14.636 14.353-.386 3.989-.167 8.037-.216 12.059 0 .055.064.11.119.2h26.361Zm32.8.013c0-3.691.132-7.307-.027-10.911-.491-8.011-6.616-14.625-14.566-15.729-.83-.1-1.1.175-1.385.859a7691.507 7691.507 0 0 1-10.187 24.731c-.134.325-.234.665-.369 1.05h26.534Zm-18.9-46.665a22.595 22.595 0 0 1-10.325-2.578c-.272-.145-.76.012-1.11.136-1.747.615-3.448 1.386-5.229 1.866-1.53.323-3.078.553-4.636.689 0 1.806-.037 3.628.007 5.448.161 5.272 4.239 9.659 9.485 10.205 5.142.577 9.964-2.794 11.189-7.821.394-2.63.6-5.284.616-7.943m-20.437 19.9 9.7 23.49 9.68-23.457h-7.654c0 1.637.028 3.22-.009 4.8a2.025 2.025 0 0 1-1.972 2.041h-.031a2.05 2.05 0 0 1-2.041-2.041v-.007c-.022-.4-.006-.812-.007-1.218v-3.61l-7.666.002Zm20.283-23.993c-.229-4.2-1.992-7.362-5.589-9.28a10.05 10.05 0 0 0-11.108.7 10.273 10.273 0 0 0-4.45 8.582 16.148 16.148 0 0 0 8.7-2.388 3.108 3.108 0 0 1 3.732-.006 16.248 16.248 0 0 0 8.719 2.4"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-70.714 -136.383)"
		/>
		<path
			d="M371.527 209.41h10.268a2.075 2.075 0 0 1 2.338 2.047 2.075 2.075 0 0 1-2.365 2.043h-10.407c.422.429.744.753 1.062 1.079.506.391.803.995.803 1.634a2.073 2.073 0 0 1-2.064 2.064 2.064 2.064 0 0 1-1.619-.784 927.219 927.219 0 0 1-4.435-4.435 2.071 2.071 0 0 1 .02-3.228 927.01 927.01 0 0 1 4.435-4.434c.39-.489.983-.774 1.609-.774 1.129 0 2.059.929 2.059 2.059 0 .629-.289 1.225-.782 1.615-.318.323-.667.616-1 .923l.08.187"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-278.606 -158.693)"
		/>
		<path
			d="M7.183 4.771h10.268a2.075 2.075 0 0 1 2.338 2.047 2.075 2.075 0 0 1-2.365 2.043H7.017c.422.429.744.753 1.062 1.079.505.391.802.994.802 1.633a2.074 2.074 0 0 1-2.064 2.064c-.63 0-1.226-.288-1.617-.781A927.438 927.438 0 0 1 .764 8.421a2.071 2.071 0 0 1 .02-3.228A927.219 927.219 0 0 1 5.219.758c.39-.489.983-.774 1.609-.774 1.129 0 2.059.929 2.059 2.059 0 .629-.289 1.225-.782 1.615-.318.323-.667.616-1 .923l.08.187"
			style={{
				fillRule: "nonzero",
			}}
			transform="rotate(-180 9.894 29.79)"
		/>
		<path
			d="M7.183 4.771h10.268a2.075 2.075 0 0 1 2.338 2.047 2.075 2.075 0 0 1-2.365 2.043H7.017c.422.429.744.753 1.062 1.079.505.391.802.994.802 1.633a2.074 2.074 0 0 1-2.064 2.064c-.63 0-1.226-.288-1.617-.781A927.438 927.438 0 0 1 .764 8.421a2.071 2.071 0 0 1 .02-3.228A927.219 927.219 0 0 1 5.219.758c.39-.489.983-.774 1.609-.774 1.129 0 2.059.929 2.059 2.059 0 .629-.289 1.225-.782 1.615-.318.323-.667.616-1 .923l.08.187"
			style={{
				fillRule: "nonzero",
			}}
			transform="rotate(-45 65.355 -73.704)"
		/>
		<path
			d="M7.183 4.771h10.268a2.075 2.075 0 0 1 2.338 2.047 2.075 2.075 0 0 1-2.365 2.043H7.017c.422.429.744.753 1.062 1.079.505.391.802.994.802 1.633a2.074 2.074 0 0 1-2.064 2.064c-.63 0-1.226-.288-1.617-.781A927.438 927.438 0 0 1 .764 8.421a2.071 2.071 0 0 1 .02-3.228A927.219 927.219 0 0 1 5.219.758c.39-.489.983-.774 1.609-.774 1.129 0 2.059.929 2.059 2.059 0 .629-.289 1.225-.782 1.615-.318.323-.667.616-1 .923l.08.187"
			style={{
				fillRule: "nonzero",
			}}
			transform="rotate(-90 32.867 -13.078)"
		/>
		<path
			d="M7.183 4.771h10.268a2.075 2.075 0 0 1 2.338 2.047 2.075 2.075 0 0 1-2.365 2.043H7.017c.422.429.744.753 1.062 1.079.505.391.802.994.802 1.633a2.074 2.074 0 0 1-2.064 2.064c-.63 0-1.226-.288-1.617-.781A927.438 927.438 0 0 1 .764 8.421a2.071 2.071 0 0 1 .02-3.228A927.219 927.219 0 0 1 5.219.758c.39-.489.983-.774 1.609-.774 1.129 0 2.059.929 2.059 2.059 0 .629-.289 1.225-.782 1.615-.318.323-.667.616-1 .923l.08.187"
			style={{
				fillRule: "nonzero",
			}}
			transform="rotate(-135 19.41 12.033)"
		/>
	</svg>
)

export default TakeInfoSvg
