import React, { useEffect } from 'react'
import { Box, Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import MainContainerComponent from '../../components/MainContainerComponent';
import HrTitleComponent from '../../components/HrTitleComponent';
import { useTranslation } from 'react-i18next';
import TopicComponent from './components/TopicComponent';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_FAQS_REDUCER } from '../../reducers/FaqsPageReducer';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	skeleton: {
		width: '100%',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		height: 400,
	}
}))

const FaqsPage = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { data, loading } = useSelector((state) => state.FaqsPageReducer);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(FETCH_FAQS_REDUCER());
	}, [dispatch])
	return (
		<MainContainerComponent hiddenCircle>
			<Container>
				<HrTitleComponent title={t('faqs_page.title')} component='h1' />

				{loading
					?<Box>
						<Skeleton
							variant='rect'
							className={classes.skeleton}
						/>
						<Skeleton
							variant='rect'
							className={classes.skeleton}
						/>
						<Skeleton
							variant='rect'
							className={classes.skeleton}
						/>
					</Box>
					: data?.list?.length
						? data?.list.map(({ title, questions }, key) =>
							<TopicComponent
								title={title}
								questions={questions}
								key={key}
							/>
						)
						: null}


			</Container>
		</MainContainerComponent>
	)
}


FaqsPage.propTypes = {
	name: PropTypes.string,
}
export default React.memo(FaqsPage);