import React from "react"
import {  Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
	icon: {
		marginBottom: theme.spacing(2),
		width: 80,
		height: 80,
		[theme.breakpoints.down('xs')]: {
			width: 60,
			height: 60,
		}
	},
	grid: {
		position: 'relative',
		zIndex: 1,
		padding: theme.spacing(5),
		[theme.breakpoints.down('xs')]: {
			paddingLeft: theme.spacing(0),
			paddingRight: theme.spacing(0),
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		}
	}
}));

const HowItWorkItemComponent = ({ title = '', offset = 0, Icon = () => {}}) => {
	const classes = useStyles();

	return (
		<Grid item md={4} className={classes.grid} data-aos="zoom-in" data-aos-offset={offset}>
			<Icon className={classes.icon} />
			<Typography>{title}</Typography>
		</Grid>
	);
};

HowItWorkItemComponent.propTypes = {
	 name: PropTypes.string,
};
export default React.memo(HowItWorkItemComponent);