import React, { useMemo } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { classNames } from '../../functions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        '& a': {
            textDecoration: 'none',
            color: theme.palette.primary.main,
            transition: 'all 0.2s ease-in',
            '&:hover': {
                color: theme.palette.primary.main,
            },
    }
}
}))

const CheckboxComponent = ({
    label,
    route,
    formikProps,
    id,
    color = 'default',
    onBlur = () => { },
    onChange = () => { },
    customHelperText,
    classRoute = '',
    classRoot = '',
}) => {
    const value = useMemo(() => formikProps.values[id], [formikProps, id]);
    const error = useMemo(() => formikProps.errors[id], [formikProps, id]);
    const touched = useMemo(() => formikProps.touched[id], [formikProps, id]);
    const {t} = useTranslation();
    const classes = useStyles();
    const isError = () => {
        return error && Boolean(touched) ? true : false
    }
    const handleBlur = (event) => {
        onBlur(event);
        formikProps.handleBlur(event)
    }
    const handleChange = (event) => {
        onChange(event);
        formikProps.handleChange(event)
    }
    return (
        <FormControl error={customHelperText ? Boolean(customHelperText) : isError()}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={Boolean(value)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={id}
                        id={id}
                        className={classes.checkbox}
                        color={color}

                    />
                }
                className={classes.formControl}
                label={<Typography className={classNames([classes.root, classRoot, classRoute])} dangerouslySetInnerHTML={{ __html: label || t(`form.${id}`) }} />}
            />
            <FormHelperText>{customHelperText ? customHelperText : isError() ? error : ''}</FormHelperText>
        </FormControl>
    )
}


export default React.memo(CheckboxComponent);