import * as React from "react"

const HowItWorkSvg = (props) => (
	<svg
		viewBox="0 0 142 148"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<path
			d="m47.277 339.791-10.7-6.327c-.412-.243-.84-.461-1.238-.725a2.852 2.852 0 0 1-1.1-3.858 2.81 2.81 0 0 1 3.966-1.084c2.6 1.429 5.145 2.949 7.716 4.427.405.233.82.448 1.339.73l8.173-14.141-40.438-23.355-8.151 14.1c.36.244.682.492 1.031.694 2.525 1.463 5.064 2.9 7.578 4.38 1.7 1 2.217 2.645 1.359 4.124-.845 1.456-2.537 1.819-4.246.843a1169.622 1169.622 0 0 1-10.808-6.246c-1.809-1.053-2.241-2.565-1.206-4.371a3183.057 3183.057 0 0 1 10.693-18.515c1.109-1.907 2.545-2.271 4.469-1.223.5.272.991.562 1.567.89 2.8-2.8 5.557-5.589 8.335-8.358A36.926 36.926 0 0 1 51.723 270.6a26.77 26.77 0 0 1 17.913 6.232 3.988 3.988 0 0 0 2.289.826c5.122.072 10.256.235 15.368-.007.272-.02.544-.031.816-.031 5.98 0 10.9 4.921 10.9 10.9 0 1.44-.285 2.865-.839 4.194a3.668 3.668 0 0 0-.062.732c2.128-.612 4.168-1.072 6.115-1.785a26.842 26.842 0 0 0 9.83-6.56c3-3.029 5.957-6.1 9.058-9.021a10.932 10.932 0 0 1 7.58-3.056c5.085 0 9.53 3.548 10.658 8.506.8 3.354.338 6.69-2.036 9.178-6.008 6.294-11.741 12.92-18.825 18.075a43.315 43.315 0 0 1-25.809 8.276c-10.2.063-20.4.014-30.6.015H62.6c-.541 1.232-.917 2.347-1.489 3.351a1624.046 1624.046 0 0 1-9.685 16.777 20.62 20.62 0 0 1-2.136 2.591l-2.013-.002Zm-24.828-46.767c.165.142.337.276.516.4a20700.748 20700.748 0 0 0 30.318 17.482 3.703 3.703 0 0 0 1.765.4c13.211.022 26.422.085 39.632-.006a38.127 38.127 0 0 0 26.779-10.711c4.343-4.112 8.521-8.4 12.707-12.675a6.514 6.514 0 0 0 1.557-2.672 5.083 5.083 0 0 0-2.445-5.767 5.275 5.275 0 0 0-6.447.972c-3.27 3.228-6.452 6.548-9.78 9.714a32.172 32.172 0 0 1-22.584 9.106c-5.408.053-10.817.011-16.226.011-3.877 0-7.754.023-11.631-.011a2.827 2.827 0 0 1-3.019-3.194c.159-1.583 1.345-2.533 3.251-2.536 6.893-.011 13.785-.013 20.677-.006 3.462 0 5.736-1.991 5.753-5.016.017-3.063-2.215-5.068-5.671-5.072-5.7-.006-11.392-.031-17.088.018a4.947 4.947 0 0 1-3.743-1.424 19.055 19.055 0 0 0-8.655-4.878c-8.03-2.015-15.455-.245-22.414 3.888-5.179 3.075-8.813 7.881-13.252 11.973"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(0 -192.702)"
		/>
		<path
			d="M238.832 79.065c1.564 4.8 3.172 9.543 4.628 14.333.426 1.257.538 2.6.326 3.911a5.864 5.864 0 0 1-5.704 4.556 5.845 5.845 0 0 1-3.601-1.24 427.25 427.25 0 0 1-11.461-8.39 1.69 1.69 0 0 0-2.458.03 435.442 435.442 0 0 1-11.469 8.38 5.89 5.89 0 0 1-3.573 1.207c-3.107 0-5.707-2.456-5.883-5.558a8.821 8.821 0 0 1 .526-3.078 543.454 543.454 0 0 1 4.2-12.8 1.642 1.642 0 0 0-.706-2.242 604.648 604.648 0 0 1-11.52-8.311 5.922 5.922 0 0 1-.908-9.127 6.343 6.343 0 0 1 4.843-1.756c4.546.02 9.093-.037 13.638.035a1.806 1.806 0 0 0 2.1-1.52 489.074 489.074 0 0 1 4.3-13.368 5.888 5.888 0 0 1 5.654-4.281 5.86 5.86 0 0 1 1.98.344 5.968 5.968 0 0 1 3.845 4.142c1.434 4.456 2.908 8.9 4.29 13.37a1.567 1.567 0 0 0 1.8 1.309c4.976-.038 9.954-.058 14.928.055a5.514 5.514 0 0 1 5.181 4.054 5.81 5.81 0 0 1-1.822 6.354c-.362.311-.752.591-1.14.871a14657.73 14657.73 0 0 1-10.7 7.735c-.422.305-.831.628-1.3.982m9.426-13.959-.129-.371h-16.639c-2.973 0-3.492-.382-4.413-3.24-1.555-4.821-3.108-9.643-4.66-14.464-.149-.462-.343-.91-.633-1.674-.268.767-.416 1.167-.547 1.572-1.621 5-3.24 10-4.858 15-.735 2.267-1.457 2.8-3.869 2.8-5.218.008-10.436 0-15.655.007-.479 0-.958.056-1.789.108.713.548 1.081.846 1.464 1.123l12.449 9c2.162 1.565 2.424 2.38 1.605 4.873l-4.785 14.575c-.156.478-.26.973-.491 1.852.74-.515 1.161-.8 1.57-1.094 4.178-3.041 8.355-6.084 12.53-9.129 2-1.459 2.791-1.474 4.756-.044 4.255 3.098 8.506 6.201 12.755 9.308.365.267.749.508 1.426.965-.247-.845-.377-1.339-.536-1.825-1.633-4.997-3.269-9.992-4.906-14.987-.669-2.051-.382-2.946 1.337-4.2 2.941-2.139 5.886-4.273 8.837-6.4 1.728-1.251 3.455-2.505 5.18-3.76"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-134.94 -28.38)"
		/>
		<path
			d="M374.661 4.791c.41-3.745 1.143-4.864 3.083-4.788 1.9.075 2.647 1.294 2.837 4.832 2.217.019 4.758-.112 4.769 3.138.007 1.847-1.321 2.539-4.78 2.7-.033 2.21.1 4.744-3.136 4.788-1.774.025-2.313-1.011-2.741-4.371a28.805 28.805 0 0 1-4.093-1.574c-.45-.25-.66-1.26-.662-1.924-.007-1.868 1.11-2.5 4.721-2.8"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-263.45)"
		/>
		<path
			d="M126.557 179.781c-.4 3.767-1.012 4.767-2.857 4.826-1.96.063-2.63-.936-3.145-4.8-2.154-.064-4.788.084-4.649-3.089.124-2.821 2.548-2.733 4.729-2.768.047-2.239-.016-4.777 3-4.747 2.946.029 2.841 2.524 2.835 4.26a33.761 33.761 0 0 1 4.175 1.691c.461.476.689 1.133.621 1.792-.2 3.006-2.548 2.808-4.712 2.836"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-82.538 -120.495)"
		/>
		<path
			d="m84.491 448.227.001.076a2.791 2.791 0 0 1-2.778 2.778l-.061.001a2.87 2.87 0 0 1-2.856-2.856v-.035a2.935 2.935 0 0 1 2.881-2.818 2.858 2.858 0 0 1 2.813 2.854"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-56.114 -317.168)"
		/>
	</svg>
)

export default HowItWorkSvg
