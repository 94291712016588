import React from 'react'
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = (size) => makeStyles((theme) => ({
    icon: {
        width: size,
        height: size,
        transition: '0.2s all ease-in',
        [theme.breakpoints.down('md')]: {
            width: size - 5,
            height: size - 5,
        },
    }
}))

const RankingIconComponent = ({ size = 20, index, ranking, className = ''}) => {
    const classes = useStyles(size)();

    return (
        <FavoriteIcon className={`${classes.icon} ${className}`} color={index <= ranking ? 'secondary' : 'disabled'} /> 
    )
}


RankingIconComponent.propTypes = {
    index: PropTypes.number,
    ranking: PropTypes.number,
}
export default React.memo(RankingIconComponent);