export const MAIN_ROUTE = '/'
export const PLAN_ROUTE = '/plan'
export const BLOG_ROUTE = '/blog'
export const STATIC_ROUTE = '/static'
export const PRIVACY_ROUTE = '/static/privacy'
export const RULES_ROUTE = '/static/rules'
export const FAQS_ROUTE = '/faqs'
export const LOGIN_ROUTE = '/login'
export const REGISTER_ROUTE = '/register'
export const FORGOT_ROUTE = '/forgot'
export const RESEND_ROUTE = '/resend'
export const RESET_ROUTE = '/reset'
export const ONBOARDING_ROUTE = '/onboarding'
export const SUC_EMPLOYEE_ONB_ROUTE = '/success_employee_onboarding'
export const SUC_VERIFICATION_ROUTE = '/success_verification'
export const ABOUT_ROUTE = '/about'