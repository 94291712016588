import React from 'react'
import { useSelector } from 'react-redux';
import PostComponent from '../../components/PostComponent';
import { MAIN_ROUTE } from '../../constants/routes';
import { FETCH_STATIC_REDUCER } from '../../reducers/StaticPageReducer';


const StaticPage = (props) => {
	const {data, loading} = useSelector(state => state.StaticPageReducer)
	return (
		<PostComponent
			data={data}
			loading={loading}
			breadcrumbs={[
			]}
			backRoute={MAIN_ROUTE}
			fetchFun={FETCH_STATIC_REDUCER}
		/>
	)
}

export default React.memo(StaticPage);