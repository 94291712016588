import React, { useCallback } from 'react'
import OnboardingSlideComponent from './OnboardingSlideComponent';
import { ONBOARDING_BUSINESS_STEP, ONBOARDING_COMPANY_STEP } from '../../../constants';
import BusinessFormComponent from '../../../components/form/BusinessFormComponent';
import { BACK_ONBOARDING_REDUCER, UPDATE_ONBOARDING_REDUCER } from '../../../reducers/OnboardingReducer';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';


const BusinessStepComponent = (props) => {

	const { data } = useSelector(state => state.OnboardingReducer);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const handlePrev = useCallback(() => {
		dispatch(BACK_ONBOARDING_REDUCER(ONBOARDING_COMPANY_STEP))
	}, [dispatch])
	return (
		<OnboardingSlideComponent
			step={ONBOARDING_BUSINESS_STEP}
		>
			<BusinessFormComponent
				data={data?.business}
				btnTitle={t('btns.next')}
				hiddenSecondBtn
				defaultValues={{
					currentStep: ONBOARDING_BUSINESS_STEP
				}}
				handleToggleDialog={handlePrev}
				secondBtnTitle={t('btns.back')}
				fetchFunction={UPDATE_ONBOARDING_REDUCER}
			/>
		</OnboardingSlideComponent>
	)
}

export default React.memo(BusinessStepComponent);