
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'

const ButtonComponent = withStyles((theme) => ({
    root: {
        borderRadius: 15,
        padding: '10px 40px'
        // padding: '12px 50px',
        // boxShadow: 'none',
    },
    containedSecondary: {
        color: theme.palette.text.white,
     
    }
}))(Button);


export default ButtonComponent;