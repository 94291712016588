import React from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import DemoBtnComponent from './form/DemoBtnComponent';
import { classNames } from '../functions';

const useStyles = (reverse) => makeStyles((theme) => ({
	box: {
		marginTop: theme.spacing(12),
		marginBottom: theme.spacing(12),
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(4),
		}
	},
	li: {
		display: 'flex',
		marginBottom: theme.spacing(1.5),
	},
	ul: {
		marginTop: theme.spacing(4),
		paddingLeft: 0,
	},
	liTitle: {
		marginLeft: theme.spacing(2),
	},
	img: {
		maxWidth: '100%',
		height: 'auto',
		zIndex: 1,
		position: 'relative',
		maxHeight: '80vh',
		[theme.breakpoints.down('sm')]: {
			maxHeight: 600,
		},
		[theme.breakpoints.down('xs')]: {
			maxHeight: 'initial',
			width: '80%',
		}
	},
	imgBox: {
		width: '50%',
		position: 'relative',
		minWidth: '50%',
		display: 'flex',
		justifyContent: reverse ? 'flex-end' : 'flex-start',
		paddingRight: reverse ? 0 : theme.spacing(2),
		paddingLeft: reverse ? theme.spacing(2) : 0,
		[theme.breakpoints.down('sm')]: {
			padding: 0,
			justifyContent: 'center',
		}
	},
	demoBox: {
		marginTop: theme.spacing(2),
	},
	title: {
		[theme.breakpoints.down('xs')]: {
			fontWeight: 'bold',
		}
	}
}))

const AboutBlockComponent = ({ className = "", src, before_list, after_list, title, list, reverse, children }) => {
	const classes = useStyles(reverse)();

	return (
		<Grid
		
			// data-aos-anchor-placement="top-bottom"
			
			container
			className={classes.box}
			direction={reverse ? 'row-reverse' : 'row'}
			alignItems='center'
			justifyContent='space-between'
		>
			<Grid item md={6} xs={12} className={classes.imgBox}>
				{children}
				<img
					data-aos={reverse ? "fade-right" : "fade-left"}
					data-aos-offset="250"
					className={classNames([classes.img, className])}
					src={src}
					alt={title}
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<Typography variant='h4' component={'h3'} className={classes.title} data-aos="zoom-in">
					{title}
				</Typography>
				{before_list
					?
					<Typography 
					  data-aos="zoom-in"
						dangerouslySetInnerHTML={{ __html: before_list }}
					/>
					: null
				}
				<ul className={classes.ul}>
					{list?.length
						? list.map((item, key) =>
							<li key={key} className={classes.li}  data-aos="zoom-in">
								<CheckIcon />
								<Typography className={classes.liTitle}>{item}</Typography>
							</li>
						)
						: null}
				</ul>
				{after_list
					? <Typography
						data-aos="zoom-in"
						dangerouslySetInnerHTML={{ __html: after_list }}
					/>
					: null
				}
				<Box className={classes.demoBox} data-aos="zoom-in">
					<DemoBtnComponent />
				</Box>
			</Grid>
		</Grid>
	)
}


AboutBlockComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(AboutBlockComponent);