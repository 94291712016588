import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import LinkComponent from './LinkComponent';
import smileImg from '../imgs/smile.webp';

const useStyles = makeStyles((theme) => ({
	box: {
		fontWeight: 600,
		position: 'relative',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		'&::after': {
			content: "' '",
			backgroundImage: `url(${smileImg})`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
			backgroundPosition: 'center',
			opacity: 0,
			position: 'absolute',
			bottom: -15,
			width: 30,
			height: 10,
			left: 0,
			right: 0,
			transition: 'all 0.2s ease-in',
			margin: 'auto',
		},
		'&:hover': {
			'&::after': {
				opacity: 1,
			}
		}
	},
	link: {
		'&:hover': {
			color: theme.palette.text.primary,
		}
	}
}))

const MenuItemComponent = ({ title, to, external_link, target }) => {
	const classes = useStyles();

	return (
		<Box className={classes.box}>
			<LinkComponent to={to} external_link={external_link} target={target} className={classes.link}>
				{title}
			</LinkComponent>
		</Box>
	)
}


MenuItemComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(MenuItemComponent);