import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
	tooltip: {
		fontSize: 10,
	}
}));

const IconButtonComponent = ({ ariaControls, Icon, title = '', onClick = () => { }, size, color, className, iconClassName }) => {
	const classes = useStyles();
	return (
		<Tooltip title={title} classes={{ tooltip: classes.tooltip}}>
			<IconButton
				size={size}
				aria-controls={ariaControls}
				onClick={onClick}
				className={className}
				aria-label={title}
			>
				<Icon
					className={iconClassName}
					color={color}
				/>
			</IconButton>
		</Tooltip>
	)
}

export default React.memo(IconButtonComponent);