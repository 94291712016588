import React from 'react'
import { Container, makeStyles, MobileStepper, Step, StepLabel, Stepper, useMediaQuery, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { STEPPER_ID } from '../constants';

const useStyles = makeStyles((theme) => ({
	mobileStepper: {
		background: 'inherit',
		paddingTop: theme.spacing(0),
		paddingBottom: theme.spacing(3),
		paddingLeft: 0,
		justifyContent: 'center',
		position: 'relative',
		paddingRight: 0,
	},
	stepper: {
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(10),
		background: 'inherit',
	},
	step: {
		...theme.typography.body1,
	},
}))

const StepperComponent = ({ currentStep, steps }) => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	return (
		<Container maxWidth='md'>
			{matches
				? <MobileStepper
					variant='dots'
					steps={steps?.length || 0}
					activeStep={currentStep}
					id={STEPPER_ID}
					className={classes.mobileStepper}
				/>
				: <Stepper
					activeStep={currentStep}
					alternativeLabel
					id={STEPPER_ID}
					className={classes.stepper}
				>
					{steps.map(({ title }, key) => (
						<Step key={key}>
							<StepLabel
								classes={{ label: classes.step }}

							>
								{title}
							</StepLabel>
						</Step>
					))}
				</Stepper>
			}
		</Container>
	)
}


StepperComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(StepperComponent);