import React, { useEffect, useMemo, useRef } from 'react'
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PayStepComponent from './components/PayStepComponent';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_ONBOARDING_REDUCER } from '../../reducers/OnboardingReducer';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CompanyStepComponent from './components/CompanyStepComponent';
import BusinessStepComponent from './components/BusinessStepComponent';
import FinishStepComponent from './components/FinishStepComponent';
// import EmployeeStepComponent from './components/EmployeeStepComponent';
import { makeStyles } from '@material-ui/core';
import StepperComponent from '../../components/StepperComponent';
import LoaderComponent from '../../components/LoaderComponent';
import { useHistory } from 'react-router-dom';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const useStyles = makeStyles((theme) => ({
	cont: {
		background: theme.palette.background.primaryGradient,
		width: '100vw',
		// paddingTop: theme.spacing(5),
		// paddingBottom: theme.spacing(3),
		minHeight: `100vh`,
	},
	paperCont: {

	},
	paper: {

	}
}))

const OnboardingPage = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const { data, loading } = useSelector(state => state.OnboardingReducer);
	const sliderRef = useRef();
	const dispatch = useDispatch();
	const steps = useMemo(() => t('onboarding_page.steps', { returnObjects: true }), [t])
	const settings = {
		dots: false,
		infinite: false,
		autoplay: false,
		arrows: false,
		speed: 500,
		swipe: false,
		draggable: false,
		slidesToShow: 1,
		slidesToScroll: 1,
	}

	useEffect(() => {
		dispatch(FETCH_ONBOARDING_REDUCER(history))
	}, [dispatch, history])

	useEffect(() => {
		if (sliderRef?.current) {
			sliderRef.current.slickGoTo(data?.currentStep || 0)
			window.scrollTo(0, 0);
		}
	}, [data])
	return (
		<Box className={classes.cont}>
			<StepperComponent
				currentStep={data?.currentStep}
				steps={steps}
			/>
			<Slider
				{...settings}
				ref={sliderRef}
			>
				<Elements stripe={stripePromise}>
					<PayStepComponent />
				</Elements>
				<CompanyStepComponent />
				<BusinessStepComponent />
				{/* <EmployeeStepComponent /> */}
				<FinishStepComponent />
			</Slider>
			{loading ? <LoaderComponent fixed /> : null}
		</Box>
	)
}


OnboardingPage.propTypes = {
	name: PropTypes.string,
}
export default React.memo(OnboardingPage);