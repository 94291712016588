import React from 'react'
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { HR_TITLE } from '../functions/csses';
import HrComponent from './HrComponent';
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useMemo } from 'react';

const useStyles = makeStyles((theme) => ({
	hr: {	
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
		},
	},
	title: {
		...HR_TITLE(theme),

	},
	skeleton: {
		width: '50%',
		...HR_TITLE(theme),
	}
}))

const HrTitleComponent = ({ hiddenHelmet, component, title, reverse, loading }) => {
	const classes = useStyles();
	const slicedTitle = useMemo(() => {
		let updatedTitle = title  || "";
		updatedTitle.slice(39);
		return updatedTitle 
	 }, [title])
	useEffect(() => {
		if (!hiddenHelmet && window?.dataLayer) {
			window.dataLayer.push({
				event: 'Pageview',
				page: {
					title
				}
			});
		}
	}, [title, hiddenHelmet])

	return (
		<section>
			{!hiddenHelmet 
			? <Helmet>
					<title>{slicedTitle} - Booser - boost your services</title>
			</Helmet> 
			: null 
			}
			{!reverse ? <HrComponent className={classes.hr} /> : null}
			{loading
				? <Skeleton variant='rect' className={classes.skeleton} />
				: <Typography variant='h2' component={component} className={classes.title}>{title}</Typography>
			}
			{reverse ? <HrComponent className={classes.hr} /> : null}
		</section>
	)
}


HrTitleComponent.propTypes = {
	title: PropTypes.string,
}
export default React.memo(HrTitleComponent);