import React from 'react'
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = (fixed) => makeStyles((theme) => ({
	loaderBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		background: `${theme.palette.background.white}99`,
		position: fixed ? 'fixed' : 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 2000,

	}
}))

const LoaderComponent = ({ fixed}) => {
 const classes = useStyles(fixed)();

	return (
		<Box className={classes.loaderBox}>
			<CircularProgress />
		</Box>
	)
}

export default React.memo(LoaderComponent);