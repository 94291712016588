import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import OnboardingReducer from './OnboardingReducer'
import BlogPageReducer from './BlogPageReducer'
import RegisterPageReducer from './RegisterPageReducer'
import MainReducer from './MainReducer'
import MainPageReducer from './MainPageReducer'
import PostPageReducer from './PostPageReducer'
import HelpPostPageReducer from './HelpPostPageReducer'
import FaqsPageReducer from './FaqsPageReducer'
import StaticPageReducer from './StaticPageReducer'


export default createStore(
    combineReducers({
        MainPageReducer,
        RegisterPageReducer,
        OnboardingReducer,
        BlogPageReducer,
        MainReducer,
        PostPageReducer,
        HelpPostPageReducer,
        FaqsPageReducer,
        StaticPageReducer,
    }),
    compose(
        applyMiddleware(thunk),
        // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);
