import superagent from 'superagent';
import { API_URL, TOKEN_NAME, } from '../constants';
// PL_LNG
// const getLang = () => localStorage.getItem('lang') || PL_LNG;
export const POST = async (url, data = {}) => {
    // const lang = getLang();
    const token = localStorage.getItem(TOKEN_NAME);
    return superagent
        .post(`${API_URL}/api${url}`)
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer ' + token)
        .set('enctype', 'multipart/form-data')
        .send(data);
};
export const PUT = async (url, data = {}) => {
    // const lang = getLang();
    const token = localStorage.getItem(TOKEN_NAME);
    return superagent
        .put(`${API_URL}/api${url}`)
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer ' + token)
        .set('enctype', 'multipart/form-data')
        .send(data);
};

export const DELETE = async (url, data = {}) => {
    // const lang = getLang();
    const token = localStorage.getItem(TOKEN_NAME);
    return superagent
        .delete(`${API_URL}/api${url}`)
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer ' + token)
        .set('enctype', 'multipart/form-data')
};


// const worker = () => {

//     onmessage = (e) => {
//         if (e?.request?.cache === 'only-if-cached' && e?.request?.mode !== 'same-origin') return;
//         fetch(`${e.data[0]}/api${e.data[1]}`, {
//             headers: {
//                 'Accept': 'application/json',
//                 'Authorization': `Bearer ${e.data[2]}`
//             }
//         })
//             .then(function (a) {
//                 return a.json(); // call the json method on the response to get JSON
//             })
//             .then(function (json) {
//                 postMessage([json, true])
//             }).catch((err) => {
//                 postMessage([err, false])
//             })

//     }
// }

// export const GET = async (url, success = () => { }, error = () => { }) => {
//     // const lang = getLang();
//     const myWorker = new Worker(URL.createObjectURL(new Blob(["(" + worker.toString() + ")()"], { type: 'text/javascript' })));


//     let body = {};
//     const token = localStorage.getItem(TOKEN_NAME);
//     myWorker.postMessage([API_URL, url, token]);
//     myWorker.onmessage = function (e) {
//         body = e.data[0];
//         const isSucc = e.data[1];
//         if (isSucc)
//             success({ body })
//         else
//             error(body)
//     }
// };
export const GET = (url, success = () => { }, error = () => { }) => {
    const token = localStorage.getItem(TOKEN_NAME);
    return superagent
        .get(`${API_URL}/api${url}`)
        .set('Accept', 'application/json')
        .set('Authorization', 'Bearer ' + token)
        .set('enctype', 'multipart/form-data')
        .then(success)
        .catch(error)
}