import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './en.json'
// import de from './de.json'
// import uk from './uk.json'
import pl from './pl.json'
import { 
    // DE_LNG, 
    EN_LNG, 
    PL_LNG, 
    // UK_LNG, 
} from "../constants";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    [PL_LNG]: {
        translation: pl
    },
    // [DE_LNG]: {
    //     translation: de
    // },
    // [UK_LNG]: {
    //     translation: uk
    // },
    [EN_LNG]: {
        translation: en
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('lang') || PL_LNG,
        fallbackLng: [PL_LNG, EN_LNG],
        // fallbackLng: [PL_LNG, DE_LNG, UK_LNG, EN_LNG],

        // keySeparator: false, 

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;