import React, { useMemo } from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import HrTitleComponent from './HrTitleComponent';
import { useTranslation } from 'react-i18next';
import LogoSvg from '../svgs/LogoSvg';
import moment from 'moment';
import { BLOG_ROUTE, FAQS_ROUTE, PRIVACY_ROUTE, RULES_ROUTE } from '../constants/routes';
import LinkComponent from './LinkComponent';
import GradientCircleComponent from './GradientCircleComponent';
import { FACEBOOK_LINK, LINKEDIN_LINK, USER_HELP_LINK } from '../constants';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
	footer: {
		marginTop: theme.spacing(40),
		marginBottom: theme.spacing(8),
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(8),
			marginBottom: theme.spacing(4),
		},
	},
	box: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	logo: {
		marginBottom: theme.spacing(1),
		height: 30,
	},
	copyrights: {
		textAlign: 'end',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'start',
		},
	},
	menusBox: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(2),
		},
	},
	menuBox: {
		marginRight: theme.spacing(3),
	},
	menuTitle: {
		fontWeight: 400,
	},
	menuLink: {
		marginBottom: theme.spacing(1),
	},
	gradientCircle: {
		bottom: '-50vw',
		left: '-10vw',
		width: '90vw',
		transform: 'rotate(-40deg)',
		height: '120vw',

		background: theme.palette.background.footerGradient,
	},
	boxSocials: {
		display: "flex",
		alignItems: "center",
		marginBottom: theme.spacing(1),
	},
	icon: {
		width: 30,
		height: 30,
		marginRight: theme.spacing(0.5),
	}
}))

const FooterComponent = ({ hiddenCircle }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const menus = useMemo(() => [
		{
			name: t('footer.menus.menu'),
			list: [
				{
					title: t('blog_page.title'),
					to: BLOG_ROUTE
				},
				{
					title: t('faqs_page.title'),
					to: FAQS_ROUTE
				},
				{
					title: t('footer.menus.user_help'),
					to: USER_HELP_LINK,
					external_link: true,
				},
			]
		},
		{
			name: t('footer.menus.privacy'),
			list: [
				{
					title: t('privacy_page.title'),
					to: PRIVACY_ROUTE
				},
				{
					title: t('rules_page.title'),
					to: RULES_ROUTE
				},
			]
		},
	], [t])
	return (
		<Container component='footer' className={classes.footer}>
			<HrTitleComponent reverse hiddenHelmet title={t('footer.slogan')} />
			<Box className={classes.box}>
				<Box className={classes.menusBox}>
					{menus?.length
						? menus.map((menu, key) =>
							<Box key={key} className={classes.menuBox}>
								<Typography component='h6' variant='h3' className={classes.menuTitle}>
									{menu?.name}
								</Typography>
								{menu?.list?.length
									? menu.list.map((item, key) =>
										<LinkComponent to={item.to} key={key} external_link={item.external_link} >
											<Typography variant='body2' className={classes.menuLink}>
												{item?.title}
											</Typography>
										</LinkComponent>
									)
									: null
								}
							</Box>
						)
						: null
					}
				</Box>
				<Box>
					<LogoSvg className={classes.logo} />
					<Box className={classes.boxSocials}>
					<LinkComponent external_link to={FACEBOOK_LINK} >
						<FacebookIcon className={classes.icon} />
					</LinkComponent>
					<LinkComponent external_link to={LINKEDIN_LINK} >
							<LinkedInIcon className={classes.icon} />
					</LinkComponent>
					</Box>
					<Typography variant='body2' className={classes.copyrights}>
						{t('footer.copyrights', { year: moment().format('YYYY') })}
					</Typography>
				</Box>
			</Box>
			{!hiddenCircle ? <GradientCircleComponent className={classes.gradientCircle} /> : null}
		</Container>
	)
}


FooterComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(FooterComponent);