import React from 'react'
import { useCallback } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ButtonComponent from '../../components/form/ButtonComponent';
import PostComponent from '../../components/PostComponent';
import { RESEND_EMAIL_API } from '../../constants/api';
import { LOGIN_ROUTE } from '../../constants/routes';
import { POST } from '../../functions/superagentSending';

const ResendPage = (props) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [answer, setAnswer] = useState({
		title: t('resend_page.title'),
		description: t('resend_page.desc'),
	});
	const handleSend = useCallback(() => {
		setLoading(true)
		setSuccess(false)
		POST(RESEND_EMAIL_API)
			.then((success) => {
				setAnswer({
					title: t('resend_page.success_title'),
					description: t('resend_page.success_desc'),
				})
				setSuccess(true)
				setLoading(false)
			})
			.catch((error) => {
				setLoading(false)
			})
	}, [t])

	const handleLoginPush = useCallback(() => {
		history.push(LOGIN_ROUTE)
	}, [history])
	return (
		<PostComponent
			data={answer}
			hiddenFetch
			loading={loading}
			hiddenBack
		>
			{success
				? <ButtonComponent variant='contained' color='primary' onClick={handleLoginPush}>
					{t('login_page.title')}
				</ButtonComponent>
				: <ButtonComponent variant='contained' color='primary' onClick={handleSend}>
					{t('btns.resend')}
				</ButtonComponent>
			}

		</PostComponent>
	)
}

export default React.memo(ResendPage);