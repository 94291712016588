import React from 'react'
import { makeStyles } from '@material-ui/core';
import { classNames } from '../functions';

const useStyles = makeStyles((theme) => ({
	hr: {
		width: '100%',
		height: 2,
		border: 'none',
		background: theme.palette.primary.main,
	}
}))

const HrComponent = ({ className = ''}) => {
 const classes = useStyles();

	return (
		<hr className={classNames([classes.hr, className])} />
	)
}

export default React.memo(HrComponent);