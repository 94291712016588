import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: 5,
        minWidth: 280,
        [theme.breakpoints.down('xs')]: {
            minWidth: 'auto',
        }
    }
}))

const SelectComponent = ({
    items,
    customHelperText,
    variant = 'outlined',
    formikProps,
    fullWidth = true,
    id,
    placeholder,
    onBlur = () => { },
    onChange = () => { },
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const value = formikProps.values[id];
    const error = formikProps.errors[id];
    const touched = formikProps.touched[id];
    const isError = () => {
        return error && Boolean(touched) ? true : false
    }
    const handleBlur = (event) => {
        onBlur(event);
        formikProps.handleBlur(event)
    }
    const handleChange = (event) => {
        onChange(event);
        formikProps.handleChange(event)
    }
    return (

        <FormControl variant="outlined" className={classes.formControl} fullWidth={fullWidth}>
            <InputLabel id={`${id}-label`}>{placeholder || t(`form.${id}`)}</InputLabel>
            <Select
                name={id}
                labelId={`${id}-label`}
                id={id}
                error={customHelperText ? Boolean(customHelperText) : isError()}
                value={value}
                onBlur={handleBlur}
                onChange={handleChange}
                label={placeholder || t(`form.${id}`)}
            >
                {items?.length ?
                    items.map((item, key) => <MenuItem key={key} value={item.value}>{item.title}</MenuItem>)
                    : <MenuItem key={0} value={'0'}>{t('empty')}</MenuItem>}
            </Select>
            <FormHelperText className={isError() ? '' : classes.hiddenHelperText}>{customHelperText ? customHelperText : isError() ? error : ' '}</FormHelperText>
        </FormControl>

    )
}


export default SelectComponent;