import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import MainContainerComponent from '../../components/MainContainerComponent';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GradientCircleComponent from '../../components/GradientCircleComponent';
import AboutBlockComponent from '../../components/AboutBlockComponent';
import FirstAboutImg from '../../imgs/aboutBlock/firstAboutImg.webp';
import SecondAboutImg from '../../imgs/aboutBlock/secondAboutImg.webp';
import ThirdAboutImg from '../../imgs/aboutBlock/thirdAboutImg.webp';
import FourthAboutImg from '../../imgs/aboutBlock/fourthAboutImg.webp';
import BannerComponent from './components/BannerComponent';
import { useDispatch } from 'react-redux';
import { FETCH_MAIN_PAGE_REDUCER } from '../../reducers/MainPageReducer';
import SmileImg from '../../imgs/smile.webp';
import BlueSmileImg from '../../imgs/aboutBlock/blueSmile.webp';
import PinkSmileImg from '../../imgs/aboutBlock/pinkSmile.webp';
import SecImgBack from '../../imgs/aboutBlock/secondAboutImgBackground.webp';
import CircleSvg from '../../svgs/CircleSvg';
import FourthImgBack from '../../imgs/aboutBlock/fourthAboutImgBackground.webp';
import HowItWorkComponent from './components/HowItWorkComponent';
import PointSvg from '../../svgs/PointSvg';
import ScrollImgComponent from './components/ScrollImgComponent';

const useStyles = makeStyles((theme) => ({
	cont: {
		position: 'relative',
	},
	aboutCircle: {
		width: '90vw',
		height: '120vw',
		left: '-40vw',
		transform: 'rotate(-25deg)',
		background: theme.palette.background.aboutGradient,
		[theme.breakpoints.down('xs')]: {
			left: '-30vw',
			width: '100vw',
			height: '140vw',
		}
	},
	lastAboutCircle: {
		width: '90vw',
		height: '120vw',
		right: '-50vw',
		bottom: '0vw',
		transform: 'rotate(25deg)',
		background: theme.palette.background.lastAboutGradient,
		[theme.breakpoints.down('xs')]: {
			width: '150vw',
			height: '190vw',
		}
	},
	plans: {
		marginTop: theme.spacing(4),
	},
	createPlaceImg: {
		height: 110,
		width: 'auto',
		marginTop: theme.spacing(2),
		animation: `$moving 2s linear infinite`,
	},
	createPlaceBox: {
		marginTop: theme.spacing(25),
		marginBottom: theme.spacing(20),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(10),
			marginBottom: theme.spacing(10),
		}
	},
	firstBlockCircle: {
		width: 300,
		height: 300,
		position: 'absolute',
		left: -150,
		top: 0,
		bottom: 0,
		margin: 'auto',
	},
	firstAboutSmile: {
		position: 'absolute',
		width: 180,
		transform: 'rotate(-20deg)',
		left: '-20vw',
		top: theme.spacing(-10),
	},
	firstAboutPinkSmile: {
		position: 'absolute',
		right: '60vw',
		animation: `$firstAboutSmileMoving 16s linear infinite`,
	},
	secAbSmile: {
		width: 300,
		right: 0,
		top: 0,
		height: 'auto',
		position: 'absolute',
	},
	secAbSmileSmall: {
		width: 120,
		right: theme.spacing(-10),
		bottom: theme.spacing(5),
		height: 'auto',
		position: 'absolute',
		transform: 'rotate(40deg)',
		animation: "$secAbSmileSmallMoving 10s linear infinite"
	},
	thirdAbSmileBlue: {
		left: theme.spacing(4),
		width: 250,
		top: theme.spacing(-20),
		position: 'absolute',
		animation: "$thirdAbSmileMoving 20s linear infinite",
	},
	secImgBack: {
		position: 'absolute',
		width: '80%',
		height: 'auto',
		bottom: -120,
		right: '-40%',
		zIndex: -1,
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		}
	},
	fourthImgBack: {
		position: 'absolute',
		width: '100%',
		height: 'auto',
		bottom: 0,
		margin: 'auto',
		right: '-20%',
		'@media (max-height: 800px)': {
			width: '80%',
		},
		[theme.breakpoints.down('md')]: {
			width: '70%',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			right: 0,
			bottom: 20,
		}
	},
	secAboutBlockImg: {
		[theme.breakpoints.down('xs')]: {
			width: '90% !important',
		}
	},
	'@keyframes moving': {
		'0%': {
			transform: 'translate(0, 0px)',
		},
		'50%': {
			transform: 'translate(0, 10px)',
		},
		'100%': {
			transform: 'translate(0, 0px)',
		}
	},

	'@keyframes firstAboutSmileMoving': {
		'0%': {
			transform: "rotate(0)",
			left: '60vw',
			top: 0,
		},
		'50%': {
			left: '75vw',
			top: 150,
		},
		'100%': {
			transform: "rotate(360deg)",
			left: '60vw',
			top: 0,
		}
	},
	'@keyframes thirdAbSmileMoving': {
		'0%': {
			transform: "rotate(0)",
			top: theme.spacing(-20),
		},
		'50%': {
			top: theme.spacing(0),
		},
		'100%': {
			transform: "rotate(-360deg)",
			top: theme.spacing(-20),
		}
	},
	'@keyframes secAbSmileSmallMoving': {
		'0%': {
			bottom: theme.spacing(5),
			transform: "rotate(0)",
		},
		'25%': {
			bottom: theme.spacing(10),
			transform: "rotate(35deg)",
		},
		'50%': {
			bottom: theme.spacing(15),
		},
		'75%': {
			bottom: theme.spacing(10),
			transform: "rotate(-35deg)",
		},
		'100%': {
			bottom: theme.spacing(5),
			transform: "rotate(0)",
		}
	},
}))

const MainPage = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(FETCH_MAIN_PAGE_REDUCER())
	}, [dispatch])
	return (
		<MainContainerComponent>
			<BannerComponent />
			<Container className={classes.cont}>
				<GradientCircleComponent className={classes.aboutCircle} />
				<Box className={classes.createPlaceBox}>
					<Typography
						variant='h4'
						align='center'
						component='h2'
						className={classes.createPlaceTxt}
						dangerouslySetInnerHTML={{ __html: t('main_page.create_place') }}
					/>
					<PointSvg className={classes.createPlaceImg} />
				</Box>

				<AboutBlockComponent
					src={FirstAboutImg}
					title={t('main_page.first_about_block.title')}
					before_list={t('main_page.first_about_block.before_list')}
					after_list={t('main_page.first_about_block.after_list')}
					list={t('main_page.first_about_block.list', { returnObjects: true })}
				>
					<ScrollImgComponent src={SmileImg} className={classes.firstAboutSmile} />
					<ScrollImgComponent src={PinkSmileImg} className={classes.firstAboutPinkSmile} />
					<CircleSvg className={classes.firstBlockCircle} />
				</AboutBlockComponent>

				<AboutBlockComponent
					src={SecondAboutImg}
					reverse
					className={classes.secAboutBlockImg}
					title={t('main_page.second_about_block.title')}
					list={t('main_page.second_about_block.list', { returnObjects: true })}
					before_list={t('main_page.second_about_block.before_list')}
				>
					<ScrollImgComponent src={SmileImg} className={classes.secAbSmile} />
					<ScrollImgComponent src={SmileImg}  className={classes.secAbSmileSmall} />
					<img src={SecImgBack} alt="item" className={classes.secImgBack} data-aos="zoom-in" />
				</AboutBlockComponent>
				<AboutBlockComponent
					src={ThirdAboutImg}
					before_list={t('main_page.third_about_block.before_list')}
					after_list={t('main_page.third_about_block.after_list')}
					title={t('main_page.third_about_block.title')}
					list={t('main_page.third_about_block.list', { returnObjects: true })}
				>
					<ScrollImgComponent src={BlueSmileImg} className={classes.thirdAbSmileBlue} />
				</AboutBlockComponent>
				<AboutBlockComponent
					src={FourthAboutImg}
					reverse
					before_list={t('main_page.fourth_about_block.before_list')}
					title={t('main_page.fourth_about_block.title')}
					list={t('main_page.fourth_about_block.list', { returnObjects: true })}
				>
					<img src={FourthImgBack} alt="item" className={classes.fourthImgBack} data-aos="zoom-in" />
				</AboutBlockComponent>
				<GradientCircleComponent className={classes.lastAboutCircle} />
			</Container>
			<HowItWorkComponent />
		</MainContainerComponent>
	)
}


MainPage.propTypes = {
	name: PropTypes.string,
}
export default React.memo(MainPage)