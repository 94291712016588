export const FEEDBACK_API = '/feedback'
export const FEEDBACK_EMPLOYESS_API = '/feedback_employees'
export const EMPLOYEE_API = '/employee'
export const EMPLOYEE_UPLOAD_IMAGE_API = '/employee_upload_image'
export const USER_UPLOAD_IMAGE_API = '/user_upload_image'
export const PLAN_API = '/plan'
export const QRCODE_API = '/qrcode'
export const REGISTER_API = '/register'
export const RESTAURANT_API = '/restaurant'
export const PASSWORD_FORGOT_API = '/forgot-password'
export const PASSWORD_RESET_API = '/reset-password'
export const CATEGORIES_API = '/category'
export const MENU_API = '/menu'
export const MENU_MEAL_API = '/menu_meal'
export const CLIENT_MENU_API = '/client_menu'
export const LOGIN_API = '/login'
export const ONBOARDING_API = '/onboarding'
export const USER_API = '/users'
export const MAIN_API = '/'
export const PLANS_API = '/plans'
export const FAQS_API = '/faq'
export const BLOG_API = '/blog'
export const STATIC_API = '/page'
export const PHONE_API = '/send_phone'
export const MAIN_PAGE_API = '/main'
export const CHECK_READY_API = '/ready'
export const CHECK_DOMAIN_API = '/check_domain'
export const VERIFY_EMAIL_API = '/verify_email'
export const RESEND_EMAIL_API = '/email/resend'
