import React from 'react'
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { classNames } from '../functions';

const useStyles = makeStyles((theme) => ({
	circle: {
		position: 'absolute',
		pointerEvents:'none',
		borderRadius: '50%',
		zIndex: -1,
		animation: `$bruh 16s linear infinite`,
		'-webkit-mask-image': 'radial-gradient(ellipse 100% 100% at 50% 50%,black 30%,transparent 50%)',
		// filter: 'blur(5px)'
	},
	'@keyframes bruh': {
		'0%': {
			backgroundPosition: '0 50%',
		},

		'50%': {
			backgroundPosition: '100% 50%',
		},
		'100%': {
			backgroundPosition: '0 50%',
		}
	}
}))

const GradientCircleComponent = ({ className = '' }) => {
	const classes = useStyles();

	return (
		// <div className=''>
		<div className={classNames([classes.circle, className])}>

		</div>
		// </div>
	)
}


GradientCircleComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(GradientCircleComponent);