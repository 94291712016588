import * as React from "react"

const UkrSvg = (props) => (
	<svg
		viewBox="0 0 50 70"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<path
			d="M589.68 78.84v52.84c0 3.57 2.07 6.98 5.55 8.52l19.32 8.57 19.32-8.57a9.318 9.318 0 0 0 5.55-8.52V78.84h-49.74Z"
			style={{
				fill: "#ffdb00",
				fillRule: "nonzero",
			}}
			transform="translate(-589.68 -78.84)"
		/>
		<path
			d="M614.55 82.73a9.274 9.274 0 0 0-2.33 6.16c.09 5.19.72 10.36.78 15.55.11 4.83-1.33 9.37-3.1 13.8-.59 1.22-1.22 2.42-1.86 3.62l-1.87-.38a3.11 3.11 0 0 1-2.44-3.66c.29-1.47 1.59-2.49 3.04-2.5l.68.07-1.52-12.68c-.5-5.65-3.41-10.6-7.7-13.82-.74-.55-1.52-1.06-2.33-1.51v45.03h10.39c.78 4.22 3.1 7.9 6.36 10.42.78.54 1.43 1.24 1.89 2.06.47-.82 1.12-1.53 1.89-2.06a17.06 17.06 0 0 0 6.36-10.42h10.39V87.38c-.81.45-1.59.95-2.33 1.51-4.29 3.22-7.2 8.17-7.7 13.82l-1.52 12.68.68-.07c1.45.01 2.74 1.03 3.04 2.5.34 1.68-.76 3.32-2.44 3.66l-1.87.38c-.64-1.2-1.27-2.4-1.86-3.62-1.76-4.43-3.21-8.96-3.1-13.8.06-5.18.69-10.36.78-15.55.02-2.36-.86-4.52-2.31-6.16Zm-15.54 11.14c2.01 2.36 3.36 5.3 3.76 8.54l1.25 10.46c-1.59.79-2.8 2.25-3.25 4.01h-1.76V93.87Zm31.08 0v23h-1.76a6.24 6.24 0 0 0-3.25-4.01l1.25-10.46c.4-3.23 1.75-6.17 3.76-8.53Zm-15.54 21.02c.84 2.73 1.98 5.33 3.41 7.74a6.954 6.954 0 0 0-3.41 2.3 7.021 7.021 0 0 0-3.41-2.3c1.43-2.42 2.58-5.01 3.41-7.74Zm-15.54 5.09h1.76a6.234 6.234 0 0 0 4.36 4.43l1.49.34c-.4 1.45-.62 2.98-.62 4.55h-6.99v-9.32Zm29.32 0h1.76v9.33h-6.99c0-1.58-.22-3.1-.62-4.55l1.49-.34c2.13-.6 3.81-2.3 4.36-4.44Zm-18.67 5.49a3.88 3.88 0 0 1 3.34 3.84h-3.89c0-1.34.2-2.62.55-3.84Zm9.79 0c.35 1.22.55 2.5.55 3.84h-3.89c0-1.96 1.45-3.58 3.34-3.84Zm-9.99 6.94H613v6.55a13.987 13.987 0 0 1-3.54-6.55Zm6.65 0h3.53c-.57 2.5-1.82 4.75-3.53 6.55v-6.55Z"
			style={{
				fill: "#005cb5",
				fillRule: "nonzero",
			}}
			transform="translate(-589.68 -78.84)"
		/>
	</svg>
)

export default UkrSvg
