import React from 'react'
import { useTranslation } from 'react-i18next';
import PostComponent from '../../components/PostComponent';
import { MAIN_ROUTE } from '../../constants/routes';

const SucEmployeeOnbPage = (props) => {
	const { t } = useTranslation();
	return (
		<PostComponent
			data={{
				title: t('suc_employee_onb_page.title'),
				description: t('suc_employee_onb_page.desc'),
			}}
			hiddenFetch
			backRoute={MAIN_ROUTE}
		/>
	)
}

export default React.memo(SucEmployeeOnbPage);