import React, { useEffect, useMemo } from 'react'
// import { makeStyles } from '@material-ui/core';
import GradientContainerComponent from '../../components/GradientContainerComponent';
import TextFieldComponent from '../../components/form/TextFieldComponent';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BtnsComponent from '../../components/form/BtnsComponent';
import { useState } from 'react';
import CheckboxComponent from '../../components/form/CheckboxComponent';
import { COMPANY_NAME, PRIVACY_LINK, REGULAMIN_LINK } from '../../constants';
import { LOGIN_ROUTE, PRIVACY_ROUTE, RULES_ROUTE } from '../../constants/routes';
import { useHistory, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import SelectComponent from '../../components/form/SelectComponent';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_REGISTER_PAGE_REDUCER, SEND_REGISTER_PAGE_REDUCER } from '../../reducers/RegisterPageReducer';
import PhoneFieldComponent from '../../components/form/PhoneFieldComponent';

// const useStyles = makeStyles((theme) => ({
// 	linkCont: {
// 		display: 'flex',
// 		justifyContent: 'center',
// 	}
// }))

const userSchema = (t) => Yup.object().shape({
	first_name: Yup.string()
		.required(t('validation.required'))
		.min(2, t('validation.min', { item: 4 }))
		.max(80, ('validation.max', { item: 80 })),
	last_name: Yup.string()
		.required(t('validation.required'))
		.min(2, t('validation.min', { item: 4 }))
		.max(80, ('validation.max', { item: 80 })),
	email: Yup.string()
		.email(t('validation.email'))
		.required(t('validation.required'))
		.min(2, t('validation.min', { item: 4 }))
		.max(80, ('validation.max', { item: 80 })),
	phone: Yup.string()
		.required(t('validation.required')),
	password: Yup.string()
		.min(8, t('validation.min', { item: 8 }))
		.required(t('validation.required')),
	rules: Yup.boolean()
		.oneOf([true], t('validation.required')),
	privacy: Yup.boolean()
		.oneOf([true], t('validation.required')),

});

const RegisterPage = (props) => {
	// const classes = useStyles();
	const dispatch = useDispatch();
	const [passwordConfirmation, setPasswordConfirmation] = useState(false);
	const { data, loading, error, success } = useSelector(state => state.RegisterPageReducer)
	const { planId } = useParams();
	const { t } = useTranslation()
	const history = useHistory();
	const errorMsg = useMemo(() => {
		if (error?.response?.body?.errors.email) {
			return 'errors.email_register'
		} else {
			return 'errors.register'	
		}
		// switch(true)
	}, [error])
	const formik = useFormik({
		validationSchema: userSchema(t),
		enableReinitialize: true,
		initialValues: {
			email: '',
			password: '',
			plan: planId,
			password_confirmation: '',
			phone: '',
			first_name: '',
			last_name: '',
			rules: false,
			newsletter: false,
			privacy: false,
		},
		onSubmit: values => {

			dispatch(SEND_REGISTER_PAGE_REDUCER(values));
		},
	});

	const handlePushLogin = useCallback(() => {
		history.push(LOGIN_ROUTE)
	}, [history])

	useEffect(() => {
		dispatch(FETCH_REGISTER_PAGE_REDUCER())
	}, [dispatch])

	return (
		<GradientContainerComponent
			// title={t('register_page.title')}
			title={success ? t('register_page.success_title') : t('register_page.title')}
			maxWidth='sm'
			loading={loading}
			message={success ? t('register_page.success') : ''}
			messageBtn={t('btns.login')}
			messageBtnTo={LOGIN_ROUTE}
			error={error ? t(errorMsg) : false}
		>
			<form onSubmit={formik.submitForm}>
				<TextFieldComponent
					id='first_name'
					autoComplete='given-name'
					formikProps={formik}
				/>
				<TextFieldComponent
					id='last_name'
					autoComplete='family-name'
					formikProps={formik}
				/>
				{data?.length
					? <SelectComponent
						id='plan'
						items={data.map((plan) => ({
							value: plan?.id,
							title: plan?.name,
						}))}
						formikProps={formik}
					/>
					: null}

				<TextFieldComponent
					id='email'
					autoComplete='username'
					type='email'
					formikProps={formik}
				/>
				<PhoneFieldComponent
					id='phone'
					formikProps={formik}
				/>
				<TextFieldComponent
					id='password'
					autoComplete='current-password'
					type='password'
					formikProps={formik}
				/>
				<TextFieldComponent
					id='password_confirmation'
					type='password'
					autoComplete='new-password'
					onBlur={(e) =>
						setPasswordConfirmation(formik.values.password !== formik.values.password_confirmation)
					}
					customHelperText={passwordConfirmation ? t('validation.password_confirmation') : ''}
					formikProps={formik}
				/>
				<CheckboxComponent
					id='newsletter'
					formikProps={formik}
				/>
				<CheckboxComponent
					id='rules'
					formikProps={formik}
					link={REGULAMIN_LINK}
					label={t('form.rules', { companyName: COMPANY_NAME, url: RULES_ROUTE })}
				/>
				<CheckboxComponent
					id='privacy'
					formikProps={formik}
					link={PRIVACY_LINK}
					label={t('form.privacy', { companyName: COMPANY_NAME, url: PRIVACY_ROUTE })}
				/>
				<BtnsComponent
					gradient
					title={t('btns.register')}
					secondTitle={t('btns.go_login')}
					handleSave={formik.submitForm}
					onClose={handlePushLogin}
				/>
			</form>
		</GradientContainerComponent>
	)
}

export default React.memo(RegisterPage);