
import { ThemeProvider, } from '@material-ui/core';
import Root from './Root';
import mainTheme from './themes/MainTheme';
import { Provider } from 'react-redux';
import store from './reducers';
import i18n from './translation/i18n';
import { I18nextProvider } from 'react-i18next';
import moment from "moment";
import "moment/locale/pl";
import { useEffect } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// pick a date util library
import MomentUtils from '@date-io/moment';


function App() {
  useEffect(() => {
    moment.locale("pl");
  }, [])
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ThemeProvider theme={mainTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Root />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Provider>
    </I18nextProvider>
  );
}

export default App;
