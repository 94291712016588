import React from "react"
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
	animation: {
	
	},
}));

const ScrollImgComponent = ({ src, className = ""}) => {
	const classes = useStyles();

	return (
		<img src={src} alt="item" className={`${classes.animation} ${className}`} />
	);
};

ScrollImgComponent.propTypes = {
	className: PropTypes.string,
	src: PropTypes.string,
};
export default React.memo(ScrollImgComponent);