import React from "react"
import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import LinkComponent from "./LinkComponent";
import UkrSvg from "../svgs/UkrSvg";

const useStyles = makeStyles((theme) => ({
	cont: {
		background: "#2659ac",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: theme.spacing(1),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			marginBottom: 0,
		}
	},
	title: {
		fontSize: 14,
		color: "#f8d447",
		fontWeight: "bold",
		textTransform: "uppercase",
	},
	icon: {
		height: 16,
		marginLeft: theme.spacing(1),
	}
}));

const BraveComponent = () => {
	const classes = useStyles();

	return (
		<LinkComponent external_link to="https://u24.gov.ua/">
			<section className={classes.cont}>
				<Typography className={classes.title}>Bravery to be Ukraine</Typography>
				<UkrSvg className={classes.icon} />
			</section>
		</LinkComponent>
	);
};

BraveComponent.propTypes = {
	name: PropTypes.string,
};
export default React.memo(BraveComponent);