import React from "react"
import { Box, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import HowItWorkSvg from "../../../svgs/HowItWorkSvg";
import { useTranslation } from "react-i18next";
import DemoBtnComponent from "../../../components/form/DemoBtnComponent";
import HowItWorkItemComponent from "./HowItWorkItemComponent";
import TakeInfoSvg from "../../../svgs/TakeInfoSvg";
import EmpRangSvg from "../../../svgs/EmpRangSvg";
import ReviewReactSvg from "../../../svgs/ReviewReactSvg";
import SaveMoneySvg from "../../../svgs/SaveMoneySvg";
import DecisionSvg from "../../../svgs/DecisionSvg";
import RoadSvg from "../../../svgs/RoadSvg";
import GradientCircleComponent from "../../../components/GradientCircleComponent";

const useStyles = makeStyles((theme) => ({
	icon: {
		width: 120,
		height: 120,
		[theme.breakpoints.down('xs')]: {
			width: 100,
			height: 100,
		}
	},
	title: {
		fontSize: 60,
		[theme.breakpoints.down('xs')]: {
			fontSize: 30,
		}
	},
	cont: {
		marginTop: theme.spacing(40),
		marginBottom: theme.spacing(40),
		display: 'flex',
		alignItems: 'baseline',
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(8),
			marginBottom: theme.spacing(8),
			display: 'block',
		}
	},
	gridCont: {
		position: 'relative',
	},
	road: {
		position: 'absolute',
		top: -10,
		left: -20,
		height: '100%',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		}
	},
	howItWorkCircle: {
		width: '120vw',
		height: '60vw',
		left: '-20vw',
		bottom: theme.spacing(-30),
		transform: 'rotate(-25deg)',
		opacity: 0.68,
		background: theme.palette.background.howItWorkGradient,
	},
	howItWorkBox: {
		position: 'relative',
	}
}));

const HowItWorkComponent = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box className={classes.howItWorkBox}>
			<Container className={classes.cont}>
				<Box data-aos="fade-up">
					<HowItWorkSvg className={classes.icon} />
					<Typography variant='h3' className={classes.title}>{t('main_page.how_it_work_title')}</Typography>
					<Typography>{t('main_page.how_it_work_desc')}</Typography>
					<DemoBtnComponent />
				</Box>
				<Grid container className={classes.gridCont}>
					<HowItWorkItemComponent
						Icon={TakeInfoSvg}
						title={t('main_page.how_it_work.take_info')}
					/>
					<HowItWorkItemComponent
						offset={100}
						Icon={ReviewReactSvg}
						title={t('main_page.how_it_work.review_react')}
					/>
					<HowItWorkItemComponent
						Icon={EmpRangSvg}
						offset={200}
						title={t('main_page.how_it_work.emp_rang')}
					/>
					<HowItWorkItemComponent
						Icon={DecisionSvg}
						offset={300}
						title={t('main_page.how_it_work.decision')}
					/>
					<HowItWorkItemComponent
						Icon={SaveMoneySvg}
						offset={400}
						title={t('main_page.how_it_work.save_money')}
					/>
					<RoadSvg className={classes.road} data-aos="zoom-in" />
				</Grid>
			</Container>
			<GradientCircleComponent className={classes.howItWorkCircle} />
		</Box>
	);
};

HowItWorkComponent.propTypes = {
	name: PropTypes.string,
};
export default React.memo(HowItWorkComponent);