import React, { useCallback, useState } from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { classNames } from '../functions';

const useStyles = makeStyles((theme) => ({
	langsContainer: {
		position: "absolute",
		zIndex: 20,
		transition: "visibility 0s, opacity 0.5s linear",
	},
	showLangCont: {
		visibility: "visible",
		opacity: 1,
	},
	hiddenLangCont: {
		visibility: "hidden",
		opacity: 0,
	},
	mainLangBox: {
		'& *': {
			transition: 'all 0.2s ease-in',
		},
		'&:hover': {
			'& *': {
				color: theme.palette.secondary.main,
			}
		}
	},
	arrowIcon: {
		transition: 'color 0.2s ease-in',
	},
	mainLang: {
		cursor: 'pointer',
		fontWeight: 'bold',
		transition: 'color 0.2s ease-in',

	},
	lang: {
		fontWeight: 'bold',
		cursor: 'pointer',
		transition: 'all 0.2s ease-in',
		'&:hover': {
			color: theme.palette.secondary.main,
		}
	},
	langBox: {
		padding: 20,
	}
}))

const LangComponent = (props) => {
	const classes = useStyles();
	const { i18n, } = useTranslation();
	const [show, setShow] = useState();
	const [langs, setLangs] = useState(
		i18n.languages.filter((lang) => lang !== i18n.language)
	);
	const [lang, setLang] = useState(i18n.language);

	const handlShowLangs = useCallback(() => {
		setShow(!show);
	}, [show]);
	const handleChangeLang = useCallback(
		(index) => {
			i18n.changeLanguage(langs[index]);
			localStorage.setItem("lang", langs[index]);
			let newLangs = [...langs];
			newLangs[index] = lang;
			setLang(langs[index]);
			setLangs(newLangs);
			handlShowLangs();
		},
		[langs, lang, handlShowLangs, i18n]
	);
	return (
		<Box className={classes.langBox}>
			<Box display="flex" alignItems="center" onClick={handlShowLangs} className={classes.mainLangBox}>
				<Typography
					className={classes.mainLang}
				>
					{lang.toUpperCase()}
				</Typography>
				{show ? (
					<ExpandLessIcon className={classes.arrowIcon} />
				) : (
					<ExpandMoreIcon className={classes.arrowIcon} />
				)}
			</Box>
			<Box className={
				classNames([classes.langsContainer,
				show ? classes.showLangCont : classes.hiddenLangCont
				])}>
				{langs.map((l, index) => (
					<Box key={index}>
					<Typography
					
						className={classes.lang}
						onClick={() => handleChangeLang(index)}
					>
						{l.toUpperCase()}
					</Typography>
					</Box>
				))}
			</Box>
		</Box>
	)
}

export default React.memo(LangComponent);