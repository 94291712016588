import React from "react"
import { Box, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../../components/form/ButtonComponent";
import { LANDING_BTN } from "../../../functions/csses";
import { LazyLoadImage } from "react-lazy-load-image-component";
import EnterpriseImg from '../../../imgs/enterprise.webp';
import LinkComponent from "../../../components/LinkComponent";
import { DEMO_LINK } from "../../../constants";

const useStyles = makeStyles((theme) => ({
	btn: {
		...LANDING_BTN,
		background: theme.palette.forGradient.secondaryBtn,
	},
	cont: {
		position: 'relative',
		marginTop: theme.spacing(20),
		// position: 'relative',
		background: theme.palette.primary.main,
		padding: `${theme.spacing(10)}px ${theme.spacing(8)}px`,
		paddingBottom: 0,
		[theme.breakpoints.down('sm')]: {
			padding: 0,
			borderRadius: 15,
			overflow: 'hidden',
		},
	},
	imgGrid: {
		display: 'flex',
		alignItems: 'flex-end',
		lineHeight: 0,
		'& span': {
			width: "100%",
			[theme.breakpoints.down('xs')]: {
				height: '100%',
			}
		},
		'& img': {
			width: "100%",
			[theme.breakpoints.down('xs')]: {
				height: '100%',
				objectFit: 'contain',
				objectPosition: 'bottom'
			}
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1),
			paddingTop: theme.spacing(4),
			paddingBottom: 0,
		},
		[theme.breakpoints.down('xs')]: {
			position: "absolute",
			bottom: 0,
			zIndex: 0,
			top: 0,
			left: 0,
			right: 0,
			margin: 'auto',
		}
	},
	content: {
		color: theme.palette.text.white,
		paddingBottom: theme.spacing(8),
		[theme.breakpoints.down('sm')]: {
			position: "absolute",
			bottom: 0,
			zIndex: 1,
			top: 0,
			left: 0,
			right: 0,
			margin: 'auto',
			padding: theme.spacing(4),
			background: '#00000080',
			display: 'flex',
			justifyContent: 'end',
			flexDirection: "column",
			alignItems: 'baseline'
		},
		[theme.breakpoints.down('xs')]: {
			padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
			position: "relative",
		}
	},
	desc: {
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(6),
		[theme.breakpoints.down('xs')]: {
			fontSize: 16,
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(4),
		}
	},
	title: {
		fontWeight: 'bold',
		fontSize: 80,
		[theme.breakpoints.down('xs')]: {
			fontSize: 40,
		}
	}
}));

const EnterpriseComponent = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Container >
			<Box className={classes.cont}>
				<Grid container>
					<Grid item md={6} xs={12} className={classes.content}>
						<Typography variant="h2" className={classes.title}>{t('main_page.enterprise_title')}</Typography>
						<Typography className={classes.desc} component='p' variant="h5">{t('main_page.enterprise_desc')}</Typography>
						<LinkComponent to={DEMO_LINK} external_link>
						<ButtonComponent variant='contained' color='secondary' className={classes.btn}>{t("btns.contact_with_us")}</ButtonComponent>
						</LinkComponent>
					</Grid>
					<Grid item md={6} xs={12} className={classes.imgGrid}>
						<LazyLoadImage
							effect="blur"
							src={EnterpriseImg}
							alt={t('main_page.enterprise_title')}
						/>
					</Grid>
				</Grid>
			</Box>
		</Container>
	);
};

EnterpriseComponent.propTypes = {
	name: PropTypes.string,
};
export default React.memo(EnterpriseComponent);