import React, { useCallback } from 'react'
import PropTypes from 'prop-types';
import IconButtonComponent from './IconButtonComponent';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(() => ({
	disableBtn: {
		cursor: 'auto'
	}
}))

const FaqLinkComponent = ({ url, title }) => {
	const classes = useStyles();
	const handleClick = useCallback(() => {
		if (url)
			window.open(url, '_blank')
	}, [url])
	return (
		<IconButtonComponent
			Icon={HelpIcon}
			className={url ? '' : classes.disableBtn}
			size='small'
			title={title}
			onClick={handleClick}
		/>
	)
}


FaqLinkComponent.propTypes = {
	url: PropTypes.any,
	title: PropTypes.any,
}
export default React.memo(FaqLinkComponent);