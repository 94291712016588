export const USER_COM_KEY = "mbmkyz";


export const STRIPE_REDIRECT_STATUS = 202;
export const ALREADY_VERIFIC_ERROR = 402;
export const AUTH_ERROR = 401;
export const NOT_VERIFY_ERROR = 402;

export const CALLBACK_TYPE_QUERY = 'callback=object'

export const EMPTY_COL = '-';
export const TOKEN_NAME = 'authention_token';

export const COMPANY_NAME = 'Booser';

// LINKS

export const REGULAMIN_LINK = 'https://booser.pl/static/rules';
export const PRIVACY_LINK = 'https://booser.pl/static/privacy';

// LANGS

export const PL_LNG = 'pl';
export const EN_LNG = 'en';
export const DE_LNG = 'de';
export const UK_LNG = 'uk';


// TIME

export const DATE_TIME = 'HH:mm DD.MM.YYYY'
export const SHORT_DATE_TIME = 'DD.MM.YYYY'

// REGEXS

// eslint-disable-next-line
export const PHONE_REGEX = /^[\+]?[(]?[0-9]{0,3}[)]?[" "]?[-\s\.]?[0-9]{3}[" "]?[-\s\.]?[0-9]{3}[" "]?[-\s\.]?[0-9]{3,4}$/im
export const CITY_REGEX = /^[a-zA-ZżŻźóÓąĄśćĆńŃęĘŁłŚś]+(?:[\s-][a-zA-ZżŻźóÓąĄśćĆńŃęĘŁłŚś]+)*$/;
export const DOMAIN_REGEX = /^[a-zA-Z0-9_-]*$/;
export const ZIP_CODE_REGEX = /^\d{1,5}(-\d{1,8})?$/;
export const ACCOUNT_NUMBER_REGEX = /\b[A-Z]{2}[0-9]{16,34}?\b/gm

export const GRID_SPACING = 2;

export const VALUTE = 'PLN'
export const INITIAL_COUNTRY = 'pl'

// API

export const API_URL = process.env.NODE_ENV === 'production' ? 'https://booser.pl' : ''

// SYSTEMS

export const ANDROID_SYSTEM = 'android';
export const IOS_SYSTEM = 'ios';

// MENU

export const DEFAULT_ADDITIVES = {
	name: '',
	price: 0,
	weight: '0 g',
}

export const DEFAULT_CATEGORY = {
	name: '',
	active: true,
	meals: [],
}
export const DEFAULT_MEAL = {
	name: "",
	active: true,
	src: '',
	weight: '',
	price: '',
	valute: VALUTE,
	description: '',
	additives: []
}


// ENV VARIABLES

export const FACEBOOK_APP_KEY = '176957924533860'


// ONBOARDING

export const ONBOARDING_PAY_STEP = 0;
export const ONBOARDING_COMPANY_STEP = 1;
export const ONBOARDING_BUSINESS_STEP = 2;
// export const ONBOARDING_EMPLOYEE_STEP = 3;
export const ONBOARDING_FINISH_STEP = 3;


// USERS TYPE

export const OWNER_USER_TYPE = 'owner'
export const MANAGER_USER_TYPE = 'manager'

export const STEPPER_ID = 'stepper'


// export const DEMO_LINK = 'https://calendly.com/lukasz-klaczynski/30min';
export const DEMO_LINK = 'https://booser.user.com/calendar/meeting/yaroslavsofinepl/';
export const USER_HELP_LINK = 'https://form.asana.com/?k=YvSaUa0sMBikMGAZCaT-Fw&d=1657560413345';


export const FACEBOOK_LINK = 'https://www.facebook.com/Booser-105346365356510'
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/booser'



// USER COM EVENTS

export const USER_CREATION_USER_COM = 'user_creating';
export const SUCCESS_REGISTRATION_USER_COM = 'finished registration';
export const SUCCESS_ONBOARDING_USER_COM = 'finished onboarding';