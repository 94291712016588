import React from 'react'
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    },
    dialog: {
        padding: `${theme.spacing(3)}px ${theme.spacing(10)}px`,
        // minWidth: 400, 
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(3),
        }
        // minWidth: 400, 
    },
    block: {
        maxWidth: 400,
        margin: 'auto', 
    },

}))

const DialogComponent = ({ title, showDialog, handleToggleDialog = () => { }, children  }) => {
    const classes = useStyles();
    return (
        <Dialog open={showDialog} onClose={handleToggleDialog} maxWidth='sm' fullWidth>
            <DialogContent className={classes.dialog}>
                <Typography variant='h2' className={classes.title}>{title}</Typography>
                <div className={classes.block}>
                    {children}
                </div>
            </DialogContent>
        </Dialog>
    )
}


DialogComponent.propTypes = {
    title: PropTypes.string,
    showDialog: PropTypes.bool,
    handleSave: PropTypes.func,
    handleToggleDialog: PropTypes.func,
}
export default React.memo(DialogComponent);