import React from 'react'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    star: {
        color: theme.palette.secondary.main,
    },
}))

const StarComponent = (props) => {
 const classes = useStyles();

    return (
        <span className={classes.star}>*</span>
    )
}

export default React.memo(StarComponent);