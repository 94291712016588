import React from 'react'
import {  makeStyles, Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
	text: {
		'& *': {
			fontSize: theme.typography.body1.fontSize,
			// color: theme.palette.text.primary,
		}
	},
	alert: {
		alignItems: 'center',
		
	},
	alertError: {
		background: theme.palette.primary.main,
	}
}))

const NotificationComponent = ({ message, severity = 'error', showNotif, onClick = () => { } }) => {
	const classes = useStyles();

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			className={classes.text}
			open={showNotif}
			autoHideDuration={200000}
			onClose={onClick}
			severity={severity}
		>
			<Alert onClose={onClick} severity={severity} variant="filled" 
			className={classes.alert}
			classes={{
				filledError: classes.alertError
			}}
			>
				{message}
			</Alert>
		</Snackbar>
	)
}


NotificationComponent.propTypes = {
	onClick: PropTypes.func,
	message: PropTypes.string,
	showNotif: PropTypes.bool,
}
export default React.memo(NotificationComponent);