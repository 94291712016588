import React from "react"
import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => ({
	icon: {
		width: 50,
		height: 50,
	},
	box: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	td: {
		padding: theme.spacing(2)
	}
}));

const PlanTdComponent = ({ has }) => {
	const classes = useStyles();

	return (
		<td className={classes.td}>
			<Box className={classes.box}>
				{has
					? <CheckIcon className={classes.icon} color='secondary' />
					: <div className={classes.icon}></div>
				}
			</Box>
		</td>
	);
};

PlanTdComponent.propTypes = {
	has: PropTypes.bool,
};
export default React.memo(PlanTdComponent);