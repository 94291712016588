import { AUTH_ERROR, NOT_VERIFY_ERROR } from "../constants";
import { ONBOARDING_API } from "../constants/api";
import { LOGIN_ROUTE, RESEND_ROUTE } from "../constants/routes";
import { GET, POST } from "../functions/superagentSending";

const initialState = {
	loading: false,
	data: {
		company: {
			name: '',
			nip: '',
			street: '',
			zipCode: '',
			city: '',
			country:  '',
		},
		business: {
			name: '',
			nip: '',
			street: '',
			zipCode: '',
			city: '',
			country: '',
		},
		positions: [
			{
				id: 1,
				name: 'Kelner'
			},
			{
				id: 2,
				name: 'Barman'
			},
			{
				id: 3,
				name: 'Administrator'
			},
			{
				id: 4,
				name: 'Inny'
			},
		],
		currentStep: 0,
		lastStep: 5,
		plan: 'Plan Początkowy',
		price: '160',
		valute: 'zł',
	},
	error: '',
};


const FETCH_ONBOARDING_REQUEST = 'FETCH_ONBOARDING_REQUEST'
const FETCH_ONBOARDING_SUCCESS = 'FETCH_ONBOARDING_SUCCESS'
const FETCH_ONBOARDING_FAILURE = 'FETCH_ONBOARDING_FAILURE'


const fetchOnboardingSuccess = (value) => ({
	type: FETCH_ONBOARDING_SUCCESS,
	payload: value
})
const fetchOnboardingRequest = () => ({
	type: FETCH_ONBOARDING_REQUEST
})

const fetchOnboardingFailure = error => ({
	type: FETCH_ONBOARDING_FAILURE,
	payload: error
})


const OnboardingReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_ONBOARDING_REQUEST:
			return {
				...state,
				loading: true,
				error: ''
			}
		case FETCH_ONBOARDING_SUCCESS:
			return {
				data: {
					...state.data,
					...(payload?.data || {}),
				},
				loading: false,
				error: ''
			}
		case FETCH_ONBOARDING_FAILURE:
			return {
				...state,
				loading: false,
				error: payload
			}
		default:
			return state
	}
}

export const BACK_ONBOARDING_REDUCER = (step) => {
	return (dispatch) => {
		dispatch(fetchOnboardingSuccess({
			currentStep: step
		}));
	}
}
export const UPDATE_ONBOARDING_REDUCER = (data) => {
	return (dispatch) => {
		dispatch(fetchOnboardingRequest());
		POST(ONBOARDING_API, (data))
			.then((success) => {
				dispatch(fetchOnboardingSuccess(success?.body));
			})
			.catch((error) => {
				dispatch(fetchOnboardingFailure());
			})
	}
}

export const FETCH_ONBOARDING_REDUCER = (history) => {
	return (dispatch) => {
	
		dispatch(fetchOnboardingRequest());
		GET(ONBOARDING_API,
			(success) => {
				dispatch(fetchOnboardingSuccess(success?.body));
			},
			(error) => {
				if (error?.status === AUTH_ERROR) {
					history.push(LOGIN_ROUTE)
				} else if (error?.status === NOT_VERIFY_ERROR) {
					history.push(RESEND_ROUTE)
				}
				dispatch(fetchOnboardingFailure());
			})

	}
}


export default OnboardingReducer;