export const LANDING_BTN = {
	borderRadius: 20,
	boxShadow: 'none',
};

export const BLUR_BOX = (theme) => ({
	background: `${theme.palette.background.white}95`,
	backdropFilter: 'blur(50px)',
})

export const HR_TITLE = (theme) => ({
	marginBottom: theme.spacing(4),
	fontSize: 60,
	[theme.breakpoints.down('sm')]: {
		fontSize: 30,
		marginBottom: theme.spacing(2)
	},
})
export const ARTICLE_CSS = (theme) => ({
	marginBottom: 24,
	fontFamily: theme.typography.fontFamily,
	overflowWrap: 'break-word',
	'& *': {
		fontSize: theme.typography.body1.fontSize,
		maxWidth: '100%',
		height: 'auto',
		wordBreak: 'break-word',
	},
	'& .marker': {
		textTransform: 'uppercase',
		fontSize: theme.typography.button.fontSize,
		boxShadow: theme.shadows[2],
		fontWeight: 'bold',
		cursor: 'pointer',
		color: theme.palette.text.white,
		background: theme.palette.secondary.main,
		borderRadius: 15,
		padding: '10px 40px',
		transition: 'all 0.25s ease',
		'&:hover': {
			background: theme.palette.secondary.dark,	
		}
	},
	'& [data-oembed-url]': {
		'& > div': {
			maxWidth: '100% !important',
			'& > div': {
				paddingBottom: '0 !important',
				height: 'auto !important',
				'& iframe': {
					position: 'relative !important',
					minHeight: 450,
					[theme.breakpoints.down('sm')]: {
						minHeight: 350,
					},
				}
			}
		}
	},
	// '& iframe': {
	//     minHeight: 600,
	// },
	'& img': {
		height: 'auto !important',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100% !important',
		},
	},
	'& p': {
		marginTop: 20,
		...theme.typography.body1
	},
	'& hr': {
		background: theme.palette.secondary.main,
		border: `0.75px solid ${theme.palette.secondary.main}`,
	},
	'& h2': {
		fontSize: theme.typography.h2.fontSize,
		fontWeight: 'normal',
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: 22,
		},
	},
	'& h3': {
		fontSize: theme.typography.h3.fontSize,
		fontWeight: 'normal',
		color: theme.palette.primary.main,
	},
	'& h4': {
		fontSize: theme.typography.h3.fontSize,
		color: theme.palette.primary.main,
	},
	'& ol ol, ol ul': {
		fontSize: theme.typography.body1.fontSize,
		listStyle: 'none',
		padding: 0,
		'& li::before': {
			color: theme.palette.primary.main,
			content: "'•'",
			fontSize: `calc(${theme.typography.body1.fontSize} + 2px)`,
			lineHeight: 1,
			margin: '0 10px 0 -20px',
		},
	},
	'& ul': {
		'& li': {
			marginLeft: 20,
		},
		listStyle: 'none',
		padding: 0,
		'& li::before': {
			color: theme.palette.primary.main,
			content: "'•'",
			fontSize: `calc(${theme.typography.body1.fontSize} + 2px)`,
			lineHeight: 1,
			margin: '0 10px 0 -20px',
		},
	},
	'& ul ol': {
		...theme.typography.body1,
		marginLeft: 20,
		listStyle: 'none !important',
		counterReset: 'countMe2',
		'& li': {
			counterIncrement: 'countMe2',
			paddingLeft: '25px',
		},
		'& li::before': {
			color: theme.palette.primary.main,
			content: 'counter(countMe2) ". "',
			fontSize: `calc(${theme.typography.body1.fontSize} + 2px)`,
		},
	},
	'& ul, ol ul': {
		'& li::before': {
			fontWeight: 550,
			lineHeight: 24,
			marginLeft: '-10px',
		},
	},
	'& ol li::marker': {
		width: '1em',
		fontWeight: 900,
		color: theme.palette.primary.main,
		margin: '0 6px 0 -25px',
	},
	'& .border': {
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
		padding: '15px 40px',
		fontSize: theme.typography.h1.fontSize,
		borderRadius: 50,
		marginTop: 5,
		marginBottom: 5,
		textDecoration: 'none',
		textAlign: 'center',
		maxWidth: '100%',
		width: 'fit-content',
	},
	'& .border *': {
		fontSize: theme.typography.h1.fontSize,
	},
	'& a': {
		textDecoration: 'none',
		color: theme.palette.primary.main,
		transition: 'all 0.2s ease-in',
		'&:hover': {
			color: theme.palette.secondary.main,
		},
	},
	'& table': {
		border: 'none',
		margin: '20px auto',
		borderSpacing: 0,
	},
	'& td, th': {
		border: `1px solid ${theme.palette.background.lightGray}`,
		padding: '0 5px',
		// borderColor: theme.palette.gray.light,
		// textAlign: 'center',
		// [theme.breakpoints.down('sm')]: {
		//   display: 'block',
		//   width: '100% !important',
		// },
	},
	'& caption': {
		fontSize: theme.typography.body1.fontSize,
		fontStyle: 'italic',
		marginBottom: 10,
	},
	'& blockquote': {
		position: 'relative',
		padding: '10px 20px',
		margin: 0,
		background: theme.palette.background.gray,
		'&>p': {
			fontSize: '18px !important',
			fontWeight: '400 !important',
			fontStyle: 'italic',
		},
		'&::after': {
			color: theme.palette.background.gray,
			fontSize: '12em',
			lineHeight: '0.65em',
			right: '20px',
			position: 'absolute',
			zIndex: 0,
			top: '-10px',
		},
		'&::before': {
			content: '""',
			position: 'absolute',
			width: 4,
			height: '100%',
			display: 'block',
			top: 0,
			left: 0,
			background: theme.palette.primary.main,
		},
	},
})