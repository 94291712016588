import React from 'react'
import { useSelector } from 'react-redux';
import {  FAQS_ROUTE } from '../../constants/routes';
import { FETCH_HELP_POST_REDUCER } from '../../reducers/HelpPostPageReducer';
import PostComponent from '../../components/PostComponent';
import { useTranslation } from 'react-i18next';

const HelpPostPage = (props) => {
	const { data, loading, } = useSelector((state) => state.HelpPostPageReducer)
	const { t } = useTranslation();

	return (
		<PostComponent
			data={data}
			loading={loading}
			breadcrumbs={[
				{
					title: t('faqs_page.title'),
					to: FAQS_ROUTE
				}
			]}
			backRoute={FAQS_ROUTE}
			fetchFun={FETCH_HELP_POST_REDUCER}
		/>
	)
}

export default React.memo(HelpPostPage);