import React, { useEffect, useState } from 'react';
import {
  Box,
  makeStyles,
  Typography,
  Dialog,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useCallback } from 'react';
import LogoSvg from '../svgs/LogoSvg';
import ButtonComponent from './form/ButtonComponent';
import { ARTICLE_CSS } from '../functions/csses';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
  },
  logo: {
    width: 120
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    height: 220,
    overflowY: 'auto',
  },
  text: {
    ...ARTICLE_CSS(theme),
  },
  btnCont: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btn: {
    '& svg': {
      display: 'none'
    }
  }
}));

const CookiesComponent = (props) => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['cookies-shown']);
  const [canShow, setCanShow] = useState(false);
  const { t } = useTranslation();
  const handleAccept = useCallback(() => {
    setCookie('cookies-shown', true, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  }, [setCookie]);

  useEffect(() => {
    const scrollCheck = () => {
      if (!canShow && window.pageYOffset >= 100 && !cookies['cookies-shown']) {
        setCanShow(true);
      }
    }
    window.addEventListener('scroll', scrollCheck);
    return (() => {
      window.removeEventListener('scroll', scrollCheck);
    })
    // eslint-disable-next-line 
  }, [cookies])
  return (
    <Dialog open={!cookies['cookies-shown'] && canShow} maxWidth='sm'  >
      <Box className={classes.dialog}>
        <LogoSvg className={classes.logo} />
        <Box className={classes.content}>
          <Typography
            className={classes.text}
            dangerouslySetInnerHTML={{ __html: t('cookies') }}
          />
        </Box>
        <Box className={classes.btnCont}>
          <ButtonComponent
            onClick={handleAccept}
            className={classes.btn}
            variant='contained'
            color='primary'
          >
            {t('btns.accept')}
          </ButtonComponent>
        </Box>
      </Box>

    </Dialog>
  );
};

export default React.memo(CookiesComponent);
