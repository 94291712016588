import React, { useMemo } from 'react'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import countries from '../../constants/countries';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { scrollToTextField } from '../../functions';

const useStyles = makeStyles((theme) => ({
	textField: {
		marginTop: 5,
		minWidth: 280,
		[theme.breakpoints.down('sm')]: {
			minWidth: 'auto',
		},
	},
}))

function countryToFlag(isoCode) {
	return typeof String.fromCodePoint !== 'undefined'
		? isoCode
			.toUpperCase()
			.replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
		: isoCode;
}


const CountrySelectComponent = ({
	id,
	formikProps,
	onBlur = () => { },
	onChange = () => { },
	currentValue,
	label,
	customHelperText,
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { options, flags } = useMemo(() => {
		const flags = {};
		const options = [];
		countries.forEach(({ label, code }) => {
			options.push(label);
			flags[label] = countryToFlag(code)
		})
		return {
			options,
			flags
		}
	}, [])
	const { error, touched } = useMemo(() => {
		return {
			error: formikProps.errors[id],
			touched: formikProps.touched[id]
		}
	}, [id, formikProps])
	const value = useMemo(() => currentValue || formikProps.values[id], [formikProps, id, currentValue]);
	const isError = () => error && Boolean(touched) ? true : false
	const handleBlur = (event,) => {
		onBlur(event);
		formikProps.setFieldValue(id, value)
	}
	const handleChange = (event, value) => {
		onChange(event);
		formikProps.setFieldValue(id, value)
	}

	return (
		<Autocomplete
			id={id}
			options={options}
			classes={{
				option: classes.option,
			}}
			autoHighlight
			fullWidth
			onChange={handleChange}
			onBlur={handleBlur}
			value={value}
			className={classes.textField}
			getOptionLabel={(option) => option || ''}
			getOptionSelected={(option, value) => option === value}
			renderOption={(option, key) => {
				return (
					<React.Fragment>
						<span>{flags[option]}</span>
						{option}
					</React.Fragment>
				)
			}}
			renderInput={(params) => (
				<TextField
					autoComplete='country-name'
					{...params}
					onClick={scrollToTextField}
					error={Boolean(error)}
					label={label || t(`form.${id}`)}
					variant="outlined"

					helperText={customHelperText ? customHelperText : isError() ? error : ' '}
				/>
			)}
		/>
	)
}

export default React.memo(CountrySelectComponent);