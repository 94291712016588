import { STATIC_API } from '../constants/api';
import { GET } from '../functions/superagentSending';

const initialState = {
	loading: true,
	data: {},
	error: '',
};

const FETCH_STATIC_REQUEST = 'FETCH_STATIC_REQUEST';
const FETCH_STATIC_SUCCESS = 'FETCH_STATIC_SUCCESS';
const FETCH_STATIC_FAILURE = 'FETCH_STATIC_FAILURE';

const fetchStaticPageRequest = () => ({
	type: FETCH_STATIC_REQUEST,
});

const fetchStaticPageSuccess = (data) => ({
	type: FETCH_STATIC_SUCCESS,
	payload: data,
});

const fetchStaticPageFailure = (error) => ({
	type: FETCH_STATIC_FAILURE,
	payload: error,
});

const StaticPageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_STATIC_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_STATIC_SUCCESS:
			return {
				...state,
				loading: false,
				data: payload,
				error: '',
			};
		case FETCH_STATIC_FAILURE:
			return {
				loading: false,
				data: {},
				error: payload,
			};
		default:
			return state;
	}
};

export const FETCH_STATIC_REDUCER = (slug) => {
	return (dispatch) => {
		dispatch(fetchStaticPageRequest());
		GET(`${STATIC_API}/${slug}`, (success) => {
			dispatch(fetchStaticPageSuccess(success?.body?.data));
		}, (error) => {
			dispatch(fetchStaticPageFailure(error));
			dispatch(fetchStaticPageFailure(''));
		})
	};
};

export default StaticPageReducer;
