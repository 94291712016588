import React, { useState, useCallback, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import PaperComponent from './PaperComponent';
import { makeStyles } from '@material-ui/core';
import LoaderComponent from './LoaderComponent';
import NotificationComponent from './NotificationComponent';
import { useTranslation } from 'react-i18next';
import BtnsComponent from './form/BtnsComponent';
import { useHistory } from 'react-router';
const useStyles = makeStyles((theme) => ({
	cont: {
		background: theme.palette.background.primaryGradient,
		width: '100vw',
		minHeight: '100vh',
	},
	btn: {
		marginTop: theme.spacing(4),
	}
}))

const GradientContainerComponent = ({ loading, title, subtitle, children, maxWidth, error, message, messageBtn, messageBtnTo }) => {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();
	const [showNotif, setShowNotif] = useState(false);
	const handleCloseNotification = useCallback(() => {
		setShowNotif(false)
	}, [])

	const handlePush = useCallback(() => { 
		history.push(messageBtnTo)
	}, [history, messageBtnTo])

	useEffect(() => {
		if (error)
			setShowNotif(true)
		return (() => {
			setShowNotif(false)
		})
	}, [error])

	
	return (
		<Box className={classes.cont}>
			<PaperComponent title={title} subtitle={message ? null : subtitle} maxWidth={maxWidth}>
				{message
					? <>
						<Typography aling='center'>{message}</Typography>

						{messageBtnTo && messageBtn
							?
							<BtnsComponent
								gradient
								title={messageBtn}
								handleSave={handlePush}
								hiddenSecondBtn
							/>
							: null
						}
					</>
					: children
				}

			</PaperComponent>
			{loading ? <LoaderComponent fixed/> : null}
			<NotificationComponent
				message={error || t('errors.wrong')}
				onClick={handleCloseNotification}
				showNotif={showNotif}
			/>
		</Box>
	)
}


GradientContainerComponent.propTypes = {
	subtitle: PropTypes.string,
	maxWidth: PropTypes.string,
	title: PropTypes.string,
}
export default React.memo(GradientContainerComponent);