import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// MAP_ROUTE, TIPS_ROUTE, MAIN_ROUTE
import { REGISTER_ROUTE, MAIN_ROUTE, LOGIN_ROUTE, ABOUT_ROUTE, FORGOT_ROUTE, RESET_ROUTE, SUC_VERIFICATION_ROUTE, ONBOARDING_ROUTE, BLOG_ROUTE, FAQS_ROUTE, STATIC_ROUTE, SUC_EMPLOYEE_ONB_ROUTE, RESEND_ROUTE, PLAN_ROUTE } from "./constants/routes";
import MainPage from "./pages/mainPage/MainPage";
import RegisterPage from "./pages/registerPage/RegisterPage";
import ForgotPage from "./pages/forgotPage/ForgotPage";
import LoginPage from "./pages/loginPage/LoginPage"
import ResetPage from "./pages/resetPage/ResetPage";
import OnboardingPage from "./pages/onboardingPage/OnboardingPage";
import BlogPage from "./pages/blogPage/BlogPage";
import PostPage from "./pages/postPage/PostPage";
import HelpPostPage from "./pages/helpPostPage/HelpPostPage";
import FaqsPage from "./pages/faqsPage/FaqsPage";
import AOS from 'aos';
import 'aos/dist/aos.css'
import StaticPage from "./pages/staticPage/StaticPage";
import SucEmployeeOnbPage from "./pages/sucEmployeeOnbPage/SucEmployeeOnbPage";
import SucVerificationPage from "./pages/sucVerificationPage/SucVerificationPage";
import ResendPage from "./pages/resendPage/ResendPage";
import AboutPage from "./pages/aboutPage/AboutPage";
import PlanPage from "./pages/planPage/PlanPage";

const Root = (props) => {
    useEffect(() => {
        AOS.init({
            once: true,
        });
    }, [])

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={`${REGISTER_ROUTE}/:planId`} component={RegisterPage} />
                <Route exact path={LOGIN_ROUTE} component={LoginPage} />
                <Route exact path={FORGOT_ROUTE} component={ForgotPage} />
                <Route exact path={RESET_ROUTE} component={ResetPage} />
                <Route exact path={ONBOARDING_ROUTE} component={OnboardingPage} />
                <Route exact path={BLOG_ROUTE} component={BlogPage} />
                <Route exact path={`${BLOG_ROUTE}/:slug`} component={PostPage} />
                <Route exact path={`${FAQS_ROUTE}/:slug`} component={HelpPostPage} />
                <Route exact path={`${STATIC_ROUTE}/:slug`} component={StaticPage} />
                <Route exact path={SUC_EMPLOYEE_ONB_ROUTE} component={SucEmployeeOnbPage} />
                <Route exact path={SUC_VERIFICATION_ROUTE} component={SucVerificationPage} />
                <Route exact path={RESEND_ROUTE} component={ResendPage} />
                <Route exact path={FAQS_ROUTE} component={FaqsPage} />
                <Route exact path={ABOUT_ROUTE} component={AboutPage} />
                <Route exact path={PLAN_ROUTE} component={PlanPage} />
                <Route exact path={MAIN_ROUTE} component={MainPage} />
            </Switch>
        </BrowserRouter>
    );
};

export default Root;
