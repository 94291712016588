import React, { useCallback } from 'react'
import { Box, makeStyles } from '@material-ui/core';
import ButtonComponent from './ButtonComponent';
import lottie from 'lottie-web';
import chosePlanAnim from '../../animations/chosePlan.json';
import { LANDING_BTN } from '../../functions/csses';
import { classNames } from '../../functions';
const useStyles = makeStyles((theme) => ({
	anim: {
		bottom: 0,
		position: 'absolute',
	},
	btnBox: {
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
	},
	btn: {
		...LANDING_BTN,
	},
	grd: {
		background: theme.palette.forGradient.secondaryBtn,
	}
}))

const AnimBtnComponent = ({ primary, title, handleClick = () => { }, showAnim, className }) => {
	const classes = useStyles();
	const animCont = useCallback(node => {
		if (node !== null) {
			lottie.loadAnimation({
				container: node,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				animationData: chosePlanAnim
			});
		}
	}, []);
	return (
		<Box className={classes.btnBox}>
			{/* <LinkComponent to={`${REGISTER_ROUTE}/${id}`}> */}
			{showAnim ? <div className={classes.anim} ref={animCont}></div> : null}
			<ButtonComponent
				onClick={handleClick}
				className={classNames([classes.btn, !primary ? classes.grd : null])}
				variant='contained'
				color='primary'
			>
				{title}
			</ButtonComponent>
			{/* </LinkComponent> */}
		</Box>
	)
}

export default React.memo(AnimBtnComponent);