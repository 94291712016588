import * as React from "react"

const DecisionSvg = (props) => (
	<svg
		viewBox="0 0 105 104"
		xmlns="http://www.w3.org/2000/svg"
		xmlSpace="preserve"
		style={{
			fillRule: "evenodd",
			clipRule: "evenodd",
			strokeLinejoin: "round",
			strokeMiterlimit: 2,
		}}
		{...props}
	>
		<path
			d="M91.177 239.12a2.743 2.743 0 0 1-1.445-2.761c.063-3.62.018-7.242.023-10.863-.176-10.339 7.332-19.338 17.536-21.016a32.078 32.078 0 0 1 3.941-.294c.628-.035 1.259-.006 1.9-.006-.025-.162-.012-.245-.047-.281a15.264 15.264 0 0 1-4.245-11.763c.13-3.378-.124-6.79.255-10.134.793-7 7.2-12.36 14.386-12.36 7.186 0 13.7 5.364 14.364 12.359.323 4.552.34 9.12.05 13.674a12.462 12.462 0 0 1-3.469 7.623l-.692.792c1.788.1 3.47.11 5.135.286 9.292.981 17.289 8.842 18.1 18.19.418 4.839.185 9.735.23 14.6.009.969-.69 1.479-1.408 1.949l-64.614.005Zm29.148-4.1a1.41 1.41 0 0 0-.043-.323c-3.547-8.62-7.106-17.235-10.675-25.846a1.102 1.102 0 0 0-.91-.443c-7.39.713-13.918 6.946-14.636 14.353-.386 3.989-.167 8.037-.216 12.059 0 .055.064.11.119.2h26.361Zm32.8.013c0-3.691.132-7.307-.027-10.911-.491-8.011-6.616-14.625-14.566-15.729-.83-.1-1.1.175-1.385.859a7691.507 7691.507 0 0 1-10.187 24.731c-.134.325-.234.665-.369 1.05h26.534Zm-18.9-46.665a22.595 22.595 0 0 1-10.325-2.578c-.272-.145-.76.012-1.11.136-1.747.615-3.448 1.386-5.229 1.866-1.53.323-3.078.553-4.636.689 0 1.806-.037 3.628.007 5.448.161 5.272 4.239 9.659 9.485 10.205 5.142.577 9.964-2.794 11.189-7.821.394-2.63.6-5.284.616-7.943m-20.437 19.9 9.7 23.49 9.68-23.457h-7.654c0 1.637.028 3.22-.009 4.8a2.025 2.025 0 0 1-1.972 2.041h-.031a2.05 2.05 0 0 1-2.041-2.041v-.007c-.022-.4-.006-.812-.007-1.218v-3.61l-7.666.002Zm20.283-23.993c-.229-4.2-1.992-7.362-5.589-9.28a10.05 10.05 0 0 0-11.108.7 10.273 10.273 0 0 0-4.45 8.582 16.148 16.148 0 0 0 8.7-2.388 3.108 3.108 0 0 1 3.732-.006 16.248 16.248 0 0 0 8.719 2.4"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-71.468 -135.127)"
		/>
		<path
			d="M0 16.7c.272-1.142.455-2.314.83-3.421C3.28 6.042 8.26 1.5 15.8.324c7.755-1.207 13.982 1.7 18.514 8.146.572.653.704 1.588.336 2.374a2.013 2.013 0 0 1-3.451.26c-.695-.872-1.314-1.816-2.087-2.611a14.616 14.616 0 0 0-10.446-4.396c-8.016 0-14.611 6.596-14.611 14.611 0 7.256 5.404 13.468 12.59 14.471 5.853.758 10.531-1.37 14.046-6.089.141-.19.262-.395.4-.586a2.03 2.03 0 0 1 1.748-1c1.112 0 2.027.915 2.027 2.027 0 .439-.143.868-.408 1.219a18.39 18.39 0 0 1-10.945 8.033c-.875.238-1.776.378-2.727.576v19.157h5.656c.846 0 1.694-.025 2.539.011h.035c1.123 0 2.051.916 2.065 2.039a2.027 2.027 0 0 1-2.018 2.002c-.033 0-.066 0-.099-.002a716.43 716.43 0 0 1-10.157 0c-1.4-.011-2.139-.871-2.141-2.394-.006-6.567-.006-13.133 0-19.7v-.956a44.89 44.89 0 0 1-5.232-1.609A18.374 18.374 0 0 1 .184 21.32 3.633 3.633 0 0 0 0 20.763V16.7Z"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(0 -.057)"
		/>
		<path
			d="M348.109 37.336v20.768c0 1.674-.749 2.423-2.415 2.428-3.215.009-6.431.009-9.647 0-1.449 0-2.383-.844-2.354-2.076a2.081 2.081 0 0 1 2.346-1.981c2.632-.01 5.265 0 7.952 0V37.29a18.661 18.661 0 0 1-13.75-8.717 18.093 18.093 0 0 1-2.609-12.932c1.51-8.965 9.349-15.595 18.44-15.595 10.259 0 18.7 8.442 18.7 18.7 0 9.492-7.227 17.555-16.662 18.589m-16.66-18.7v.055c0 8.024 6.603 14.628 14.628 14.628s14.628-6.604 14.628-14.628c0-7.989-6.544-14.576-14.533-14.628-8.036-.036-14.68 6.542-14.724 14.578"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-260.741 -.013)"
		/>
		<path
			d="M63.653 60.6c1.308-1.333 2.411-2.574 3.649-3.661a2.961 2.961 0 0 1 1.957-.759A1.951 1.951 0 0 1 70.4 59.5c-.939 1.062-1.988 2.027-2.986 3.037-.256.26-.5.529-.848.894 1.215 1.2 2.447 2.3 3.532 3.538a3.03 3.03 0 0 1 .794 1.95 1.956 1.956 0 0 1-1.938 1.746c-.504 0-.989-.196-1.352-.546-.92-.792-1.754-1.686-2.6-2.557-.4-.409-.746-.87-1.248-1.464-1.325 1.357-2.442 2.619-3.7 3.728a2.73 2.73 0 0 1-1.949.725 2.318 2.318 0 0 1-1.524-1.351 2.531 2.531 0 0 1 .478-2 39.178 39.178 0 0 1 2.969-3.048c.233-.235.451-.483.77-.826-1.211-1.194-2.451-2.293-3.526-3.535a3.05 3.05 0 0 1-.781-2.041 1.97 1.97 0 0 1 1.941-1.662c.567 0 1.108.246 1.481.674 1.245 1.188 2.407 2.462 3.74 3.837"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-44.987 -44.71)"
		/>
		<path
			d="M165.751 81.866h.031c1.11 0 2.024.913 2.024 2.024 0 1.11-.914 2.024-2.024 2.024a2.034 2.034 0 0 1-2.024-2.024v-.024a2.056 2.056 0 0 1 1.993-2"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-130.428 -65.216)"
		/>
		<path
			d="M386.774 70.362c2.013-2.015 3.86-3.889 5.742-5.728.368-.388.826-.68 1.334-.85a2.002 2.002 0 0 1 2.425 1.946c0 .494-.183.971-.515 1.337a574.975 574.975 0 0 1-7.529 7.533 1.875 1.875 0 0 1-2.745-.02 126.217 126.217 0 0 1-4.371-4.379 2.005 2.005 0 0 1-.588-1.417c0-1.099.904-2.003 2.003-2.003.53 0 1.039.211 1.414.585.979.926 1.859 1.958 2.83 2.995"
			style={{
				fillRule: "nonzero",
			}}
			transform="translate(-303.089 -50.757)"
		/>
	</svg>
)

export default DecisionSvg
