// import { REGISTER_PAGE_API } from "../constants/api";
import { SUCCESS_REGISTRATION_USER_COM, USER_COM_KEY, USER_CREATION_USER_COM } from "../constants";
import { PLANS_API, REGISTER_API } from "../constants/api";
import { sendEvent } from "../functions";
import { GET, POST } from "../functions/superagentSending";



const initialState = {
	loading: false,
	success: false,
	data: [],
	error: '',
};


const FETCH_REGISTER_PAGE_REQUEST = 'FETCH_REGISTER_PAGE_REQUEST'
const FETCH_REGISTER_PAGE_SUCCESS = 'FETCH_REGISTER_PAGE_SUCCESS'
const SEND_REGISTER_PAGE_SUCCESS = 'SEND_REGISTER_PAGE_SUCCESS'
const FETCH_REGISTER_PAGE_FAILURE = 'FETCH_REGISTER_PAGE_FAILURE'

const fetchRegisterPageSuccess = (value) => ({
	type: FETCH_REGISTER_PAGE_SUCCESS,
	payload: value
})
const sendRegisterPageSuccess = (value) => ({
	type: SEND_REGISTER_PAGE_SUCCESS,
	payload: value
})
const fetchRegisterPageRequest = () => ({
	type: FETCH_REGISTER_PAGE_REQUEST
})

const fetchRegisterPageFailure = error => ({
	type: FETCH_REGISTER_PAGE_FAILURE,
	payload: error
})


const RegisterPageReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_REGISTER_PAGE_REQUEST:
			return {
				...state,
				loading: true,
				error: ''
			}
		case FETCH_REGISTER_PAGE_SUCCESS:
			return {
				data: payload,
				loading: false,
				error: ''
			}
		case SEND_REGISTER_PAGE_SUCCESS:
			return {
				...state,
				success: true,
				loading: false,
				error: ''
			}
		case FETCH_REGISTER_PAGE_FAILURE:
			return {
				...state,
				success: false,
				loading: false,
				error: payload
			}
		default:
			return state
	}
}

export const FETCH_REGISTER_PAGE_REDUCER = () => {
	return (dispatch) => {
		dispatch(fetchRegisterPageRequest());
		GET(PLANS_API,
			(success) => {
				dispatch(fetchRegisterPageSuccess(success?.body?.data));
			},
			(error) => {
				dispatch(fetchRegisterPageFailure(error));
				// dispatch(fetchRegisterPageFailure());
			})
	}
}
export const SEND_REGISTER_PAGE_REDUCER = (values,) => {
	return (dispatch) => {
		dispatch(fetchRegisterPageRequest());
		POST(REGISTER_API, values).then((success) => {
			window.UE.pageHit({
				apiKey: USER_COM_KEY,
				email: values?.email,
				name: values?.first_name
					? `${values?.first_name} ${values?.last_name}`
					: false,
				phone_number: values?.phone,
				unsubscribed: !values?.newsletter,
			});
			sendEvent({
				name: USER_CREATION_USER_COM, body: {
					name: SUCCESS_REGISTRATION_USER_COM
				}
			});
			dispatch(sendRegisterPageSuccess(success));
		}).catch((error) => {
			dispatch(fetchRegisterPageFailure(error));
			// dispatch(fetchRegisterPageFailure());
		})
	}
}

export default RegisterPageReducer;