import React, { useState } from 'react'
import GradientContainerComponent from '../../components/GradientContainerComponent';
import TextFieldComponent from '../../components/form/TextFieldComponent';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import BtnsComponent from '../../components/form/BtnsComponent';
import { FORGOT_ROUTE, ONBOARDING_ROUTE } from '../../constants/routes';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { POST } from '../../functions/superagentSending';
import { LOGIN_API } from '../../constants/api';
import { TOKEN_NAME,  } from '../../constants';



const userSchema = (t) => Yup.object().shape({
	email: Yup.string()
		.email(t('validation.email'))
		.required(t('validation.required'))
		.min(2, t('validation.min', { item: 4 }))
		.max(80, ('validation.max', { item: 80 })),
	password: Yup.string()
		.min(8, t('validation.min', { item: 8 }))
		.required(t('validation.required')),

});

const LoginPage = (props) => {
	const { t } = useTranslation()
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const formik = useFormik({
		validationSchema: userSchema(t),
		enableReinitialize: true,
		initialValues: {
			email: '',
			password: ''
		},
		onSubmit: values => {
			setLoading(true);
			setError(false);
			POST(LOGIN_API, values)
				.then((success) => {
					setLoading(false);
					localStorage.setItem(TOKEN_NAME, success.body.panel_token)
					if (success.body.redirect_url)
						window.location.href = `${success.body.redirect_url}?${TOKEN_NAME}=${success.body.access_token}`
					else
						history.push(ONBOARDING_ROUTE)
				}).catch((error) => {
					setError(true)
					setLoading(false);

				})
			// dispatch((values));
		},
	});

	const handlePushForgot = useCallback(() => {
		history.push(FORGOT_ROUTE)
	}, [history])
	return (
		<GradientContainerComponent
			loading={loading}
			error={error ? t('errors.login') : false}
			title={t('login_page.title')}
		>
			<form onSubmit={formik.submitForm}>
				<TextFieldComponent
					id='email'
					autoComplete='username'
					type='email'
					formikProps={formik}
				/>
				<TextFieldComponent
					id='password'
					autoComplete='current-password'
					type='password'
					formikProps={formik}
				/>
				<BtnsComponent
					gradient
					title={t('btns.login')}
					secondTitle={t('login_page.forgot_title')}
					handleSave={formik.submitForm}
					onClose={handlePushForgot}
				/>
			</form>
		</GradientContainerComponent>
	)
}

export default React.memo(LoginPage);